.cases-d {
  padding-top: 30px;
}
.notes .form-group,
.ref .form-group {
  margin: 0 !important;
}
.underline-t .saved-input {
  margin: 0;
  padding: 0;
}
.underline-t .logo-title {
  position: absolute;
  right: 1.5px;
  top: 4px;
  width: 13px;
}

.disc .form-group {
  margin: 0 !important;
}
.card-close .logo-title {
  position: relative;
  /* right: 10px;
  top: 15px;
  width: 13px; */
  float: right;
}
#focus-form .col-md-12 {
  padding: 10px;
}
#focus-form .drag {
  position: absolute;
  right: 25px;
  top: 2px;
  width: 1.5em;
}
#focus-form .underline-t .logo-title {
  top: 5.4px;
}

.inside-card .progress {
  width: 50px;
  float: left;
  margin-top: 7px;
}
.notes-n {
  height: 34px !important;
}
@media (max-width: 946px) {
  .notes-n {
    height: 64px !important;
  }
}
