.dropdown-toggle::after {
  margin-left: 0.355em;
}
.badged {
  width: 300px;
}
.badged-1 {
  width: 150px;
  margin-left: 20px;
}
#badges {
  background-color: #e1e1e1;
  height: 500px;
  padding-top: 60px;
}
.view-all {
  float: left;
}
.dropdown-menu {
  width: 300px;
  z-index: 119999 !important;
  background-color: white !important;
  padding: 20px 10px !important;
  overflow: visible !important;
}
.feature-img-con-m img {
  width: 100% !important;
}
.dropdown-menu .navbar-nav {
  height: 30px;
  background-color: white !important;
  z-index: 119999 !important;
  line-height: 0.9 !important;
  margin: 0 !important;
  padding: 10px 10px !important;
}
.dropdown-menu a {
  background-color: white !important;
  z-index: 119999 !important;
  font-size: 14px !important;
}
.land-logo {
  cursor: pointer;
}
.header .header-nav ul.dropdown-menu,
.header .header-nav ul.dropdown-menu {
  position: relative !important;
  z-index: 9999;
}
#Navigation {
  z-index: 9999;
}
#basic-navbar-nav {
  z-index: 9999;
}
.navbar-expand-lg .navbar-nav {
  z-index: 9999;
}
#basic-navbar-nav {
  z-index: 9999;
}
.dropdown-item {
  padding: 0;
}
.sub-nav {
  font-weight: normal;
}
.head-nav img {
  float: right;
  height: 14px;
  width: 14px;
  position: absolute;
  opacity: 0;
  right: 20px;
  padding: 0;
}
.head-nav:hover img {
  opacity: 100%;
}
.box-t h4 {
  display: none;
}

.Login-IMG {
  background-color: rgb(247, 247, 248) !important;
  background: url("https://res.cloudinary.com/aleri/image/upload/v1618581480/Aleri/landing/Copy_of_Copy_of_Untitled_v7stdx.jpg")
    right center / 90% no-repeat rgb(247, 247, 248);
}
.Login-Container {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  position: relative;
  bottom: 0;
  overflow: auto;
  flex: none;
  scroll-behavior: smooth;

  overflow-x: hidden;
}
.Login-Container .row {
  height: 100%;
}
.signup-signin {
  color: #0f7ec0;
}
.progtrckr {
  display: none !important;
}
.registrationForm h1 {
  font-weight: 900;
}
@media (max-width: 991px) {
  #feature-landing {
    height: 480px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin: auto !important;
    margin-bottom: 20px !important;
  }
  .dropdown-menu a {
    margin-bottom: 12px;
    text-align: center;
  }
  .dropdown-menu a:last-child {
    margin-bottom: 0;
  }
  .head-nav:hover img {
    opacity: 0;
  }
  .box-t h4 {
    padding-left: 43px;
    display: block;
  }
  #footer li {
    text-align: center;
  }
  #Navigation {
    margin: 0;
  }

  #badges {
    padding-top: 20px;
  }
  #why-not-aleri {
    top: 800px;
  }
  .badged {
    width: 300px;
  }
  .badged-1 {
    width: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .brands {
    line-height: 1.5 !important;
  }
  #Navigation {
    margin: 0;
  }
}
@media (max-width: 500px) {
  #Navigation .navbar-brand {
    padding-left: 15px;
  }
  .navbar-light .navbar-toggler {
    margin-right: 15px;
    margin-top: 20px !important;
  }
  #Navigation {
    margin: 0;
  }
}
@media (max-width: 400px) {
  .under-head ul li {
    font-size: 16px !important;
  }
}
