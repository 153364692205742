#form-intro h5 {
  text-align: left;
}
#form-intro h3 {
  text-align: center;
  margin-bottom: 20px;
}
#form-intro h1 {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
#form-intro {
  background-color: #142032;
  color: white;
  padding: 30px;
  height: 800px;
}
#form-intro iframe {
  width: 85%;
  height: 750px;
  margin-top: 100px;
  box-shadow: 10px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}
#form-summary {
  background-color: white;
  color: black;
  padding: 30px;
  height: 800px;
}
#form-summary p {
  font-size: 23px;
  font-weight: 500;
  margin-top: 500px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
#form-one {
  background-color: #142032;
  color: white;
  padding: 30px;
  height: 800px;
  position: relative;
  overflow: hidden;
}
#form-one img {
  position: absolute;

  right: -300px;
  top: 100px;
  /* bottom: 5%; */
  box-shadow: 10px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  height: 600px;
}
#form-one p {
  position: relative;
  text-align: left;
  font-size: 20px;
  margin-left: 120px;
  margin-right: 50px;
  margin-top: 80px;
  display: block;
  line-height: 1.8;
}
#form-one p:first-of-type {
  margin-top: 140px;
}
#form-one p img {
  position: relative;

  right: unset;
  top: unset;
  /* bottom: 5%; */
  box-shadow: unset;
  border-radius: unset;
  height: 70px;
  width: 70px;
  float: left;
  margin-right: 10px;
  margin-bottom: 40px;
}
#form-one p span {
  font-weight: bolder;
  font-size: 23px;
}
#form-two {
  background-color: white;
  color: black;
  padding: 30px;
  height: 800px;
  position: relative;
  overflow: hidden;
}
#form-two img {
  position: absolute;

  left: -300px;
  top: 100px;
  /* bottom: 5%; */
  box-shadow: 10px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  height: 600px;
}
#form-two div {
  position: absolute;
  left: 50%;
}
#form-two p {
  position: relative;
  text-align: left;
  font-size: 20px;
  margin-right: 120px;
  margin-left: 50px;
  margin-top: 80px;

  line-height: 1.8;
}
#form-two p span {
  font-weight: bolder;
  font-size: 23px;
  text-align: left;
}
#form-two p:first-of-type {
  margin-top: 140px;
}
#form-two p img {
  position: relative;

  right: unset;
  top: unset;
  left: unset;
  /* bottom: 5%; */
  box-shadow: unset;
  border-radius: unset;
  height: 70px;
  width: 70px;
  float: left;
  margin-right: 10px;
  margin-bottom: 40px;
}

#form-faq {
  background-color: #142032;
  color: white;
  padding: 60px;
  height: 800px;
}
#form-faq .row {
  margin: 50px;
  margin-top: 100px;
}

#form-faq .nav-tabs .nav-link {
  font-size: 18px;
  background-color: white;
  border-radius: unset;
  border: 1px solid #e2e2e2;
  color: darkgrey;
}

#form-faq .nav-tabs .nav-link:hover {
  color: #142032;
}

#form-faq .tab-content > .active {
  text-align: left;
  margin-left: 50px;
}
#form-faq .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #0f7ec0 !important;
  border: #0f7ec0 !important;
  color: #142032 !important;
}
#form-faq .tab-content > .active {
  font-size: 18px;
}
#form-faq .tab-content > .active span {
  font-size: 26px;
  font-weight: bolder;
}
.colon {
  color: #0f7ec0;
}

@media (min-width: 1700px) {
  #form-intro iframe {
    height: 850px;
  }
  #form-summary {
    padding: 40px;
  }
}
@media (max-width: 1500px) {
  #form-intro iframe {
    height: 680px;
  }
  #form-summary p {
    margin-top: 400px;
  }
}

@media (max-width: 1450px) {
  #form-intro iframe {
    height: 600px;
  }
  #form-summary p {
    margin-top: 350px;
  }
  #form-summary {
    height: 700px;
  }
  #form-two p {
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-right: 30px;
    margin-left: 100px;
    margin-top: 80px;
    line-height: 1.8;
  }
  #form-one p {
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-left: 30px;
    margin-right: 100px;
    margin-top: 80px;
    line-height: 1.8;
  }
  #form-one img {
    right: -400px;
  }
  #form-two img {
    left: -400px;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 70px;
  }
}
@media (max-width: 1150px) {
  #form-intro iframe {
    height: 540px;
  }
  #form-intro {
    height: 600px;
  }
  #form-summary {
    height: 600px;
  }
  #form-one img {
    right: -460px;
  }
  #form-two img {
    left: -460px;
  }
  #form-one p img,
  #form-two p img {
    width: 50px;
  }
  #form-one p,
  #form-two p {
    font-size: 16px;
  }
  #form-one p span,
  #form-two p span {
    font-size: 20px;
  }
  #form-faq .tab-content > .active {
    font-size: 16px;
  }
}
@media (max-width: 1100px) {
  #form-intro iframe {
    height: 500px;
  }
  #form-one img {
    right: -500px;
  }
  #form-two img {
    left: -500px;
  }
  #form-one p {
    margin-left: 10px;
  }
  #form-two p {
    margin-right: 10px;
  }
}
@media (max-width: 991px) {
  #form-intro iframe {
    height: 420px;
  }
  #form-summary p {
    margin-top: 260px;
  }
  #form-summary p {
    font-size: 18px;
  }
  #form-summary {
    height: 500px;
  }
  #form-faq {
    padding: 20px;
  }
  #form-faq .row {
    margin: 10px;
    margin-top: 30px;
  }
  #form-faq .tab-content > .active {
    margin-top: 20px;
    margin-left: 0;
  }
  #form-intro {
    margin-top: 25px;
  }
  #form-one img {
    position: relative;
    left: 0;
    right: 0;
    margin: 0;
    height: unset;
    width: 100%;
    border-radius: 15px;
    top: unset;
  }
  #form-one,
  #form-two {
    height: 900px;
  }
  #form-one p:first-of-type {
    margin-top: 0;
    width: 100%;
  }
  #form-one p {
    line-height: 3;
    margin: 10px;
    float: left;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 20px;
  }

  #form-two img {
    left: -460px;
  }
}
@media (max-width: 867px) {
  #form-intro iframe {
    height: 360px;
    width: 100%;
  }
}
@media (max-width: 667px) {
  #form-intro iframe {
    height: 320px;
  }
  #form-summary p {
    margin-top: 170px;
    margin-right: 0;
    margin-left: 0;
  }
  #form-summary {
    padding: 0;
    height: 200px;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 40px;
  }
  #form-one,
  #form-two {
    padding: 20px 10px;
  }
}
@media (max-width: 567px) {
  #form-intro iframe {
    height: 280px;
    width: 100%;
  }

  @media (max-width: 500px) {
    #form-intro iframe {
      height: 260px;
      width: 100%;
      border-radius: 10px;
    }
    #form-intro {
      padding: 15px;
    }
    #form-faq {
      height: 900px;
    }
    #form-intro h1 {
      margin-top: 45px;
    }
    #form-summary p {
      margin-top: 150px;
      margin-right: 0;
      margin-left: 0;
      line-height: 2;
    }
    #form-summary {
      padding: 0;
      height: 300px;
    }
    #form-one p img,
    #form-two p img {
      margin-bottom: 90px;
    }
  }
  @media (max-width: 420px) {
    #form-one p img,
    #form-two p img {
      margin-bottom: 75px;
    }
    #form-one p img,
    #form-two p img {
      width: 30px;
    }
    #form-one p {
      font-size: 14px;
      line-height: 1.8;
    }
    #form-one span,
    #form-two span {
      font-size: 18px;
    }
    #form-one,
    #form-two {
      height: 700px;
    }
  }
}
@media (max-width: 400px) {
  #form-intro iframe {
    height: 200px;
    width: 100%;
    border-radius: 10px;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 55px;
  }
}
