.s-item-documents {
  background-color: #0f7ec0;
  border: none;
  margin-left: 8px;
}

.s-item-documents:hover,
.s-item-documents:active,
.s-item-documents:focus {
  background-color: transparent !important;
  color: #0f7ec0 !important;
  box-shadow: inset 0 0 0 1px #0f7ec0 !important;
}

.document-text {
  cursor: default;
}

#focus-document-list {
  /* width: 500px; */
  /* margin: auto; */
}

.card-type {
  float: left;
  padding: 5px;
  padding-bottom: 0;
  color: #949db0 !important;
}
.card-type span {
  font-size: 15px !important;
  color: #949db0 !important;
}
.card-placement {
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
  text-align: right;
  /*  */
  /* position: absolute;
  right: 10px;
  left: 10px;
  height: 52px;
  bottom: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 10px;
  white-space: nowrap; */
}
.card-placement a {
  color: #0f7ec0;
}
.document-box .form-group {
  margin: 0;
}
.document-box textarea {
  border: none !important;
  background-color: transparent !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
}

.document-box textarea::-webkit-scrollbar {
  height: 2px;
  background-color: transparent !important;
  margin-top: 10px !important;
}

.document-box textarea::-webkit-scrollbar-thumb {
  background-color: #0f7ec0 !important;
}
.link_doc,
.link_doc:hover,
.link_doc:active,
.link_doc:focus {
  cursor: pointer;
  color: #0f7ec0 !important;
  text-decoration: none !important;
}
.document-x {
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 5px;
  width: 15px !important;
}
.document-x:hover {
  opacity: 70%;
}
.document-u {
  margin-left: 10px;
  margin-top: 2px;
  margin-right: 5px;
  width: 18px !important;
}
.document-u:hover {
  opacity: 70%;
}
#focus-form .card-close {
  padding-bottom: 0;
}
#focus-document-list textarea {
  height: 30px;
}
