#dark {
  background: #2b2b2b;
  /* background-image: url('https://res.cloudinary.com/aleri/image/upload/v1593642443/Aleri/dark-back_tfvcsp.jpg'); */
  color: white !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
}
#dark .parent-side-nav-card {
  box-shadow: none !important;
}
#dark #side-nav-open {
  background-color: #242527;
}
.dark .saved-input textarea {
  /* color: rgba(255, 255, 255, 0.749) !important; */
  font-size: 20px !important;
}
#dark .btn-outline-secondary {
  color: white !important;
  border-color: black !important;
  background-color: #2e3235;
}

.dark-add,
.dark-add:active,
.dark-add:focus,
.dark-add:hover {
  border: 1px solid #191a1b !important;
  background-color: #202224 !important;
}
#dark .disc3 {
  border-right: 1px solid rgb(117, 116, 116);
}
#dark .btn-outline-secondary:hover,
#dark .add-case-btn:hover {
  color: white !important;
  background-color: #212121 !important;
  border-color: black !important;
}

#dark .parent-side-nav p {
  color: white;
}

#dark .comp-auth-nav {
  background-color: #242527;
}

#dark .side-nav-open-title-w h6 {
  color: white;
}

#dark .list-group-item {
  color: whitesmoke !important;
  background-color: #14111129;
  /* box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px; */
}
#dark .modal-content {
  background-color: #14111129;
}
#dark .list-group-item:hover {
  color: whitesmoke !important;
  background-color: #14111129;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}
#dark .user-list .list-group-item {
  background-color: #232527 !important;
}
.dark-modal .StripeElement {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  background-color: white;
  color: white !important;
}
.dark-modal .action {
  background-color: #0f7ec0;
  color: white;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b;
}
#dark .d {
  border-color: #191a1b !important;
  background-color: #242527;
}
#dark .d:hover {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b !important;
  background-color: #242527 !important;
}
#dark .d:active {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b !important;
  background-color: #242527 !important;
}
#dark .d:focus {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b !important;
  background-color: #242527 !important;
}
.dark-modal .box-title-m,
.dark-modal .box-title-d,
.dark-modal .box-title-t {
  color: #0f7ec0;
}
.dark-modal .StripeElement p,
.dark-modal .StripeElement span,
.dark-modal .StripeElement input {
  color: white !important;
}
.dark-modal .ElementsApp,
.ElementsApp .InputElement {
  color: white !important;
}
.dark-modal .box {
  background-color: #191a1b !important;
}
.dark-modal .box-b {
  background-color: #191a1b !important;
}
.dark-modal .box-b-2 {
  background-color: #191a1b !important;
}
.dark-modal .box-bottom {
  background-color: #191a1b !important;
}
.dark-modal.box-title-price .box-title-d {
  color: #0275d8 !important;
}
#dark div.inside-card {
  color: #212121;
  background-color: #242527 !important;
  background-image: none;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}
#dark .inside-card:hover {
  color: #212121;
  background-color: #242527 !important;
  background-image: none;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;

  outline: none !important;
}

#dark .modal-content .btn-primary {
  background-color: #212121;
  border: none;
}

#dark .expanded {
  background-color: #232527;
}

#dark .form-control {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.541);
  /* border: #8da2b57d 1px solid; */
}

#dark .action {
  background-color: #232527;
  border: 1px solid rgb(46, 45, 45);
  color: aliceblue;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}
#dark .action:active {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px, #0f7ec0 2px 3px 3px 0px !important;
}
#dark .action:focus {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 30px 2px,
    rgb(16, 19, 23) 4px 6px 6px 0px !important;
}
#dark .parent-side-nav {
  border-color: #191a1b;
}
#dark .action:hover {
  background-color: #232527;
  border: 1px solid rgb(48, 47, 47);

  color: aliceblue;
}

#dark .phone {
  color: white;
}

#dark .underline-settings {
  background-color: #202224 !important;
}

#dark .form-control:focus {
  background-color: transparent;
  color: whitesmoke;
  border-bottom: 1px solid rgb(163, 155, 155);
}

#dark #user-settings textarea.form-control {
  border: 1px solid rgb(163, 155, 155) !important;
}
#dark .expanded {
  border-right: 1px solid #191a1b;
}

#dark #comp-auth-nav-expanded {
  border-right: 1px solid #191a1b;
}

#dark .com-app-icon-proof {
  border-bottom: solid 1px #191a1b;
}

#dark textarea {
  background: transparent;
  color: rgba(255, 255, 255, 0.749);
}
#dark textarea:focus {
  background: transparent;
  color: #f9f9f9;
}

#dark .saved-input .disc {
  border-left: 1px solid rgba(255, 255, 255, 0.281);
}

#dark .notes {
  border-left: 1px solid rgba(255, 255, 255, 0.281);
}
#dark .unsaved-input .disc {
  /* border-right: 1px solid rgb(117, 116, 116); */
}
#dark .unsaved-input .disc2 {
  border-right: 1px solid rgba(255, 255, 255, 0.281);
}

#dark .unsaved-input .ref {
  border-right: 1px solid rgb(117, 116, 116);
}

#dark .saved-input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.281);
  /* border-top: 1px solid rgba(255, 255, 255, 0.281); */
}

#dark .comp-auth-nav {
  background: linear-gradient(to bottom, #2b2b2b 0, #242527 100%);
}
#dark #top-func {
  background: #242527;
  background-color: #242527;
}
#dark #top-second,
#dark #top-first {
  background: #242527 !important;
}
#dark #top-second div,
#dark #top-first div {
  /* background: #242527; */

  border-right: 1px solid #191a1b;
}
#dark #top-first .active-top-card {
  box-shadow: inset 0px 5px 0px 0px #0f7ec0 !important;
  z-index: 10000;
}

#dark #top-nav-expanded {
  border-bottom: 1px solid #191a1b;
  background-color: #242527;
}
#dark #top-second {
  border-left: 1px solid #191a1b;
  border-right: 1px solid #191a1b;
}
#dark #top-nav {
  border-bottom: 1px solid #191a1b;
}
#dark #top-second .top-card {
  background-color: #0f7ec0 !important;
}
#dark .top-card {
  border-right: 1px solid #dfe3ed;
}

#dark .case-drag-single-list {
  background-color: #242527 !important;
}
.dark-mode {
  background-color: #424242;
  color: white;
}
.dark-modal .modal-content {
  background-color: #242527;
  color: white;
}
.dark-modal .form-control {
  color: white;
}
.dark-modal button span {
  color: white;
}
.dark-modal .modal-header {
  border-bottom: 1px solid #191a1b;
}
.dark-modal .modal-footer {
  border-top: 1px solid#191a1b;
}

#dark-tooltip-folder-s {
  background-color: #202224;
}
#dark-tooltip-folder-s > .tooltip-inner {
  background: transparent;
  color: rgb(148, 157, 176);
  background-color: #202224;
  border: none;
}

#dark-tooltip-folder {
  background-color: #202224;
  border: 1px solid #191a1b;
}

#dark-tooltip-folder > .tooltip-inner {
  background: transparent;
  color: rgb(148, 157, 176);
  background-color: #202224;
  border: none;
}

#dark-tooltip-add {
  opacity: 1;
  background-color: #202224;
  color: rgb(148, 157, 176);
  padding: 3px;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid #191a1b;
  font-size: 90%;

  font-weight: 300;
}
#dark-tooltip-add > .tooltip-inner {
  background: transparent;
  color: rgb(148, 157, 176);
  background-color: #202224;
  border: none;
}
#dark.btn-toolbar {
  display: block;
}

#tooltip-add > .tooltip-arrow {
  display: none;
  color: #202224;
}
#dark .arrow::before {
  display: none;
}
#dark [data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  margin-top: 20px;
  z-index: 100000;
}

#dark [data-tooltip]:hover:before {
  opacity: 1;
  background-color: #202224;
  color: rgb(148, 157, 176);
  padding: 10px;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid #191a1b;
  font-size: 90%;
  font-weight: 300;
  z-index: 100000;
}
#dark [data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
  margin-top: 20px;
  z-index: 100000;
}

#dark .case-drag-single-list h4 {
  background-color: #191a1b !important;
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
}
#dark .parent-side-nav {
  background-color: #14111129 !important;
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
}
#dark .parent-side-nav:hover {
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}

#dark .parent-side-nav:active {
  background-color: #424242 !important;
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
}
#dark .expanded .add-case-nav {
  border: 1px solid #191a1b;
}
#dark .add-case {
  box-shadow: none;
  background: transparent;
}
#dark #dash-add .add-case {
  border: 1px solid #191a1b;
  background-color: #202224;
}
#dark #dash-add .add-case,
#dark #wit-dash .add-case {
  border: 1px solid #191a1b;
  background-color: #202224;
}

#dark button.add-case.plus-icon,
#dark button.add-case.plus-icon {
  border: none !important;
  background-color: #0f7ec0 !important;
}
#dark #dash-add .add-case:active,
#dark #dash-add .add-case:focus,
#dark #wit-dash .add-case:focus,
#dark #wit-dash .add-case:active,
#dark #dash-add .add-case:hover,
#dark #wit-dash .add-case:hover {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px !important;
}

#dark #topic-delete {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px !important;
}

#dark.inside-card:active,
#dark.inside-card:focus,
#dark.parent-side-nav-card:active,
#dark.parent-side-nav-card:focus,
.dark-parent-side-nav-card,
.inside-card-dark {
  background-color: #424242 !important;
  border: 1px solid #191a1b !important;
  /* cursor: move !important; fallback if grab cursor is unsupported */
  /* cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important; */
  background-image: none !important;
  color: #f9f9f9 !important;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px !important;
}
.dragging {
  cursor: grabbing !important;
  cursor: move !important; /* fallback if grab cursor is unsupported */
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
}

.f9 .dark-border-bx {
  background-color: #424242 !important;
  border: 1px solid #191a1b !important;
}
#dark .border-bx {
  background-color: #202224 !important;
  border: 1px solid #191a1b !important;
}
#dark #top-nav {
  /* width: 70%; */
  height: 42px;
  background: #202224 !important;

  position: fixed;
  z-index: 1000;
  left: 60px;
  right: 0;
  overflow: hidden;
  white-space: nowrap;
}
#dark #top-nav-expanded {
  height: 42px;
  background: #202224 !important;

  position: fixed;
  z-index: 1000;
  /* left: 300px; */
  right: 0;
  overflow: hidden;
  white-space: nowrap;
  top: 0;
}

#dark div[class|="c3-right"] + div[class|="hui"]::after {
  /* background-color: #202224 !important; */
  /* color: white !important; */
  content: "Close Sidebar To Continue";
  font-size: 24px;
  bottom: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}
#dark div[class|="c3-u-right"] + div[class|="hui"]::after {
  /* background-color: #202224 !important; */
  /* color: white !important; */
  content: "Click Here To Close Sidebar";
  font-size: 24px;
  bottom: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}

.czhaTb::before + div[class|="c3-u-right"] {
  width: 100vw;
  height: 100vh;
  content: "";
  position: inherit;
  top: 0;
}
#dark #top-nav-expanded,
#dark .czhaTb-top-expanded {
  /* width: 100vw; */
  /* left: 0; */
  z-index: 10;
}
.dark-modal .sidebar {
  overflow: auto;
  color: white;
  background: #242527;
  display: flex;
  flex-direction: column;
  width: 25%;
}

#dark .sidebar {
  overflow: auto;
  color: white;
  background: #242527;
  display: flex;
  flex-direction: column;
  width: 25%;
}
#dark .Tip__card {
  padding: 20px;
  background: #242527;
  background-clip: padding-box;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(37 40 43 / 20%);
}
