.landing-logo {
  width: 220px;
  margin-bottom: 50px;
}

@media (max-width: 75em) {
  .landing-logo {
    width: 150px;
    margin-bottom: 50px;
  }
  .dash-t {
    font-size: 1.5rem;
  }
  .dash-foot {
    font-size: 0.8rem;
  }
}

#quate {
  font-size: 80% !important;
  float: right !important;
}
#dash-d-main .season {
  font-size: 22px;
  font-weight: 300;
  color: rgb(148, 157, 176);
}
#dash-d-main .landing-doc-link {
  font-size: 16px;
  font-weight: 500;
  color: rgb(148, 157, 176);
}
#dash-d-main .season:hover,
#dash-d-main .landing-doc-link:hover {
  color: #0275d8;
  text-decoration: none;
}

.card_important_comment {
  opacity: 0.8;
}
.card_important_comment:hover {
  opacity: 1;
}

.case-btn-side-w {
  z-index: 1000;
}

.logo-title-dash {
  width: 18px !important;
  float: right;
  margin-left: 15px;
  cursor: pointer;
}

.case-dash-container {
  position: absolute;
  top: 43px;
  right: 0%;
  width: 75px;
}
#wit-dash h1 {
  margin-right: 75px;
}

#feature-landing-red {
  background-color: white;
  position: absolute;
  top: 50px;
  height: 700px;
  width: 100%;
  text-align: left;
}
#why-not-aleri {
  background-color: #f7f7f7;
  position: absolute;
  top: 700px;
  height: 523px;
  width: 100%;
  text-align: left;
}

#why-not-aleri .under-head {
  margin: 90px;
  margin-left: 0;
  margin-top: 100px;
  text-align: left;
  font-weight: lighter;
  line-height: 1.5;
  color: #142032;
  font-size: 33px;
}
#why-not-aleri-2 .under-head {
  margin-top: 130px;
}
#why-not-aleri-2 {
  background-color: #f7f7f7;
  height: 400px;
  margin-top: 100px;
  /* padding-top: 25px; */
  /* position: absolute;
  top: 700px;
  
  width: 100%;
  text-align: left; */
}

#what-clients-say {
  background-color: white;
  position: absolute;
  top: 1400px;
  height: 700px;
  width: 100%;
  text-align: center;
}
#feature-landing-red h1 {
  color: black;
  /* text-shadow: 8px 4px black; */
  top: 1000px;
  font-size: 750%;
  text-align: left;
}
#feature-landing-red-texts {
  padding: 60px 40px;
}
/* #feature-landing {
  top: 1000px;
} */
/* .dark-mode-section {
  margin-top: 10000px;
} */

.lead-top {
  font-size: 100% !important;
  letter-spacing: 1px !important;
  font-weight: 500 !important;
}

.lead-red {
  /* font-weight: lighter; */
  color: black;
  font-size: 150%;
  /* background-color: white;
  border-radius: 5px 30px 0px 0px;
  padding: 3px 10px; */
  /* box-shadow: 2px 2px black; */
  line-height: 2;
}
.lead-red-2 {
  font-weight: lighter;
  color: black;
  font-size: 230%;
}
.lead-red-2-outer {
  margin: 80px 20px;
  width: 80%;
  height: 400px;
  background: white;
  box-shadow: 2px 2px white;
  padding: 40px;
  border-radius: 5px;
}
.red-blood {
  color: #cc0000;
}
.blue-blood {
  color: #0275d8 !important;
}
#why-not-aleri h1 {
  text-align: center;
  color: black;
}
.landing-sec-2 {
  margin-top: 350px;
}
.navbar-brand {
  margin-right: 5px;
}
#featured {
  height: 400px;
  margin-bottom: 80px;
  background-color: #142032;
}
.landing-demo {
  width: 40% !important;
  height: 50px;
  border-radius: 7px;
  margin: unset;
  padding: unset;
  font-size: unset;
  box-shadow: unset;
  background-color: #0275d8;
  color: white;
}
.pod-btn {
  width: 100%;
  margin-left: 30px !important;
  padding-left: 0 !important;
  text-align: left !important;
}
#featured .under-head {
  color: white;
  margin: 34px;
  padding: 0;
  margin-top: 50px;
  padding-left: 70px !important;
}
.under-head ul li {
  text-align: left;
  margin-left: 0;
  font-size: 70%;
  margin-top: 30px;
}
.blog-length {
  text-align: left !important;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-top: 10px;
}
.blog-length-2 {
  text-align: right !important;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 10px;
}

.blog-length-up {
  /* border-bottom: 1px solid #142032;
  border-top: 1px solid #142032;
  background-color: #f2f2f2; */
  padding-top: 5px;
  position: absolute;
  bottom: 0;
  right: 50%;
  left: 0;
  /* width: 100%; */
  width: 50%;
  padding-top: 10px;
}
.blog-length-up-2 {
  /* border-bottom: 1px solid #142032;
  border-top: 1px solid #142032;
  background-color: #f2f2f2; */
  padding-top: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 50%;
  /* width: 100%; */
  width: 50%;
  padding-top: 10px;
}
#featured h3 {
  margin-top: 0px !important;
  font-size: 22px !important;
  text-align: left;
  margin-bottom: 10px !important;
}
#featured h4 {
  text-align: left;
  margin-top: 0px !important;
  font-size: 18px !important;
}
#featured a {
  float: left;
  margin-top: 0 !important;
  font-size: 20px;
}
#featured img {
  margin-top: 25px;
  width: 570px;
  box-shadow: -1px 1px 20px -1px rgba(0, 0, 0, 0.25);
}
/* test */
.drift-widget-greeting-content {
  line-height: 1.3;
}
.drift-widget-greeting .drift-widget-greeting-content {
  font-size: 13px !important;
}
.drift-widget-chat-bottom {
  top: 90px;
}
.img-ad {
  width: 400px;
}
#landing-info-row {
  margin-bottom: 50px;
}
.landing-list h3 {
  margin-bottom: 30px;
  margin-right: 30px;
  font-weight: lighter;
}

.landing-list ul {
  margin-left: 20px;
  font-size: 150%;
  line-height: 2;
  margin-right: 30px;
}
.landing-list ul li {
  margin-bottom: 20px;
}
.feature-img-side {
  box-shadow: -1px 1px 20px -1px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  position: absolute;
  left: -220px;
  width: 840px;
}

.dark-head {
  font-size: 50px;
}
.helper div {
  font-size: 60%;
  margin: 10px;
  line-height: 4;
  display: inline-block;
}
.helper div span {
  margin-top: 50px;
}
.helper div img {
  width: 16px;
  margin-right: 5px;
}
.email-form {
  width: 80%;
  border: 2px solid #0275d8;
  border-radius: 10px;
}
.email-form .form-group {
  width: 70%;
}
.email-form a {
  width: 30%;
}

.email-form button {
  width: 100% !important;
  height: 50px;
  border-radius: 0 7px 7px 0;

  margin: unset;
  padding: unset;

  font-size: unset;
  box-shadow: unset;
  background-color: #0275d8;
  color: white;
}
.email-form .form-control,
.email-form .form-control:active,
.email-form .form-control:hover {
  width: 100%;
  border: none;
}
.jen {
  text-align: left !important;
  font-size: 380% !important;
}
.dark-mode-section .email-form {
  margin: auto;
  margin-top: 20px;
  width: 30%;
}
.dark-mode-section .helper div span {
  font-size: 15px;
}
#landing-info-row {
  /* margin-top: 40px; */
  margin-top: 0;
}
#feature-landing-texts h1 {
  text-align: left !important;
  margin-bottom: 7px;
}
#feature-landing-texts p {
  text-align: left;
  font-size: 33px;
}
#feature-landing-texts {
  padding-top: 0;
  padding-left: 80px;
}

.under-head {
  margin: 70px;
  text-align: center;
  font-weight: lighter;
  line-height: 1.5;
  color: #142032;
  font-size: 33px;
}

.cap-img {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 400px;
  left: 100px;
}
.cap-img-1 {
  position: absolute;
  bottom: 0;
  top: -10px;
  width: 400px;
  left: 40%;
}
#main-land .container-fluid {
  padding: 50px;
}
.bg-1,
.bg-2,
.bg-3 {
  height: 600px;
}
#main-land .row {
  margin-bottom: 300px;
}
.fea-image {
  width: 100%;
}
#main-land p {
  font-size: 30px;
}

#main-land h3 {
  font-size: 50px;
}
@media (min-width: 995px) {
  #why-not-aleri {
    top: 900px;
  }

  #feature-landing-texts p {
    font-size: 28px;
  }
}
@media (min-width: 1295px) {
  #why-not-aleri {
    top: 900px;
  }
}
@media (max-width: 1678px) {
  .under-head {
    /* margin-right: 20px;
    margin-left: 20px; */
  }
  #why-not-aleri-2 .under-head {
    margin: 30px;
    margin-top: 130px;
  }
}
@media (max-width: 1400px) {
  .jen {
    font-size: 360% !important;
  }
}

@media (max-width: 1360px) {
  .under-head {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 165%;
  }
  .cap-img {
    left: 0px;
  }
  #why-not-aleri #landing-info-row {
    margin-top: 0 !important;
  }
}
@media (max-width: 1300px) {
  #featured .under-head {
    margin-left: 10px;
    float: left;
    padding-left: 40px !important;
  }
  #featured img {
    width: 440px;
    top: 30px;
  }
  .cap-img-1 {
    /* position: absolute;
    bottom: 0;
    top: -10px;
    width: 400px; */
    left: 20%;
  }
  .jen {
    font-size: 300% !important;
  }
  .feature-img-side {
    left: -320px;
    width: 840px;
  }
  #feature-landing-texts p {
    font-size: 26px;
  }
  #main-land .container-fluid {
    padding: 30px;
  }
  .bg-1,
  .bg-2,
  .bg-3 {
    height: 500px;
  }
  .fea-image {
    width: 700px;
  }
  #main-land .row {
    margin-bottom: 200px;
  }
  #main-land p {
    font-size: 20px;
  }

  #main-land h3 {
    font-size: 30px;
  }
}
.modal-front {
  z-index: 10000;
}
@media (max-width: 1165px) {
  .cap-img-1 {
    left: 10%;
  }
  .container-fluid {
    padding: 0;
  }
  #why-not-aleri {
    top: 880px;
  }
  .landing-sec-2 {
    margin-top: 350px;
  }
  #feature-landing-texts {
    padding-bottom: 0;
    padding-left: 30px;
  }
  .feat {
    font-size: 22px !important;
  }
  #info {
    margin-top: 40px;
  }
  .dark-mode-section .email-form {
    width: 52%;
  }

  #main-land .container-fluid {
    padding: 0;
  }
  .bg-1,
  .bg-2,
  .bg-3 {
    height: 420px;
  }
  .fea-image {
    width: 600px;
  }
  #main-land .row {
    margin-bottom: 10px;
  }
  .under-head {
    margin: 50px;
  }
  .lead-top {
    font-size: 75% !important;
  }
}
@media (max-width: 991px) {
  .landing-sec-2 {
    margin-top: 350px;
  }
  #why-not-aleri .under-head {
    margin-right: 10px;
  }
  .cap-img-1 {
    left: 0;
  }
  .landing-demo {
    width: 70% !important;
  }
  #featured {
    height: 500px;
    margin-bottom: 80px;
    background-color: #142032;
  }
}
/*  */
@media (max-width: 900px) {
  .jen {
    font-size: 260% !important;
  }
  #feature-landing-texts p {
    font-size: 22px;
  }
  .helper div {
    margin: 5px;
  }
  .email-form button {
    font-size: 14px;
  }
  .feat {
    font-size: 20px !important;
  }
  #main-land p {
    font-size: 16px !important;
  }
  .feature-img-con {
    margin-left: 0;
  }

  .bg-1,
  .bg-2,
  .bg-3 {
    height: 380px;
  }
  .fea-image {
    width: 500px;
  }
  #main-land .row {
    margin-bottom: 10px;
  }

  /* .under-head {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 165%;
  } */
  /* 
  #why-not-aleri #landing-info-row {
    margin-top: 0 !important;
  } */
  .cap-img {
    left: -20px;
  }
  #featured .under-head {
    margin-left: 0px;
    float: left;
    padding-left: 20px !important;
  }
  #featured img {
    width: 420px !important;
    top: 30px;
  }
  .landing-sec-2 {
    margin-top: 350px;
  }
}
@media (max-width: 850px) {
  .landing-sec-2 {
    margin-top: 350px;
  }
}
@media (max-width: 810px) {
  .landing-sec-2 {
    margin-top: 350px;
  }
  #badges {
    padding-top: 80px;
  }
  #why-not-aleri {
    top: 800px;
  }
  .badged {
    width: 200px;
  }
  .badged-1 {
    width: 100px;
    margin-left: 20px;
  }
}

@media (max-width: 800px) {
  .jen {
    font-size: 220% !important;
  }
  #feature-landing-texts p {
    font-size: 20px;
  }
  #featured img {
    width: 400px !important;
    top: 30px;
  }
  .landing-sec-2 {
    margin-top: 350px;
  }
}
@media (max-width: 767px) {
  #why-not-aleri .under-head {
    text-align: center;
    margin-right: 0;
    margin-top: 0;
  }
  #why-not-aleri li {
    text-align: center;
  }
  #why-not-aleri {
    height: 690px !important;
  }
  #why-not-aleri-2 .under-head {
    margin-top: 30px;
  }

  #why-not-aleri-2 {
    height: 500px !important;
  }
  .cap-img-1 {
    width: 270px;
    margin-top: 300px;
    left: 35%;
  }

  .dark-mode-section .email-form {
    margin: auto;
    margin-top: 20px;
    width: 86%;
  }
  .dark-mode-section .helper div span {
    font-size: 11px;
    margin: 2px;
  }

  .under-head {
    font-size: 23px;
    margin-top: 5px;
  }
  #why-not-aleri {
    top: 1320px;
    height: 486px;
    padding-top: 40px;
  }

  #why-not-aleri-2 {
    height: 600px;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  #feature-landing .email-form {
    width: 100% !important;
  }
  #feature-landing .form-group {
    margin: 0 !important;
  }
  .jen {
    font-size: 280% !important;
  }
  #feature-landing-texts p {
    font-size: 20px;
  }
  #feature-landing-texts {
    padding-bottom: 0;
  }
  .main-image {
    top: -40px;
  }
  .landing-sec-2 {
    margin-top: 350px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #landing-info-row {
    padding: 0;
  }
  .dark-mode-section .form-group {
    margin-top: 0;
  }
  .dark-head {
    font-size: 30px;
  }
  #why-not-aleri-2 {
    padding-top: 10px;
  }
  #landing-info-row {
    margin-top: 13px !important;
  }
  .dark-mode-section .form-group {
    margin-bottom: 0;
  }
  #why-not-aleri #landing-info-row {
    position: relative;
  }
  .cap-img {
    position: relative;
    margin: auto !important;
    width: 250px;
    display: block;
    left: 0;
  }
  #featured .under-head {
    padding: 10px !important;
    float: unset;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .cap-img {
    margin-top: 48px !important;
  }
  .under-head {
    text-align: center !important;
  }
  .dark-mode-section {
    margin-top: 80px;
  }
  #featured {
    padding-top: 30px;
    height: 800px;
  }
  #featured h3 {
    font-size: 30px !important;
  }
  .pod-btn {
    margin-left: 0 !important;
  }
  .landing-demo {
    width: 100%;
  }
  .landing-sec-2 {
    padding-top: 100px !important;
  }

  #badges {
    padding-top: 30px;
    margin-top: 600px;
  }

  .badged {
    width: 200px;
  }
  .badged-1 {
    width: 100px;
  }
}
@media (max-width: 762px) {
  /* #why-not-aleri {
    top: 1120px;
    height: 486px;
    padding-top: 40px;
  } */
  #why-not-aleri {
    top: 1020px;
    height: 486px;
    padding-top: 40px;
  }
  #badges {
    padding-top: 30px;
    margin-top: 300px;
  }
  .main-image {
    width: 70%;
  }
  .landing-sec-2 {
    padding-top: 300px !important;
    margin-top: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 614px) {
  .dark-mode-section .email-form {
    width: 92%;
  }
  .cap-img-1 {
    width: 270px;
    margin-top: 300px;
    left: 25%;
  }
}
@media (max-width: 585px) {
  .under-head {
    margin-bottom: 26px;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  .under-head ul li {
    margin-top: 10px;
    padding: 5px;
  }
}

@media (max-width: 545px) {
  .under-head ul li {
    margin-top: 13px;
  }
  .cap-img {
    width: 252px;
  }
}
@media (max-width: 514px) {
  .under-head ul li {
    margin-top: 5px;
  }
}
@media (max-width: 514px) {
  .under-head ul li {
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .badged {
    width: 200px;
  }
  .badged-1 {
    width: 100px;
    margin-left: 20px;
  }
  #why-not-aleri {
    top: 1010px;
  }
  .under-head {
    font-size: 20px;
    margin-top: 10px;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  #why-not-aleri {
    height: 690px !important;
  }
}
@media (max-width: 500px) {
  .landing-sec-2 {
    margin-top: 100px;
  }
}
@media (max-width: 488px) {
  #why-not-aleri {
    /* height: 418px !important; */
  }
  .landing-sec-2 {
    /* margin-top: 750px !important; */
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
}
@media (max-width: 483px) {
  /* .landing-sec-2 {
    margin-top: 420px;
  } */
  .jen {
    font-size: 220% !important;
  }
  /* .landing-sec-2 {
    margin-top: 550px;
  } */
  /* #why-not-aleri {
    height: 448px !important;
  }
  .landing-sec-2 {
    margin-top: 750px !important;
  } */
  #badges {
    margin-top: 200px;
  }
  #why-not-aleri {
    top: 930px;
  }
}
@media (max-width: 480px) {
  #why-not-aleri {
    height: 690px !important;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  .under-head {
    margin-top: 15px !important;
  }
  .under-head ul li {
    font-size: 60%;
    padding: 5px;
  }
  #feature-landing {
    margin-bottom: 200px;
  }
}
@media (max-width: 460px) {
  /* .landing-sec-2 {
    margin-top: 420px;
  } */
  .jen {
    font-size: 200% !important;
  }
  /* .landing-sec-2 {
    margin-top: 550px;
  } */
  /* #why-not-aleri {
    height: 468px !important;
  }
  .landing-sec-2 {
    margin-top: 750px !important;
  } */
  #why-not-aleri {
    padding-top: 0;

    height: 650px !important;
  }
}
@media (max-width: 431px) {
  /* #why-not-aleri {
    height: 490px !important;
  }
  .landing-sec-2 {
    margin-top: 750px !important;
  } */

  .cap-img-1 {
    left: 15%;
  }
  #featured img {
    width: 350px !important;
  }

  #feature-landing {
    height: 400px;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  #why-not-aleri {
    top: 840px;
  }
}
@media (max-width: 423px) {
  #why-not-aleri {
    padding-top: 0;
  }
  .cap-img {
    margin-top: 20px !important;
  }
}

@media (max-width: 380px) {
  .jen {
    font-size: 150% !important;
  }
  /* .landing-sec-2 {
    margin-top: 550px !important;
  }
  #why-not-aleri {
    height: 490px !important;
  } */
}

@media (max-width: 374px) {
  #featured img {
    width: 300px !important;
  }
  .sales {
    padding-top: 10px;
    font-size: 80%;
  }
  /* .under-head {
    margin-top: 0px;
  } */

  .badged {
    width: 150px;
  }
  .badged-1 {
    width: 75px;
    margin-left: 20px;
  }
}
