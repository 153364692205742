.s-item {
  background-color: transparent;
  color: #0f7ec0;
  box-shadow: inset 0 0 0 1px #0f7ec0;
  border: none;
  margin-left: 8px;
}
.s-item:hover {
  background-color: #0f7ec0;
  border: none;
}

/* .s-item-documents {
  font-size: 13px;
} */

.suggestion {
  height: 58px;
  width: 100%;
}
#foc .suggestion {
  height: 45px;
}
.tech-info {
  background-color: transparent;
  color: #0f7ec0;
  box-shadow: inset 0 0 0 1px #0f7ec0;
  border: none;
  margin-left: 8px;
  float: right;
  font-size: 12px;
}
.tech-info:hover {
  background-color: #0f7ec0;
  border: none;
}
.s-items {
  position: absolute;
  right: 10px;
  left: 10px;
  height: 52px;
  bottom: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  /* padding-bottom: 10px; */
  white-space: nowrap;
}

.s-items button {
  max-height: 40px;
}
/* .s-items::before {
  width: 2000px;
  height: 20px;
  background-color: red;
  content: 'Read this: ';
  z-index: 90000;
}
.s-items::after {
  width: 20px;
  height: 20px;
  background-color: blue;
  content: 'Read this: ';
} */
.s-items::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
  /* background-color: #f5f5f5; */
}

.s-items::-webkit-scrollbar {
  height: 2px;
  background-color: transparent;
  margin-top: 10px !important;
}

.s-items::-webkit-scrollbar-thumb {
  background-color: #0f7ec0;
}
.expanded::-webkit-scrollbar-thumb {
  background-color: #0f7ec0;
}
#focus-form .form-group {
  margin-bottom: 0 !important;
}
#focus-form textarea {
  padding: 0 5px;
}
