.important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 7px;
  cursor: pointer;
  z-index: 999 !important;
  display: block;
}
/* .card_important_comment {
  display: block;
} */
.comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 7px;
  cursor: pointer;
  z-index: 999 !important;
  display: none;
}
.not_important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 7px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: block;
}
.not_comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 8px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: none;
}

/*  */
/*  */
/*  */

#focus-form .col-md-12 {
  padding: 0;
}

#focus-form .saved-input {
  padding: 3px;
}

#foc .to-center .not_important,
#foc .to-center .important {
  width: 18px;
  position: absolute;
  left: -25px;
  top: 25px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: block;
}

#foc .to-center .not_comment,
#foc .to-center .comment {
  width: 18px;
  position: absolute;
  right: -25px;
  top: 25px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: none;
}
.underline-t .important,
.underline-t .not_important {
}

.underline-t {
  position: relative;
  width: 100%;
}
#focus-form .col-md-12 {
  padding: 0 !important;
}
#foc textarea {
  padding: 0;
}
#foc .saved-input {
  padding: 0;
}
#foc .important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 8px;
  cursor: pointer;
  z-index: 999 !important;
  display: block;
}
#foc .comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 8px;
  cursor: pointer;
  z-index: 999 !important;
  display: none;
}
#foc .not_important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 8px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: block;
}
#foc .not_comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 8px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: none;
}

/* split */
.c4 #wit-dash,
.c4-u #wit-dash {
  padding: 10px 20px;
}
/* .c4-left #wit-dash,
.c4-left-u #wit-dash {
  padding: 10px;
} */

.c4-left #wit-dash .not_important,
.c4-left-u #wit-dash .not_important,
.c4-right #wit-dash .not_important,
.c4-right-u #wit-dash .not_important,
.c4-left #wit-dash .important,
.c4-left-u #wit-dash .important,
.c4-right #wit-dash .important,
.c4-right-u #wit-dash .important {
  display: inline-block;
  position: relative;
  left: unset;
  top: 0;
  padding: 0;
  margin-right: 7px;
}
.c4-left #wit-dash .not_comment,
.c4-left-u #wit-dash .not_comment,
.c4-right #wit-dash .not_comment,
.c4-right-u #wit-dash .not_comment,
.c4-left #wit-dash .comment,
.c4-left-u #wit-dash .comment,
.c4-right #wit-dash .comment,
.c4-right-u #wit-dash .comment {
  display: none;
  position: relative;
  right: unset;
  top: 1px;
  padding: 0;
}
.c4-left #wit-dash .saved-input .ref,
.c4-left-u #wit-dash .saved-input .ref,
.c4-right #wit-dash .saved-input .ref,
.c4-right-u #wit-dash .saved-input .ref {
  padding: 0;
}
.not_important:hover,
.not_comment:hover {
  opacity: 1 !important;
}

.c4-left #wit-dash .not_important,
.c4-right #wit-dash .not_important,
.c4-left #wit-dash .not_comment,
.c4-right #wit-dash .not_comment {
  /* display: none; */
  opacity: 0.8;
}
.c4-left #wit-dash .focus .ref:hover .not_important,
.c4-right #wit-dash .focus .ref:hover .not_important,
.c4-left #wit-dash .focus .ref:hover .not_comment,
.c4-right #wit-dash .focus .ref:hover .not_comment {
  /* display: inline-block !important; */
}

/* check */
.c4-left #foc .to-center .not_important,
.c4-left-u #foc .to-center .not_important,
.c4-right #foc .to-center .not_important,
.c4-right-u #foc .to-center .not_important,
.c4-left #foc .to-center .important,
.c4-left-u #foc .to-center .important,
.c4-right #foc .to-center .important,
.c4-right-u #foc .to-center .important {
  display: inline-block;
  position: absolute;
  left: unset;
  top: 0;
  padding: 0;
  margin-right: 7px;
  left: 0 !important;
}
.c4-left #foc .to-center .not_comment,
.c4-left-u #foc .to-center .not_comment,
.c4-right #foc .to-center .not_comment,
.c4-right-u #foc .to-center .not_comment,
.c4-left #foc .to-center .comment,
.c4-left-u #foc .to-center .comment,
.c4-right #foc .to-center .comment,
.c4-right-u #foc .to-center .comment {
  display: none;
  position: absolute;
  right: unset;
  top: 1px;
  padding: 0;
  left: 30px !important;
}

.progress {
  background-color: rgba(148, 157, 176, 1);
  height: 0.5rem !important;
}

.progress-bar {
  background-color: #0f7ec0;
}
#dark .progress {
  background-color: rgba(255, 255, 255, 0.749);
}
.document-x {
  width: 12px !important;
  float: right !important;
  margin-right: 0px;
  margin-left: 5px;
}

.document-u {
  width: 14px !important;
  float: left !important;
}
.card-type {
  float: left;
  padding: 0 5px;
  font-size: 15px !important;
  padding-bottom: 0;
  color: #949db0 !important;
}
.card-type span {
  font-size: 15px !important;
  color: #949db0 !important;
}
.document-u {
  margin-left: 5px;
}
.temp {
  line-height: 0.5;
  padding: 0px !important;
  margin: 5px !important;
  margin-left: 0 !important;
  font-size: 26px;
}
#Navigation .navbar-brand {
  padding-top: 4px;
}
.underline-t .s {
  height: 18px;
}
.side-nav-t {
  margin-right: 5px;
  margin-left: 0;
}
@media (max-width: 991px) {
  .temp {
    display: none;
  }
}
