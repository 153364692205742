.css-1rklsty-TimelineItemContentWrapper {
  min-height: 0px !important;
}

.css-16u30nb-TimelineControlContainer {
  margin-top: 15px;
}

.date-picker-dark {
  width: 110%;
  background-color: transparent;
  color: white;
  border-right: none;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid white;
}

.date-picker-white {
  width: 110%;
  background-color: transparent;
  color: #212529;
  border-right: none;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid #212529;
}
