#wit-dash {
  height: 100%;
}

#wit-dash .container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.widgets {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.widget-item,
.widget-item-dark {
  height: 180px;
  width: 180px;
  margin: 0.8rem 1rem;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-item {
  border-color: rgba(246, 249, 255, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px #b0b5bb;
  border-width: 1px;
}

.widget-item-dark {
  background-color: #242527;
  color: #f9f9f9;
  box-shadow: rgb(38 41 46 / 72%) 0px 0px 20px 1px,
    rgb(16 19 23) 2px 3px 3px 0px;
  border: 1px solid #191a1b;
}

.widget-item-dark:hover,
.widget-item:hover {
  cursor: pointer;
}

.widget-item:hover {
  background-color: #f9f9f9;
}
