.ref .logo-title {
  position: absolute;
  right: 4px !important;
  top: 4px;
  width: 15px !important;
}
.ref .form-group {
  margin-bottom: 0;
}
.underline-t .logo-title {
  width: 12px;
}
.ref-logo-title {
  position: absolute;
  right: 5px !important;
  top: 4px;
  width: 12px;
  cursor: pointer;
}
.saved-input a,
.saved-input a:active,
.saved-input a:hover,
.saved-input a:focus,
.saved-input p,
.saved-input p:active,
.saved-input p:hover,
.saved-input p:focus {
  font-size: 20px !important;
  color: #0f7ec0;
  cursor: pointer;
  padding: 2px 2px 2px 5px;
  overflow: auto !important;
}

.ref-end {
  /* border-right: 1px solid #2f3740; */
}
.s .drag {
  position: absolute !important;
  right: 15px !important;
  top: 1px !important;
  width: 1.5em !important;
}

.disc3 {
  border-right: 1px solid lightgrey;
}
#dash-d-main .season {
  cursor: pointer;
}
.focus_doc {
  height: 50px;
}
.focus_topic,
.focus_topic:active,
.focus_topic:hover,
.focus_topic:focus {
  margin-bottom: 0;
  color: #8a0468 !important;
}
.focus_summary,
.focus_summary:active,
.focus_summary:hover,
.focus_summary:focus {
  margin-bottom: 0;
  color: #c43838 !important;
}

.focus_fact,
.focus_fact:active,
.focus_fact:hover,
.focus_fact:focus {
  margin-bottom: 0;
  color: #dd482a !important;
}
.saved-input a,
.saved-input a:active,
.saved-input a:hover,
.saved-input a:focus {
  padding: 0 !important;
}
.centre_link {
  line-height: 2;
  margin-left: 4px;
}
.documentList {
  width: 100%;
  border: 1px solid rgb(163, 155, 155);
  padding: 10px;
  background-color: rgb(163, 155, 155);
  border-radius: 5px;
  max-height: 230px;
  overflow-y: scroll;
}
.listItem {
  width: 100%;
  background: white;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  color: black;
}
.dark-modal .listItem {
  border: 1px solid black;
  background: #242527;
  color: #e2e2e2;
}
.listItem:hover,
.listItem:active,
.listItem:focus {
  border: 1px solid #0f7ec0;
  color: #0f7ec0;
}
.listItem p {
  margin: 0 !important;
}
.no_ref {
  text-align: center;
  margin-bottom: 0 !important;
}
