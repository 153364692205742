/* CSS For:
  - Tag.js
  - SummaryHighlightModal.js
  - Summary.js
*/

.custom-class {
  background-color: rgb(253, 253, 62);
  color: black !important;
  font-size: 20px;
}

.notes span {
  font-size: 20px;
  color: black;
}

.dark .notes span {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.749);
}

.highlighted-text {
  font-size: 20px;
}
.highlight {
  height: auto !important;
  word-break: break-word;
}
.highlight-switch {
  margin-top: 3%;
  /* margin: auto; */
}

.highlight-class {
  width: 100%;
  padding: 2px 2px 2px 5px;
  height: auto !important;
  word-break: break-word;
}

#current-witness {
  background-color: #97caef;
  color: black;
}

.factItem {
  width: 100%;
  background: white;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  color: black;
}

.dark-modal .factItem {
  border: 1px solid black;
  background: #242527;
  color: #e2e2e2;
}

.factItem:hover,
.factItem:target,
.factItem :checked,
.factItem :focus,
.--selected {
  border: 1px solid #0f7ec0;
  color: #0f7ec0;
}
.factItem p {
  margin: 0 !important;
}

.factItem-text {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
  /* margin: auto; */
  /* align-items: flex-end; */
  padding-top: 4%;
}

.factItem-witness {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  justify-content: flex-end;
  /* align-items: ; */
  /* align-items: flex-end; */
}

.selected-fact {
  border: 2px solid #0f7ec0;
  color: #0f7ec0;
}

.fact-witness-name {
  color: #0f7ebf !important;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start; */
  /* padding-right: 100%; */
  /* width: 100%; */
  line-height: 1.2 !important;
  width: 70%;
}

.fact-card-name {
  line-height: 1.2 !important;
}

/* #fact-witness-name {
  width: 50%;
  /* margin-right: 20%;
} */

/* .buckets {
  /* display: flex;
  flex-direction: row; */
/* width: 100%;
  height: 50px;
  margin: 50px;
  padding: 50px; 
} */

/* .bucket-labels {
  display: flex;
  flex-direction: column;

}

.witness-fact {
  display: flex;
  flex-direction: row;
} */

.bucket-labels {
  margin-right: 50%;
  margin-bottom: 20px;
}

.bucket-labels-btn {
  /* width: 100%; */
  margin-left: 0 !important;
}

.witness-fact-bucket-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  margin: auto;
  /* gap: initial;
  row-gap: 10px; */
}

.fact-card {
  margin: auto;
}
.view-all-btn-1 {
  margin-bottom: 10px;
  margin-left: 0 !important;
}
.view-all-btn {
  margin-bottom: 10px;
}
.select-tag {
  margin-left: 0;
}

.all-tag textarea {
  cursor: default !important;
}
