@import url(https://fonts.googleapis.com/css?family=Josefin + Sans:300, 400);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* .documentaton{ */

.text-left ol,
.text-left ul {
  margin: 30px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  height: 100%;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 !important;
  margin: 0 !important;
}
.dropdown-menu {
  padding: 0;
}

.dropdown-item:hover a {
  color: #0f7ec0 !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background: white !important;
}
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item a:active,
.dropdown-item a:focus {
  background: white !important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  z-index: 9999 !important;
}
.sortable-ghost,
.sortable-chosen {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
#landing-info-row {
  margin-top: 50px;
}
.main-unsupport {
  width: 100%;
  color: black;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 200px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10000 !important;
}
.main-unsupport h6 {
  text-align: center;
  font-weight: 700;
}
.not-comp {
  margin-top: 30px;
}

.bbtn {
  background-color: black;
  color: white;
}
.wbtn {
  background-color: black;
  color: white;
}
.unsupport-msg {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.zoom-img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.bg-1 {
  padding-top: 20px;
  background-color: #192a42;
  z-index: -1;
  width: 70%;
  margin: auto;
  height: 400px;
  background-position: 10% 100%, 100% 0%;
  background-size: 130px, 125px;
  background-repeat: no-repeat, no-repeat;

  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg),
    url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg);
  border-radius: 4px;

  position: absolute;
  top: -20px;
  left: 0%;
  right: 0%;
}
.step-by-step {
  font-size: 10px;
  position: absolute;
  right: 3px;
  top: 3px;
  color: #0f7ec0;
}
.bg-2 {
  padding-top: 20px;
  background-color: #192a42;
  z-index: -1;
  width: 70%;
  margin: auto;
  height: 400px;
  background-position: 10% 100%, 100% 0%;
  background-size: 130px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg),
    url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg);
  border-radius: 4px;
  position: absolute;
  top: -20px;
  left: 0%;
  right: 0%;
}

.bg-3 {
  padding-top: 20px;
  background-color: #192a42;
  z-index: -1;
  width: 70%;
  margin: auto;
  height: 407px;
  background-position: 10% 100%, 100% 0%;
  background-size: 130px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg),
    url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg);
  border-radius: 4px;
  position: absolute;
  top: -20px;
  left: 0%;
  right: 0%;
}
.bg-4 {
  padding-top: 20px;
  background-color: #192a42;
  z-index: -1;
  width: 70%;
  margin: auto;
  height: 400px;
  background-position: 10% 100%, 100% 0%;
  background-size: 130px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg),
    url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg);
  border-radius: 4px;
  position: absolute;
  top: -20px;
  left: 0%;
  right: 0%;
}

.head-left {
  margin-left: 36px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}
.head-right {
  margin-right: 36px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}
.card-img-top {
  border-radius: 8px !important;
  border-color: transparent !important;
}
.chrome-download {
  position: relative;
  top: 20px;
  padding: 10px;
  color: white;
  background-color: #2f3740;
  border-radius: 5px;
  -webkit-align-items: center;
          align-items: center;
  /* margin-left: 25%; */
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}
.chrome-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  margin-right: 10px;
}
.chrome-download:hover,
.chrome-download:focus {
  outline: none;
  text-decoration: none;
  color: white;
}
.element-div {
  width: 100%;
  height: 100px;
}

.tab-m {
  /* margin: 20px !important; */
}
.tab-image {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

/* #landing-infos {
  background-color: linear-gradient(55deg, white 0%, white 51%, #2e3233 85%);
} */
/* New Features---------------------------------------- */
.fea-head {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}
.sub-div {
  /* widows: 100%; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 30px;
  width: 100%;
}
/* .watermark-div {

  width: 90%;
  height: 85%;
  position: absolute;
  top: 100px;
  left: 0px;
  background-image: url("../src/pics/tod-dark.svg");
  background-repeat: no-repeat;
  opacity: 0.04;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
} */

.main-feature {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  margin-top: 7%;
  /* height: calc(100vh - 50px); */
  scroll-behavior: smooth;
}

.main-feature::-webkit-scrollbar,
.main-container::-webkit-scrollbar {
  display: none;
}
.main-feature::-webkit-scrollbar,
.main-container::-webkit-scrollbar {
  -ms-overflow-style: none;
}
.main-container {
  position: relative;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  overflow: auto;
  -webkit-flex: none;
          flex: none;
  scroll-behavior: smooth;

  overflow-x: hidden;
}
.main-container.y {
  top: 0;
  width: 100%;
  height: 100vh;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}
.y.mandatory-scroll-snapping {
  scroll-snap-type: y mandatory;
}
.main-container > div {
  text-align: center;
  scroll-snap-align: center;
  -webkit-flex: none;
          flex: none;
}
.y.main-container > div {
  /* line-height: 256px; */
  width: 100%;
  height: 100vh;
}
.y.main-container > div:first-child {
  line-height: 1.3;
  font-size: 10px;
}
.fea-image {
  width: 600px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: -1px 1px 20px -1px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}

.text {
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 15px;
  padding-right: 15px;
}
/* End New Featues  --------------------------------------*/

.info-c.card {
  margin: 0 auto;
  margin-bottom: 2em;
  width: 20rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.info-c:last-child:hover {
  margin-bottom: 40px;
}

.info-c .h4 {
  margin-top: 90px;
  margin-bottom: 30px;
}

#info .h1 {
  /* margin-top: 30px; */
  margin-bottom: 70px;
}
.fea-head {
  background-color: white;
}
/* Unsupported Browser -------------------------- */

.fea-section {
  scroll-snap-align: start;
}

.main-unsupport {
  width: 100%;
  color: black;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 200px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10000 !important;
}
.main-unsupport h3 {
  text-align: center;
}
.unsupport-msg {
  font-size: 16px;
}
.chrome-download {
  position: relative;
  top: 120px;
  /* margin-top: 100px; */
  padding: 10px;
  color: white;
  background-color: #2f3740;
  border-radius: 5px;
  -webkit-align-items: center;
          align-items: center;
}
.phil {
  width: 80px;
  z-index: 1000;
}
.chrome-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}

.chrome-download:hover,
.chrome-download:focus {
  outline: none;
  text-decoration: none;
  color: white;
}

/* End Unsupported Browser ----------------------  */

/* Dark Section ----------------- */

.intro-light {
  color: #5a6269;
}
.dark-sub-head {
  color: #5a6269;
}

.dark-img {
  width: 50em;
  height: 40em;
}
.dark-img-flip {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

#dark-img-wrapper {
  max-width: 100%;
  margin: 10px auto 10px auto;
}
#outer {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.dark-image-wrapper {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}
.dark-image,
.light-image {
  width: 100%;
  height: auto;
}
/* .dark-image:hover {
  background-color: #e7e4e4;
  transition: all 0.3s ease-in-out;
}
.light-image:hover {
  background-color: #2d353d;
  transition: all 0.3s ease-in-out;
}
.fade-out {
  transition: all 0.3s ease-in-out;
} */

/* End  Dark Section ----------------- */

#ace {
  color: #0f7ec0;
}
.com-app-icon-group {
  top: 24%;
}
.last-icon {
  bottom: 0;
  position: absolute;
}

.second-last-icon {
  bottom: 60px;
  position: absolute;
}

btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(38, 143, 255, 0.5);
}

.main-image {
  position: relative;
  right: 24%;
  top: -104px;
  width: 100%;
  z-index: 1;
}

#feature-landing-texts h1 {
  text-align: center !important;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}
input[type="checkbox"] {
  margin-right: 10px;
}

.alert-danger {
  padding: 0px !important;
  width: 65%;
  margin: 0 auto;
  text-align: center;
}

/* react stripe elements test style */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: white;

  box-shadow: 1px 8px 8px 1px #e6ebf1, 5px 2px 8px 4px #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
/* end stripe test */
.form-check-input {
  position: relative;
}
.underline-settings {
  height: 80px !important;
}
.landing-sec-2-social {
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 20px 100px;
  /* border-top: 1px solid rgb(141, 162, 181); */
  /* background-color:  rgb(47, 55, 64); */
}
.social {
  width: 66px;
}
/* stripe */
#landing {
  margin-top: 50px;
}
.landing-sec-2 {
  width: 100%;
  height: 100%;
  /* margin-top: 200px;
  padding: 100px; */
  /* background-color: thistle; */
}
.main-head {
  margin-top: 2em;
}
.main-head h1 {
  margin-top: 200px;
}
.main-head p {
  padding: 30px;
  width: 80%;
  -webkit-align-items: center;
          align-items: center;
  margin: 10px auto;
}
.langing-img {
  margin: 0 auto;
  height: 50%;
  width: 70%;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#paymentForm p {
  text-align: left;
  font-size: 80%;
}
.video iframe {
  width: 80%;
  height: 650px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 50px;
  margin-top: 50px;
}
.video {
  width: 100%;
}
.video h4 {
  margin-bottom: 25px !important;
}

.video img {
  margin: 50px 0 !important;
}

.landing-video {
  margin: 0 auto;
  height: 50%;
  width: 70%;
  /* padding: 1px; */

  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.landing-btn1,
.landing-btn2 {
  width: 150px !important;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 1px 2px 4px 0px rgb(104, 100, 100);
}
.landing-btn2 {
  background-color: white;
  color: black;
  border: none;
  z-index: 1000;
}

.landing-btn2:hover {
  background-color: #222222;
  box-shadow: #222 1px 2px 4px 0px;
  border: none;
}
.landing-btn1 {
  background-color: #222;
  border: none;
}
.landing-btn1:hover {
  box-shadow: #222 1px 2px 4px 0px;
  border: none;
  background-color: #1d2229;
}
.landing-section-2 {
  box-shadow: #d1d2d4 2px 5px 10px 6px;
}

.features-scroll {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: bold;

  width: 100%;
  height: 100vh;
}

#Landing label {
  text-transform: capitalize;
  /* width: 70%; */
  font-size: 140%;
  margin-bottom: 50px;
}
.form-check-label {
  width: unset;
}
input[type="checkbox"],
input[type="radio"] {
  /* width: 50px;
  height: 50px; */
  padding-right: 5px;
}
.thanks-msg {
  margin: 0 auto;
  width: 67%;
  font-size: 16px;
}
.sent-msg {
  background: black;
  color: white;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.bin {
  padding-top: 7px;
}

.form-check-label {
  width: unset;
  font-size: 80%;
  margin-bottom: unset;
}
.MyCardElement {
  padding-top: 20px !important;
  margin-top: 30px !important;
  height: 20%;
}
#coupon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px transparent;
  border-bottom: 1px #222 solid;
  outline: none;
}
#coupon:focus {
  border: transparent;
  outline: none;
}
#Landing button {
  width: 100%;
  /* margin-left: 10px; */
  transition: 0.4s;
}
.InputElement {
  font-size: 120% !important;
}
.info-strategy-main {
  display: -webkit-flex;
  display: flex;
}
.info-strategy {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
}
.info-strategy-p {
  padding: 0 !important;
  white-space: inherit !important;
}

/* stripe */

.svg-icon {
  margin-right: 5px;
}

.help {
  padding: 40px;
  font-size: 16px;
  font-weight: 2 00;
  padding-top: 20px;
}
.topic-title span {
  font-weight: 100;
  line-height: 10px;
}
.topic-title {
  font-size: 160%;
  line-height: 1.2 !important;
}
.inside-card {
  width: 200px;
  height: 95px;
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, white 50%, #f9f9f9 50%);
  transition: background-position 0.5s;
  padding: 6px;
  border-radius: 6px;
  border-width: 1px;
  border-bottom: unset !important;
  border-color: rgba(246, 249, 255, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px rgba(238, 238, 238, 0.15);
  box-shadow: 0 1px 3px 1px #b0b5bb;
  z-index: 100000;
}
.css-vshini {
  min-height: 0 !important;
}
.inside-card:hover {
  background-position: 0 -100%;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  margin: 5px;
}
.parent-side-nav-card p {
  white-space: normal !important;
  max-height: 8.6em !important;
  width: 100% !important;
}
#wit-dash .parent-side-nav-card p,
.dragging .parent-side-nav p {
  max-height: 3em !important;
}

#wit-dash .tag p,
.dragging .tag p {
  max-height: 3.5em !important;
}
#wit-dash .parent-side-nav,
.dragging .parent-side-nav p {
  border-radius: 7px;
}
.uncap {
  text-transform: none !important;
}
#dash-d-main {
  text-align: right !important;
  margin: 40px 15px;
  color: #0275d8 !important;
}
.dash-foot {
  text-align: left !important;
  color: rgb(148, 157, 176);
}
#dash-d-main-verification {
  text-align: left !important;
  margin: 40px 15px;
}
.season {
  color: #d35400 !important;
}
.upgrade {
  color: #0275d8;
  cursor: pointer;
}

/* step registration */
ol.progtrckr {
  list-style-type: none;
  padding: 0;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
}
ol.progtrckr li span {
  padding: 0 1.5rem;
}
.collab-panel {
  padding: 15px 12px;
  border: 1px solid rgba(219, 215, 215, 0.226);
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px !important;

  box-shadow: 4px 4px 4px 1px #d5d5d3;
  transition: box-shadow 150ms ease;
}
.collab-panel.list-group-item.underline-settings > div .col-md-3:first-child {
  color: #0275d8;
  font-weight: 700;
}
.firm-name {
  color: #0275d8;
  cursor: pointer;
}
.badge-pill {
  font-weight: 500 !important;
  background-color: darkorange;
  font-size: 15px !important;
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
  display: inline;
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}
ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #0275d8;
}
ol.progtrckr li.progtrckr-done {
  color: white;
  border-bottom: 4px solid #5cb85c;
}
ol.progtrckr li:after {
  content: "\00a0\00a0";
}
ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}
ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}
/* ol.progtrckr li.progtrckr-todo:hover:before {
  color: #0b1a38;
} */
ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #0275d8;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

body {
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  font-family: "Muli", sans-serif;
  /* background-image: url('https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/paper-background_gcwnwl.svg'); */
  background-attachment: fixed;
}
.l-simple-text__inner {
  text-align: justify;
  padding: 0 1em 0;
}
.l-simple-text__inner h4 {
  text-align: left;
}
.l-simple-text__inner table {
  text-align: left;
}
.cases-two {
  padding-bottom: 100px !important;
}
.cases textarea {
  width: 100%;
  padding: 5px;
  font-size: 20px;
}
#comp-auth-nav-expanded {
  top: 0;
  bottom: 0;
  border-radius: unset;
}
#focus-form textarea {
  width: 100%;
  padding: 5px;
  font-size: 20px;
}
#wit-dash h1 strong {
  font-size: 80%;
}
.w-home-2 {
  margin-left: 0;
  /* height: 40px; */
}
.w-home-2 .add-case {
  width: 22px !important;
  height: 22px !important;
  margin-left: 0 !important;
  padding-right: 0;
  box-shadow: none;
}

.w-home-2 .logo-title {
  width: 22px !important;
  height: 13px !important;
}
.w-home-2 .logo-title-2 {
  width: 22px !important;
  height: 11px !important;
}
.w-home-2 .add-case {
  background-color: white !important;
}
.func {
  margin: 20px 3px !important;
  height: 60px;
  width: 60px !important;
}

.wit-title {
  text-align: center;
  margin-top: 10px;
  font-size: 120%;
  font-weight: 400;
}
.wit-title-z {
  font-size: 100%;
}
.inside-card {
  width: 100%;
  height: 95px;
}
.case-btn-side-w {
  /* width: 215px; */
  width: 100%;
  /* padding-right: 10px; */
}
.case-btn-side {
  position: relative;
}
.case-btn-side .progress {
  width: 50px;
  position: absolute;
  top: 10px;
}
.case-btn-side-w .w-home-2 {
  float: right;
  /* padding-right: 5px; */
}

.case-btn-side-w .w-home-2:first-child {
}
.case-btn-side-w .side-nav-t-overide {
  width: 22px !important;
  /* margin: 5px; */
}
.case-btn-side {
  /* position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 40px; */
  float: right;
  /* width: 80px; */
}

.side-nav-t-t-m {
  width: 100%;
}
.side-nav-t-t-m .case-btn-side {
  width: 100%;
  padding: 18px 4px;
  padding-top: 0;
}
.side-nav-t-t-m .case-btn-side .btn-toolbar {
  float: right;
}
.side-nav-t-overide {
  width: 22px !important;
  margin: 0 !important;
}
.logo-settings-top {
  width: 22px;
  z-index: 4000;
  float: left;
  margin-left: 20px;
}
.w .case-btn-side-t {
  padding-bottom: 20px;
}
.case-btn-side-t {
  margin-top: 0px;
  position: relative;

  /* position: absolute;


  right: 0;
  top: 0;
  width: 50px;
  height: 40px; */
  /* float: left; */
  /* width: 80px; */
}
.logo-c {
  width: 24px !important;
}

.inside-btn {
  float: right;
}

.logo-s {
  text-align: center !important;
  width: 14px !important;

  margin-bottom: 4px;
}
.logo-settings {
  width: 14px !important;
  margin-bottom: 4px;
}
.logo-settings-b {
  width: 14px !important;
}

.navToggle button:focus {
  outline: none !important;
  border: none !important;
}
.mobile-screen {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 !important;
}

.topic-l {
  margin: auto !important;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.wit {
  width: 90%;
  margin: auto;
}
.wit img {
  width: 174px;
  display: block;
  margin: auto;
  height: 140px;
}

.mobile-screen {
  display: none;
}
.top-form {
  width: 100%;
  /* background-color:#0f7ec0 ; */

  height: 300px;
  /* margin-bottom: 100px; */
  color: black;
  text-align: center;
  padding-top: 50px;
}
.top-form:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 350px;
  z-index: 1;
  opacity: 0.19;
  background-image: url("https://res.cloudinary.com/aleri/image/upload/v1593642443/Aleri/about-banner_aon1pl.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  background-size: cover;
}

#case-dashboard {
  position: fixed;
  top: 50px;
  right: 10px;
  margin: 10px;
}
#case-dashboard-footer {
  position: fixed;
  bottom: 0;
  margin: 20px;
}
.card-body {
  margin-bottom: 20px;
}

.sales {
  width: 100%;
  height: 40px;
  color: white;
  font-weight: 200;
  background-color: #142032;
  /* opacity: 0.66; */
  padding: 7px;
  z-index: 10 !important;
  position: relative;
  /* z-index: 10 !important; */
}
.sales a {
  color: white;
  cursor: pointer;
}
.sales a:hover {
  text-decoration: none;
}
.bottom-form {
  width: 100%;
  background-color: #0f7ec0;
  height: 100px;
  color: white;
  text-align: center;
  padding-top: 35px;
  margin-top: 100px;
}
.bt-footer {
  height: 100px;
  width: 100%;
  text-align: center;
  background-color: #142032;
  color: white;
  margin-top: 50px;
}
table:not(.has-no-style) td {
  border: 0 solid #d9dde2;
  padding: calc(0.5 * 1rem) calc(0.75 * 1rem);
}
table:not(.has-no-style) td {
  border: 0 solid #d9dde2;
  padding: calc(0.5 * 1rem) calc(0.75 * 1rem);
}
table:not(.has-no-style) th {
  background: #2d3c43;
  color: #fff;
}

table:not(.has-no-style) th {
  border: 0 solid #d9dde2;
  padding: calc(0.5 * 1rem) calc(0.75 * 1rem);
}
.App {
  text-align: center;
}

.land-logo {
  /* width: 36px; */
  max-width: 200px;
  max-height: 62px;
  margin-right: 42px;
  display: block;
  width: auto;
}

.copyright-logo {
  font-size: 200% !important;
  margin-bottom: 20px;
  color: #142032;
}

#focus-form {
  width: 100%;

  margin-bottom: 200px;
}
.focus-f {
  margin-top: 50px;
  padding-bottom: 200px !important;
}

#authFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 80px;
  width: 100px;
  color: rgb(148, 157, 176);
}
.focus {
  margin-top: 100px;
}
.step-progress .registrationForm form {
  /* padding-top: 0 !important; */
}
.registrationForm form {
  width: 90%;
  margin: auto;
  padding-top: 0px;
}

.registrationForm form button {
  width: 50%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.registrationForm h1 {
  margin-bottom: 20px;
}
.registrationForm h4 {
  font-weight: 300;
}
.create {
  width: 36px;
  padding-left: 4px;
}

#user-settings h1 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

#user-settings h2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

#user-settings h3 {
  padding-top: 40px;
  padding-bottom: 20px;
}

#user-settings h5 {
  padding-bottom: 20px;
}

#user-settings h5 span {
  font-weight: 700;
}
#user-settings label.form-label {
  font-weight: 700;
}
.czhaTb {
  /* display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    right: -18px;
    height: 100%;

    z-index: 30;
     */

  position: fixed;
  top: 0;
  bottom: 0;
  cursor: w-resize;
  left: 300px;
  width: 18px;
  background: transparent;
  /* border-right: 1px solid #dfe3ed; */
  z-index: 100;
  overflow: auto;
}

.czhaTb::after {
  position: absolute;
  top: 50%;
  right: 6px;
  height: 20px;
  width: 4px;
  background-color: #8da2b5;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  /* opacity: 0.5; */
  content: "";
  border-radius: 3px;
}
.regScroll {
  overflow-y: scroll;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-bottom: 200px;
}
.czhaTb-notExpanded {
  position: fixed;
  top: 0;
  bottom: 0;
  cursor: e-resize;
  left: 60px;
  width: 18px;

  background: transparent;
  /* border-right: 1px solid #dfe3ed; */
  z-index: 100;
  overflow: auto;
}
.czhaTb-notExpanded::after {
  position: absolute;
  top: 50%;
  right: 6px;
  height: 20px;
  width: 4px;
  background-color: #8da2b5;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  /* opacity: 0.5; */
  content: "";
  border-radius: 3px;
}
#comp-auth-nav-expanded {
  border-right: 1px solid #dfe3ed;
}
.comp-auth-nav {
  /* background-color: #142032; */
  /* background-color: rgba(0.12, 0.4, 0.29, 0.9); */
  /* background-color: white; */
  background: linear-gradient(to bottom, #fff 0, #f2f2f2 100%);
  /* top: 140px; */
  /* bottom: 140px; */
  top: 20%;
  bottom: 20%;
  width: 60px;
  position: fixed;
  z-index: 1001;
  /* cursor: pointer; */
  border-radius: 0 10px 10px 0;
}
.comp-auth-nav-toggle {
  display: block;
}
.mobile-auth-nav-toggle {
  display: none;
}

.com-app-icon-group {
  margin: auto;
  /* margin-top: 220px; */
  position: absolute;
  top: 29%;
  /* bottom: 120px; */
  width: 60px;
}
.add-case-btn {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  background-color: #0f7ec0;
  color: #ffffff;
  border-color: #0f7ec0;
  /* margin-bottom: 100px; */
}
#side-nav-open {
  margin-left: 60px;
  padding-top: 20px;
  /* overflow: auto; */
  padding-bottom: 500px;
}
.side-nav-open-w {
  padding-bottom: 100px !important;
}
#side-nav-open-mobile {
  display: none;
}
.add-btn-plus {
  float: right;
}
.com-p {
  padding: 7px;
}
.side-bt {
  border: none;

  color: transparent;
  position: relative;
  display: block;
  padding: none;
  margin: none;
  background-color: transparent;
  padding: 7px;
  margin: 0;
  outline: 0;
}
#top-nav {
  /* width: 70%; */
  height: 42px;
  background: #f9f9f9;
  border-bottom: 1px solid #dfe3ed;
  position: fixed;
  z-index: 1000;
  left: 60px;
  right: 0;
  overflow: hidden;
  white-space: nowrap;
}
#top-nav-expanded {
  height: 42px;
  background: #f9f9f9;
  border-bottom: 1px solid #dfe3ed;
  position: fixed;
  z-index: 1000;
  left: 300px;
  right: 0;
  overflow: hidden;
  white-space: nowrap;
  top: 0;
}
#top-first {
  position: absolute;
  right: 400px;
  left: 0;
  height: 42px;
  top: 0;
  bottom: 0;
  right: 400px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  display: -webkit-flex;
}
#top-first p {
  text-align: center;
}
/* #top-first::-webkit-scrollbar {
  height: 3px;
  background-color: black;
  color: red;
} */
/*  */
.c3::-webkit-scrollbar-track,
.c3-u::-webkit-scrollbar-track,
.c4::-webkit-scrollbar-track,
.c4-u::-webkit-scrollbar-track,
.c3-left::-webkit-scrollbar-track,
.c3-u-left::-webkit-scrollbar-track,
.c4-left::-webkit-scrollbar-track,
.c4-u-left::-webkit-scrollbar-track {
}

.c3::-webkit-scrollbar,
.c3-u::-webkit-scrollbar,
.c4::-webkit-scrollbar,
.c4-u::-webkit-scrollbar,
.c3-left::-webkit-scrollbar,
.c3-u-left::-webkit-scrollbar,
.c4-left::-webkit-scrollbar,
.c4-u-left::-webkit-scrollbar,
textarea,
.c3-right::-webkit-scrollbar,
.c3-u-right::-webkit-scrollbar,
.c4-right::-webkit-scrollbar,
.c4-u-right::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.c3::-webkit-scrollbar-thumb,
.c3-u::-webkit-scrollbar-thumb,
.c4::-webkit-scrollbar-thumb,
.c4-u::-webkit-scrollbar-thumb {
  background-color: #0f7ec0;
}
.c3-left::-webkit-scrollbar-thumb,
.c3-u-left::-webkit-scrollbar-thumb,
.c4-left::-webkit-scrollbar-thumb,
.c4-u-left::-webkit-scrollbar-thumb,
textarea,
.form-group,
.c3-right::-webkit-scrollbar-thumb,
.c3-u-right::-webkit-scrollbar-thumb,
.c4-right::-webkit-scrollbar-thumb,
.c4-u-right::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.container-fluid {
  padding: 0;
}
/*  */
#top-first::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
  /* background-color: #f5f5f5; */
}

#top-first::-webkit-scrollbar {
  height: 2px;
  background-color: transparent;
}

#top-first::-webkit-scrollbar-thumb {
  background-color: #0f7ec0;
}
.expanded::-webkit-scrollbar-thumb {
  background-color: #0f7ec0;
}
.expanded::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
  /* background-color: #f5f5f5; */
}

.expanded::-webkit-scrollbar {
  width: 1px;
  background-color: transparent;
}

#top-second {
  position: absolute;
  width: 200px;
  top: 0;
  right: 200px;
  height: 42px;
  border-right: 1px solid #dfe3ed;
  border-left: 1px solid #dfe3ed;
  background: #f9f9f9;
}
#top-second p {
  text-align: center;
}
#top-second .top-card {
  background-color: #0f7ec0;
  z-index: 100;
}
.active-top-card {
  /* border-top: 4px solid ; */
  /* -webkit-box-shadow: inset 0px 0px 0px 4px #0f7ec0;
  -moz-box-shadow: inset 0px 0px 0px 4px #0f7ec0; */
  box-shadow: inset 0px 5px 0px 0px #0f7ec0;
}

.top-l {
  margin: 10px 15px;
  width: 20px !important;
  z-index: -1;
  cursor: pointer;
}

.top-l-b {
  width: 23px !important;
}

.tabs-up {
  display: none;

  /* background: red; */
}
.tabs-down {
  display: block;
}
.tabs-down-button {
  cursor: n-resize !important;
}
.tabs-up-button {
  cursor: s-resize !important;
}
#split {
  width: 50%;
}
.c3 {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 320px;
  right: 0;
  overflow: auto;
}
.c3::after {
}
.c4 {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 60px;
  right: 0;
  overflow: auto;
}
/* .c4::after {
  content: "";

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../src/pics/tod-dark.svg");
  background-repeat: no-repeat;
  opacity: 0.05;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -10;
} */
.c3-u {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 320px;
  right: 0;
  overflow: auto;
}
/* .c3-u::after { */
/* content: ""; */
/* width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../src/pics/tod-dark.svg"); */

/* background-size: 100px 100px; */
/* background-position: 30px 30px; */
/* background-repeat: no-repeat;
  opacity: 0.05;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -10;
} */
.c4-u {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 60px;
  right: 0;
  overflow: auto;
}
/* .c4-u::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../src/pics/tod-dark.svg");
  background-repeat: no-repeat;
  opacity: 0.05;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -10;
} */
.c3-left {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 320px;
  width: 43.5%;
  overflow: auto;
  /* border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed;
  margin-top: 44px; */
}
.c3-left::after {
  position: fixed;
  width: 2px;
  background-color: #dfe3ed;
  top: 15%;
  bottom: 15%;
  content: "";
  right: 35%;
  border-radius: 9px;
}
.c4-left {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 60px;
  width: 47.5%;
  overflow: auto;
  /* border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed;
  margin-top: 44px; */
}
.c4-left::after {
  position: fixed;
  width: 2px;
  background-color: #dfe3ed;
  top: 15%;
  bottom: 15%;
  content: "";
  right: 48.5%;
  border-radius: 9px;
}
.c3-u-left {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 320px;
  width: 41.5%;
  overflow: auto;
  /* border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed;
  margin-top: 23px; */
}
.c3-u-left::after {
  position: fixed;
  width: 2px;
  background-color: #dfe3ed;
  top: 15%;
  bottom: 15%;
  content: "";
  right: 41.4%;
  border-radius: 9px;
}
.c4-u-left {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 60px;
  width: 47.5%;
  overflow: auto;
  /* margin-top: 23px;
  border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed; */
}
.c4-u-left::after {
  position: fixed;
  width: 2px;
  background-color: #dfe3ed;
  top: 0;
  bottom: 15%;
  content: "";
  right: 47.5%;
  border-radius: 9px;
}
/* right */
.c3-right {
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  width: 43.5%;
  overflow: auto;
  /* border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed;
  margin-top: 44px; */
}

.c4-right {
  position: fixed;
  top: 40px;
  bottom: 0;
  right: 0;
  width: 47.5%;
  overflow: auto;
  /* border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed;
  margin-top: 44px; */
}
/* .c4-right::before {
  position: fixed;
  width: 2px;
  background-color: #dfe3ed;
  top: 15%;
  bottom: 15%;
  content: '';
  right: 49.5%;
  border-radius: 9px;
} */

.c3-u-right {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 41.5%;

  overflow: auto;
  /* border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed;
  margin-top: 23px; */
}

.c4-u-right {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 47.5%;
  overflow: auto;
  /* margin-top: 23px;
  border-right: 3px solid #dfe3ed;
  border-top: 1px solid #dfe3ed; */
}

/* #case-menu-expanded > .c3-left,
#case-menu-expanded > .c3-right {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "hi";
  opacity: 0.4;
  width: 100vw;
  height: 100vh;
} */

div[class|="c3-left"],
div[class|="c3-right"],
div[class|="c3-u-left"],
div[class|="c3-u-right"] {
  z-index: -1;
  opacity: 0.09;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.hui {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

div[class|="c3-right"] + div[class|="hui"]::after {
  /* background-color: rgba(255, 255, 255, 0.653) !important; */
  /* color: black !important; */
  content: "Close Sidebar To Continue";
  font-size: 24px;
  bottom: 49%;
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}
div[class|="c3-u-right"] + div[class|="hui"]::after {
  /* background-color: rgba(255, 255, 255, 0.653) !important; */
  /* color: black !important; */
  content: "Close Sidebar To Continue";
  font-size: 24px;
  bottom: 49%;
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}
div[class|="hui"] ~ .expanded {
  box-shadow: 19px 1px 20px 6px #70707094;
  z-index: 1000;
}
/* right end */
.czhaTb-top {
  position: fixed;
  right: 0;
  left: 60px;
  height: 18px;

  background: transparent;
  z-index: 1000;
  overflow: auto;
  top: 40px;
  margin-bottom: 30px;
}
.czhaTb-top-expanded {
  position: fixed;
  right: 0;
  left: 300px;
  height: 18px;

  background: transparent;
  z-index: 1000;
  overflow: auto;
  top: 40px;
  margin-bottom: 30px;
}
.czhaTb-top-expanded::after {
  position: absolute;
  left: 49%;
  margin-top: 10px;
  height: 4px;
  width: 20px;
  background-color: #8da2b5;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  content: "";

  border-radius: 3px;
}

.czhaTb-top::after {
  position: absolute;
  left: 49%;
  margin-top: 10px;
  height: 4px;
  width: 20px;
  background-color: #8da2b5;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  border-radius: 3px;
}

.tabs-up-button {
  top: 0px !important;
}
.logo-top {
  width: 14px;
}
.logo-top-s {
  cursor: pointer;
}
.top-case {
  font-size: 10px;
}
.top-card img:last-child {
  visibility: hidden;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 5px;
}

.top-card:hover img:last-child {
  visibility: visible;
}
.top-card {
  height: 40px;
  width: 199px;
  display: inline-block;
  /* background-color: ; */
  /* 
  box-shadow: rgba(17, 49, 96, 0.04) 0px 0px 0px 1px,
    rgba(17, 49, 96, 0.16) 0px 2px 4px 0px; */
  border-right: 1px solid #dfe3ed;
  border-radius: 0;
  /* border-top: 4px solid #f9f9f9; */
  /* float: left; */
  margin-left: auto;
}
.top-card p {
  line-height: 1.2 !important;

  margin-bottom: 0px;
  text-align: left !important;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.top-card img {
  float: left;
  margin-right: 5px;
  margin-top: 2px;
}
.top-card p:last-child {
  text-align: left;
  font-size: 14px;
}
.top-card button {
  width: 100%;
}
#top-second p {
  color: rgb(148, 157, 176);
  line-height: 2.5;
  font-weight: 700;
}
#top-first p {
  color: rgb(148, 157, 176);
  line-height: 2.5;
  font-weight: 700;
}
#top-func {
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  position: absolute;
  width: 200px;

  top: 0;
  height: 40px;
  /* background: rgba(219, 215, 215, 0.226); */
  right: 0;
  z-index: 10000;
  background-color: #f9f9f9;
}
.side-bt-t {
  padding: 0px !important;
  width: 100%;
}
.btn:focus {
  outline: none !important;
  outline-offset: none !important;
}
.side-bt-2 {
  border: none;
  color: transparent;
  position: relative;
  display: block;
  padding: none;
  margin: none;
  background-color: transparent;
  padding: 0px;
  margin: 0;
  outline: 0;
}
.com-app-icon-proof .logo {
  width: 36px;
}
.com-app-icon-proof {
  border-bottom: 1px solid #dfe3ed;
  height: 60px;
  margin-top: 20px;
  cursor: pointer;
}
.side-bt-2 {
  position: absolute !important;
  height: 40px !important;
  padding: 7px;
  width: 199px !important;
  /* background-color: red; */
  margin: 0;
}
.side-bt:hover {
  background-color: transparent;
  border: none;
}
.side-bt:active {
  background-color: transparent !important;
  border: none !important;
  outline: 0 !important;
}
.side-bt:focus {
  background-color: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
}
.unfocus:focus {
  outline-width: 0 !important;
}
.side-bt .btn:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
}
.numberOfItems {
  float: right;
  margin-right: 25px;
  font-weight: 300;
  color: rgb(148, 157, 176);

  margin-left: 8px;
  font-size: 120%;
  margin-top: 0;
}
.add-case:hover {
  background-color: #fff !important;
  border-color: #e2e5e8 !important;
}
.add-case:focus {
  box-shadow: rgba(17, 49, 96, 0.04) 0px 0px 0px 1px,
    rgba(17, 49, 96, 0.16) 0px 2px 4px 0px;
  background-color: #fff !important;
  border-color: #e2e5e8 !important;
}
.add-case:active {
  background-color: #fff !important;
  border-color: transparent !important;
}
.btn {
  /* z-index: 3000; */
}
.side-nav-t {
  padding: 0;
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
  display: flex;
  display: -webkit-flex;
  width: 16px;
  margin-left: 3px;
  padding-left: 5px;
}
.witness-name {
  margin-right: 5px;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.side-nav-t-t {
  width: 100%;
  margin: 0;
  padding: 0;
}
.side-nav-t-t:hover {
  margin-bottom: 50px;
}
.side-nav-t-t button {
  width: 100%;
  margin: 0;
  padding: 0;
}
.p-s-n {
  height: 110px !important;
}
.side-nav-t button {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
}
.side-nav-t button:focus {
  background: transparent;

  outline: none !important;
  box-shadow: none !important;
}
.side-nav-t button:active {
  background: transparent !important;

  outline: none !important;
  box-shadow: none !important;
}
.side-nav-t-t button:focus {
  background: transparent;

  outline: none !important;
  box-shadow: none !important;
}
.side-nav-t-t button:active {
  background: transparent !important;

  outline: none !important;
  box-shadow: none !important;
}
.tooltip {
  z-index: 100000;
}
#tooltip-folder-s {
  margin-left: 15px;
  opacity: 1;
  background-color: rgb(148, 157, 176);
  color: white;
  padding: 3px;
  border-radius: 6px;
  border: 1px solid white;
  font-size: 90%;
  font-weight: 300;
}
#tooltip-folder-s > .tooltip-inner {
  background: transparent;
  color: white;
  background-color: rgb(148, 157, 176);
  border: none;
}
#tooltip-folder {
  margin-left: 15px;
  opacity: 1;
  background-color: rgb(148, 157, 176);
  color: white;
  padding: 3px;
  border-radius: 6px;
  border: 1px solid white;
  font-size: 90%;
  font-weight: 300;
}

#tooltip-folder > .tooltip-inner {
  background: transparent;
  color: white;
  background-color: rgb(148, 157, 176);
  border: none;
}

#tooltip-add {
  opacity: 1;
  background-color: rgb(148, 157, 176);
  color: white;
  padding: 3px;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid white;
  font-size: 90%;
  font-weight: 300;
}
#tooltip-add > .tooltip-inner {
  background: transparent;
  color: white;
  background-color: rgb(148, 157, 176);
  border: none;
}
.btn-toolbar {
  display: block;
}
#tooltip-add > .tooltip-arrow {
  display: none;
  color: rgb(148, 157, 176);
}
.arrow::before {
  display: none;
}
[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  margin-top: 20px;
  z-index: 100000;
}
[data-tooltip]:hover:before {
  opacity: 1;
  background-color: rgb(148, 157, 176);
  color: white;
  padding: 10px;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid white;
  font-size: 90%;
  font-weight: 300;
  z-index: 100000;
}
[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
  margin-top: 20px;
  z-index: 100000;
}
.logo-title-l {
  width: 15px !important;
}
.add-case {
  /* display: flex; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  color: rgb(141, 162, 181);
  height: 38px;
  width: 38px;
  box-shadow: rgba(17, 49, 96, 0.04) 0px 0px 0px 1px,
    rgba(17, 49, 96, 0.16) 0px 2px 4px 0px;
  padding: 0px;
  background: inherit inherit inherit inherit inherit inherit inherit inherit
    inherit;
  border-radius: 90px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: none;
  border-image: initial;
  transition: all 0.15s ease 0s;
  background-color: #fff;
}

/* .w-home-2 .add-case:focus {
  border-radius: #6c95c3 4px solid !important;
  border-radius: 90px;
} */
#side-nav-open h5 {
  /* color: rgba(0.12, 0.4, 0.29, 0.9); */
  font-size: 140%;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
}
.case-drag-single-list h4 {
  font-size: 100%;
  font-weight: 400;
}
.logo-title {
  width: 18px;
  margin-bottom: 2px;
  cursor: pointer;
}

button.add-case.plus-icon.btn.btn-primary,
button.add-case.plus-icon.btn.btn-primary:hover {
  background-color: #0f7ec0 !important;
}

img.logo-title.plus-icon {
  -webkit-filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(1deg) brightness(105%)
    contrast(1000%);
          filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(1deg) brightness(105%)
    contrast(1000%);
}

/* img.logo-title.plus-icon:hover {
  filter: none;
} */

.log {
  width: 18px;
  cursor: pointer;
  margin-left: 2px;
  margin-bottom: 2px;
}

w .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.cases-dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  /* position: fixed;
  left: 0 !important;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: 40000 !important;
  /* position: absolute;
  left: 26px;
  top: 136px;
  right: 0;
  bottom: 0; */
  overflow: hidden;
}
#case {
  text-align: center;
}
.side-nav-open-title {
  width: 100%;
  /* height: 50px; */
}
.side-nav-open-title-w {
  width: 100%;
  height: 100%;
}
.side-nav-open-title-w h6 {
  text-align: left;
  margin: 10px 20px;
  color: rgba(148, 157, 176, 1);
  font-weight: 100;
}
.side-nav-open-title-w h6 span {
  font-weight: 500;
}
#side-nav-open a:hover {
  text-decoration: none !important;
}
#dash-add {
  margin-top: 10px;
  margin: auto;
}
#dash-desc {
  margin-top: 140px;
}
.dash-d {
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-transform: capitalize;
  -webkit-user-select: none;
          user-select: none;
  color: rgb(148, 157, 176);
}
.dash-d-split {
  /* left: 340px !important;
  width: 28% !important; */
  text-align: center !important;
}
.dash-d-split-closed {
  text-align: right !important;
}
.dash-t {
  font-size: 200%;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
  -webkit-user-select: none;
          user-select: none;
  color: rgb(148, 157, 176);
  margin-bottom: 40px;
}
.side-nav-open-title h5 {
  /* float: left; */
  /* margin-left: 30px; */
}
.dash-info {
}
.dash-d-side {
  position: absolute;
  margin-top: 50px;
}
.dash-d-side-t {
  position: absolute;
  font-size: 120%;
}

.dash-d-firm {
  position: absolute;
  top: 0;
  right: 20px;
  /* margin: 50px 0 50px 50px; */
  font-size: 200%;
  text-align: right;
  line-height: 1.2;
  -webkit-justify-content: right;
          justify-content: right;
  width: 60%;
}
.dash-d-firm-expanded {
  position: absolute;
  top: 0;
  right: 20px;
  /* margin: 50px 0 50px 50px; */
  font-size: 200%;
  text-align: right;
  line-height: 1.2;
  -webkit-justify-content: right;
          justify-content: right;
  width: 100%;
}
.dash-d-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 50px;
}
.side-nav-open-title img {
  /* float: left;
  margin-left: 10px; */
}
.side-logo {
  margin-top: 20px;
}
.loggedInUser {
  color: #0275d8;
}

#private {
  text-align: center;
}

.action-s {
  width: 50%;
  margin: auto;
  margin-top: 50px;
}

#case-menu .list-group {
  margin-top: 50px;
}
#case-menu-expanded .list-group {
  margin-top: 50px;
}
.logo-comp {
  width: 24px !important;
}

.user-list {
  margin-top: 150px !important;
}

.btn-right {
  float: right !important;
}

.btn-func {
  float: right;
  margin-left: 20px;
}

.com-app-icon {
  /* background: #6c95c3; */
  /* background: #17253d; */
  width: 40px;
  height: 40px;
  /* border-radius: 10px; */
  margin: 10px;
  float: left;
  /* padding: 8px; */
  /* 

   */
  /* border-radius: 6px;s */
  border-width: 1px;
  /* border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144; */
  /* box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15); */
  /* box-shadow: 0 1px 3px 1px #2f3740; */
  /* border-top: 1px solid white; */
  padding: 0;
}
.com-app-icon {
  /* border-top: none; */
  /* border-bottom: 1px solid white; */
}

.app-icon {
  display: none;
}

.lab_u:hover {
  text-decoration: underline;
  color: #0275d8;
}
.lab_p {
  margin: 10px;
}

.lab_p:hover {
  cursor: pointer;
}

.app-icon-group {
  display: none;
}
/* .com-app-icon{ */
/* background: #428bca; */
/* } */
.com-app-icon:hover {
  /* background: #6c95c3; */
  /* background: #0275d8; */
  /* background: #17253d;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin: 12px;
  float: left;
  padding: 8px; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.brands {
  font-weight: 700;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.f10 {
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 400px;
  text-align: left;
}
.f17 h1 {
  text-align: center;
}
.f11 {
  background-color: #ffffff;
}
.f12 {
  text-align: justify;
  padding: 20px;
  list-style-type: none;
}
.f12 h5 {
  text-align: left;
}
#landing-info p {
  font-weight: 300;
  padding: 10px;
  line-height: 1.7;
}

#landing-info li {
  font-weight: 300;
  padding: 10px;
  line-height: 1.7;
}

.pad a {
  font-weight: 700;
}

.App-title {
  font-size: 1.5em;
}

.phone {
  color: #142032;
  text-decoration: none;
  margin-top: 20px !important;
}

.phone:hover {
  color: #6c95c3;
  text-decoration: none;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
::-webkit-input-placeholder {
  font-size: 16px;
}
::placeholder {
  font-size: 16px;
}
/* landing */
.registrationForm .form-control {
  color: black !important;
}
.form-control {
  background-color: transparent;
  height: 50px;
  font-size: 20px;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgb(163, 155, 155);
}
.form-control:focus {
  color: black;
  transition: 0.3s;
  border-bottom: 1px solid rgb(163, 155, 155);
  box-shadow: none;
  background-color: transparent;
}
.form-horizontal {
  color: white;
}

.auth h1 {
  color: white;
  margin-bottom: 50px;
}

.form-horizontal .control-label {
  margin-right: 10px;
}
.invite {
  margin: 10px;
}
#login {
  line-height: 25px;
}

#icon {
  font-size: 300% !important;
}

#landing-buttons {
  margin: 50px;
}

#more-info {
  margin-right: 20px;
  background-color: #a70f20;
  color: white;
  border-color: #a70f20;
}
#white {
  /* background-color: rgb(47, 52, 55); */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
.navbar-inverse {
  background-color: rgb(47, 55, 64);
  border-color: rgb(47, 55, 64);
}

.Landing {
  background-color: rgb(47, 55, 64);
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  padding-top: 200px;
  z-index: -1;
  overflow-y: scroll;
  overflow-x: hidden;
}
#Landing {
  color: black;
  /* background-color: rgb(47, 52, 55); */
  /* box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.2) 0px 5px 10px, rgba(15, 15, 15, 0.4) 0px 15px 40px; */
  padding-top: 20px;
  padding-bottom: 20px;
}
nav.navbar.navbar-inverse {
  margin-bottom: 0 !important;
}

#landing-header h1 {
  font-size: 400% !important;
}

#landing-title {
  color: white;
}

.page-header h1 small {
  /* color: rgb(23, 36, 61); */
  color: rgb(167, 15, 32);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.landing-component-info {
  margin-top: 20px;
}

/* import scroll down*/

* {
  margin: 0;
  padding: 0;
}

#section07 a {
  padding-top: 80px;
}

#section07 a span {
  position: absolute;
  bottom: 35px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}

#section07 a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

#section07 a span:nth-of-type(2) {
  bottom: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

#section07 a span:nth-of-type(3) {
  bottom: 0px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdb07 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.version {
  padding-top: 15px;
}

nav.navbar.navbar-inverse {
  height: 150px !important;
}

#register-msg {
  margin-bottom: 0px !important;
}

.auth {
  background-color: rgb(47, 55, 64);
  padding-top: 200px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  color: white;
}

#About {
  position: fixed;
  background-color: rgb(47, 55, 64);
  top: 120px;
  bottom: 0;
  left: 0;
  right: 0;
}

.about-containers {
  height: 450px;
  background-color: rgb(47, 55, 64);
  border-bottom: 1px solid #a70f20;
}

/* case landing */
.col-md-3 {
  padding: 0px;
  margin: 0;
}

.col-md-9 {
  padding: 0px;
  margin: 0;
}

.col-md-1 {
  padding: 0px;
  margin: 0;
}

.col-xs-11 {
  padding: 0px;
  margin: 0;
}

.new-case-button {
  margin-right: 5px;
  margin-top: 5px;
}

.panel {
  position: fixed;
  bottom: 0;
  top: 51px;
  left: 0;
  right: 75%;
  background-color: #222;
}

#new-case-closed {
  position: fixed;
  bottom: 0;
  top: 80px;
  left: 0;
  /* right: 94%; */
  width: 80px;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
}

#new-case-closed ul {
  margin-bottom: 0;
}

#new-case-closed .list-group {
  margin-bottom: 0;
}

/* #case-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
} */

.new-case-closed-item {
  background-color: rgb(23, 36, 61);
  color: grey;
  border: 1px solid black;
  width: 80px;
}

.new-case-closed-item-bottom {
  position: fixed;
  background-color: rgb(23, 36, 61);
  color: grey;
  border: 1px solid black;
  bottom: 10%;
  left: 0;
  width: 80px;
}

.new-case-closed-item-bottom {
  position: fixed;
  background-color: rgb(23, 36, 61);
  color: grey;
  border: 1px solid black;
  bottom: 10%;
  left: 0;
  width: 80px;
}

.new-case-closed-item-bottom-title {
  position: fixed;
  color: grey !important;
  bottom: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  margin: auto;
  width: 80px;
}

.new-case-closed-item-bottom-two {
  position: fixed;
  background-color: rgb(23, 36, 61);
  color: grey;
  border: 1px solid black;
  bottom: 15%;
  left: 0;
  width: 80px;
}

.ul-list {
  padding-left: 0;
}
.ul-list li {
  list-style-position: inside;
  padding-left: -10px !important;
}

/* .new-case-closed-item-bottom-two {
  position: fixed;
  color: grey !important;
  bottom: 20px;
  left: 0;
  margin: 0;
  padding: 0;
} */
.page-header h1 small {
  color: grey !important;
  margin: 0;
}

.page-header {
  border-bottom: none;
  margin: 0;
  padding: 0 5%;
}

/* loading */

#load {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: black;
  z-index: 999999 !important;
  background: white;
  overflow: hidden;
  width: 100%;
}
#load h1 {
  padding-top: 200px;
  margin: 30px;
  font-weight: 700;
  font-size: 400%;
  z-index: 999999 !important;
  text-align: center;
}
#load .div-cent {
  margin: auto;
  width: 100px;
}
.div-cent img {
  margin-left: 11px !important;
}
#load img {
  width: 58px;

  margin: 0;
  margin-top: 11px;
  z-index: 999999 !important;
}
.checklist-check {
  margin-left: 5px;
  margin-bottom: 3px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid black;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0275d8 black black black;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* side menu */
.side-menu-icon {
  width: 35px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-left: 0;
  padding-right: 0;
}

#setting-menu {
  position: fixed;
  bottom: 0;
  top: 80px;
  left: 8%;
  right: 70%;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
  border: 2px solid black;
}

.setting-menu-item {
  background-color: rgb(47, 55, 64) !important;
  border: 3px solid #222;
  color: white !important;
}

/* .list-group-item.active {
  border: 3px solid black;
  background-color: #a70f20 !important;
}
.list-group-item.active:hover {
  border: 5px solid black;
  background-color: #a70f20 !important;
}
.list-group-item:hover {
  border: 5px solid black;
  background-color: #222 !important;
} */
/* .a.list-group-item,
#new-case-closed button.list-group-item {
  text-align: center;
  color: grey;
} */
#new-case-closed a.list-group-item:focus,
#new-case-closed a.list-group-item:hover,
#new-case-closed button.list-group-item:focus,
#new-case-closed button.list-group-item:hover {
  background-image: rgb(47, 55, 64) !important;
}

#new-case-closed button:active {
  background-color: rgb(23, 36, 61) !important;
  color: grey !important;
  border: 3px solid black !important;
}

#new-case-closed button:hover {
  background-image: rgb(47, 55, 64) !important;
  color: grey !important;
  border: 5px solid black !important;
}

#new-case-closed button:focus {
  background-color: rgb(23, 36, 61) !important;
  color: grey !important;
  border: 5px solid black !important;
}

#new-case-closed button:focus {
  outline: 0;
}

/* button.list-group-item.active {
  border: 3px solid black;
  background-color: #a70f20 !important;
}
.list-group-item.active:hover {
  border: 5px solid black;
  background-color: #a70f20 !important;
}
.list-group-item:hover {
  border: 5px solid black;
  background-color: #222 !important;
} */

/* .a.list-group-item,
#setting-menu button.list-group-item {
  text-align: center;
  /* color: grey; */
/* } */

#setting-menu button:active {
  border: 3px solid black;
  background-color: #a70f20 !important;
}

#setting-menu button:hover {
  border: 5px solid black;
  background-color: #222 !important;
}

#setting-menu button:focus {
  border: 5px solid black;
  background-color: #a70f20 !important;
}

#setting-menu button:focus {
  outline: 0;
}

/* setting page */

.setting-page-closed {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px;
  margin: auto;
}

.setting-page-open {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 20%;
  right: 0;
  padding: 30px;
  margin: auto;
}

.setting-page-btn-save {
  margin-bottom: 20px;
  background-color: #2f3740;
  color: lightgrey;
  border: 1px solid lightgrey;
  float: right;
}

#user-settings {
  text-align: left;
  width: 100%;
  margin: 20px auto auto auto;
  display: block;
  padding-bottom: 200px;
}

#user-settings2 {
  text-align: left;
  width: 100%;
  margin: auto;
  display: block;
  padding-bottom: 100px;
}

.top {
  width: 100%;
  height: 70px;
  /* margin: 20px; */
}

.top2 {
  width: 100%;
  height: 70px;
  /* margin: 20px; */
  padding-right: 10%;
}

.case-bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.save {
  background-color: #142032;
  border: 1px solid black;
  width: 120px;
  margin: 20px;
  float: right;
  position: relative;
  z-index: 100;
}

/* .func {
  width: 50px !important;
} */
.func {
  margin: 20px 3px !important;
  height: 60px !important;
  width: 60px !important;
}
/* .top-g {
  width: 300px;
  margin: auto;
} */
.top-g {
  width: 280px;
  padding: 0;
  margin: auto;
}

.user-form {
  margin-top: 160px;
}
.user-form > button:first-of-type {
  /* position: absolute; */
  /* margin-bottom: 90%; */
}
.top-g-b {
  border-radius: 10px 0 0 10px !important;
}
#Navigation {
  z-index: 1000;
  /* margin-top: 10px; */
  margin-bottom: 5px;
}
.game {
  /* background-color: #142032; */
  padding-top: 100px;
  margin-bottom: 180px;
}
.top-g-f {
  border-radius: 0 10px 10px 0 !important;
}

.func img {
  width: 26px !important;
}

.new {
  background-color: #142032;
  border: 1px solid black;
  width: 200px;
  margin: 20px;
  float: right;
  position: relative;
  z-index: 100;
}

.new:hover {
  background-color: none;
}
.new:focus {
  /* box-shadow: none; */
}

.new-b {
  float: left;
}

.titles {
  text-align: center;
  padding-bottom: 5px;
  padding-top: 20px;
  border-bottom: 1px solid #2f3740;
  padding-left: 0;
  padding-right: 0;
}
.titles h3 {
  font-size: 90%;
}
.check-title {
  font-size: 25px;
  float: left;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 0;
  padding: 0;
  position: absolute;

  left: 0;
}

.saved-input {
  /* padding-top: 5px; */
  border-bottom: 1px solid #2f3740;
  /* padding-bottom: 5px; */
  padding: 2px 2px 2px 5px;
}
#r-save .saved-input {
  border-bottom: unset;
}
.unsaved-input {
  padding-top: 5px;
  padding-bottom: 70px;
}

.unsaved-input textarea {
  resize: none !important;
  border: none !important;
  overflow: auto !important;
  outline: none !important;

  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 0 0 5px !important;
}

.drag {
  content: " ";
  display: inline-block;
  width: 0.9em;
  height: 1em;
  /* margin: -0.5em 0.75em -0.5em -0.5em; */
  /* margin-top: 7px; */
  background-color: transparent;
  background-size: 0.5em 0.5em;
  background-position: 0 0, 0.25em 0.25em;
  cursor: -webkit-grab;
  cursor: grab;
  background-image: linear-gradient(
      45deg,
      #8da2b5 0.1768em,
      transparent 0.1767em
    ),
    linear-gradient(225deg, #8da2b5 0.1768em, transparent 0.1767em);
  position: absolute;
  right: 0;
  bottom: 5px;
}
.drag:active {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}
.logo-title-settings {
  cursor: pointer;
  position: absolute;
  right: 4px;
  bottom: 3px;
  width: 15px;
}

.saved-input textarea {
  resize: none !important;
  border: none !important;
  overflow: auto !important;
  outline: none !important;

  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  background: transparent !important;
  box-shadow: none !important;
  width: 100%;
  padding: 2px 2px 2px 5px;
}

.checklist-status {
  position: absolute;
  right: 0;
  top: 10px;
  margin-top: 20px;
  text-align: center;
  padding: 10px 5px;
  text-transform: capitalize;
  border: rgb(163, 151, 151) 1px solid;
  border-radius: 10px;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 50px;
  float: right;
  cursor: pointer;
  width: 150px;
  margin-bottom: 10px !important;
}

.checklist-status:hover,
.checklist-status:focus,
.checklist-status:active {
  /* font-size: 110%; */

  box-shadow: 2px 2px #c1cad1 !important;
  /* font-weight: bolder; */
}

.to-center {
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
}

.to-center-t {
  text-align: center !important;
  margin: 0 !important;
}

#wit-dash h1 {
  margin-top: 40px;
  overflow-wrap: break-word;
  font-size: 130%;
}

.saved-input textarea:hover {
  border: none;
  width: 100%;
  font-size: 2px 2px 2px 5px;
}

.saved-input .col-md-12 {
  /* padding: 10px; */
}

/* h2 .section-check {
  font-size: 10%;
} */
.section-check {
  margin-top: 100px;
  margin-bottom: 100px;
}
.saved-input textarea {
  font-size: 20px !important;
}
.new-prep {
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 1px solid #2f3740;
}

.new-prep-b {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.remove-s {
  padding-top: 8px;
  color: red;
}

.remove-s-prep {
  /* margin: 20px;
  padding: 20px; */
  float: right !important;
}

.unsaved-input textarea:hover {
  border: none;
}
.ref-line {
  border-right: 1px solid #2f3740;
}
.saved-input .ref {
  /* border-right: 1px solid #2f3740; */
  /* padding: 5px; */
}
input:focus {
  outline-style: none !important;
  border: transparent;
  box-shadow: none;
}
.saved-input .disc {
  /* border-left: 1px solid #2f3740; */
}
.disc2 {
  border-right: 1px solid #2f3740;
}

.unsaved-input .ref {
  border-right: 1px solid lightgrey;
}

.unsaved-input .disc {
  border-left: 1px solid rgba(255, 255, 255, 0.282);
}

.n2 {
  border-left: 1px solid black;
}

.cases {
  /* margin-top: 40px; */
  padding-bottom: 20px;
  text-align: left;
}

.cases .list-group-item {
  border: unset;
}
.list-group-item {
  /* padding: 20px;
  margin: 0 auto;

  max-width: 70%; */
}
/* .underline {
  border-bottom: 2px solid #222 !important;
  border-radius: unset !important;
  width: 100%;
  position: relative;
} */
.underline {
  border-radius: 8px !important;
  border-width: 1px;
  /* border-bottom: unset !important; */
  padding: 35px 0;
  border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07)
    rgba(19, 33, 68, 0.07);
  /* box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15); */
  /* box-shadow: 0 1px 3px 1px #2f3740; */
  margin-bottom: 20px !important;
  padding: 15px 32px;
  box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.215);

  transition: box-shadow 0.3s ease-in-out;
}

.list-underline {
  border-radius: 8px !important;
  border-width: 1px;
  /* border-bottom: unset !important; */
  padding: 35px 0;
  border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07)
    rgba(19, 33, 68, 0.07);
  /* box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15); */
  /* box-shadow: 0 1px 3px 1px #2f3740; */
  margin-bottom: 20px !important;
  padding: 15px 32px;
  box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.215);

  transition: box-shadow 0.3s ease-in-out;
}
.list-underline:hover {
  /* box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.372); */
  /* transition-delay: 2s; */
  color: #000;
}

.underline-n {
  height: auto;
}

/* case */
.case-btn-d {
  margin: auto;
  background-color: white;
  border-color: #17253d;
  color: #17253d;
  font-size: 75%;
  position: relative;
}

.case-btn {
  margin: auto;
  background-color: #17253d;
  border-color: #17253d;
  position: relative;
  display: block;
  width: 70%;
  margin: 0 !important;
  text-decoration: none !important;
}

.cases a:hover {
  text-decoration: none !important;
}

.lab {
  font-size: 130%;
  font-weight: 900;
}

.casePages {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px;
  margin: auto;
}
.textArea {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: transparent;
  height: 50px;
  font-size: 20px;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgb(163, 155, 155);
}
.casePages-open {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 20%;
  right: 0;

  margin: auto;
}

.case-dailyNotes-side-menu {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px;
  margin: auto;
}

#side-menu {
  position: relative;
  width: 50%;
}

.settings-member-name {
  float: left;
  margin: 0 20px;
}

.settings-account-type {
  float: left;
  margin: 0 20px;
  position: relative;
  display: inline;
}

.settings-date-joined {
  float: right;
  margin: 0 20px;
  position: relative;
  display: inline;
}

.settings-edit-member {
  float: right;
  margin: 0 20px;
  position: relative;
  display: inline;
}

.settings-edit-member:hover {
  color: #17253d;
  font-weight: bolder;
  cursor: pointer;
}

.settings-remove-member {
  float: right;
  margin: 0 20px;
  position: relative;
  display: inline;
}

.settings-remove-member:hover {
  color: #a70f20;
  font-weight: bolder;
  cursor: pointer;
}
.s-action {
  display: block;
  margin: auto;
  margin-top: 20px;
  float: none;
}
#wit-dash {
  text-align: left;
  padding-top: 0;
  /* margin-bottom: 200px; */
  width: 94%;
  margin: auto;
}
.wit-dash-w {
  /* padding-top: 10% !important; */
}

.cat-list {
  margin-top: 50px;
}

#member-settings li.list-group-item:hover {
  background-color: white !important;
  border: 1px solid #ddd !important;
  height: 50px;
}

#member-settings li.list-group-item {
  background-color: white !important;
  border: 1px solid #ddd !important;
  height: 50px;
}

#member-settings ul.list-group {
  margin-top: 100px;
}
.notes {
  border-left: 1px solid #2f3740;
}
/* daily notes */

#dailyNotes-menu {
  position: fixed;
  top: 51px;
  left: 30%;
  right: 60%;
  bottom: 0;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
  padding: 0;
}

#dailyNotes-menu-closed {
  position: fixed;
  top: 51px;
  left: 8%;
  right: 83%;
  bottom: 0;
  border: #222 3px solid;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
  padding: 0;
}

.dailyJournal-text {
  position: fixed;
  top: 51px;
  left: 40%;
  right: 0;
  bottom: 0;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
  padding: 0;
  border-left: #222 solid 1px;
}

.dailyJournal-text-closed {
  position: fixed;
  top: 51px;
  left: 17%;
  right: 0;
  bottom: 0;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
  padding: 0;
  border-left: #222 solid 1px;
}

.row {
  margin: 0;
}

#journal-btn {
  position: fixed;
  top: 60px;
  left: 90%;
  right: 0;
}

#journal-text {
  position: absolute;
  top: 60px;
  left: 0;
  right: 1%;
  bottom: 0;
}

/* sentence */
.sentence-text {
  position: fixed;
  top: 51px;
  left: 30%;
  right: 0;
  bottom: 0;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
  padding: 0;
  border-left: #222 solid 1px;
}

.sentence-text-closed {
  position: fixed;
  top: 51px;
  left: 8%;
  right: 0;
  bottom: 0;
  background-color: rgb(47, 55, 64);
  margin-bottom: 0 !important;
  padding: 0;
  border-left: #222 solid 1px;
}

.address-btn {
  position: fixed;
  top: 60px;
  left: 60%;
  right: 0;
}

.address-btn-closed {
  position: fixed;
  top: 60px;
  left: 50%;
  right: 0;
}

.navbar-inverse {
  border-radius: unset;
}

.about-containers-text {
  width: 80%;
  margin: 10%;
  background-color: white;
  color: black;
  box-shadow: 1px 3px;
  padding: 4px;
}

#ending {
}

#intro {
}

.about-containers h2 {
  color: #a70f20;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.about-containers-title {
  color: black;
  font-size: 200%;
  font-weight: 200;
  padding: 5%;
  background-color: white;
}

/* footer */
#footer li {
  list-style: none;
  text-align: left;
  padding-left: 40px;
}

#footer {
  padding: 65px 0 0;
  color: black;
  line-height: 30px;
  font-size: 15px;
  white-space: nowrap;
  background: white;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
}

#footer a {
  color: #142032;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
}

.cases h1 {
  margin-top: 40px !important;
}

#footer a:hover {
  color: #0f7ec0;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  text-decoration: unset;
}

#footer h4 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin: 0 0 5px;
  margin-left: 40px;
  padding: 0;
  text-align: left;
}

#footer strong {
  font-weight: 500;
}

#footer li {
  margin-right: 40px;
}

.copyright {
  margin-right: 0;
  color: white;
  text-align: center;
  padding-top: 35px;
  width: 100%;
}

.rootLink {
  color: #0f7ec0;
  font-weight: 600;
  fill: #0f7ec0;
}

.feature-img {
  width: 800px;
}

#basic-navbar-nav {
  z-index: 10;
}
.btn {
  font-weight: 400 !important;
}
#Navigation {
  color: black;
  white-space: nowrap;
  background: white !important;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  padding-top: 20px;
}

.brands {
  color: black;
  padding-top: 4px;
}

#Navigation a {
  color: black;
  white-space: nowrap;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  text-decoration: unset;
  z-index: 20;
  font-size: 16px;
}

#Navigation a:hover {
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif !important; */
}

#Navigation:hover {
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
}

#contact strong p {
  margin-top: 20px;
  margin-bottom: 5px;
}
#contact {
  padding-bottom: 100px;
}

#contact form {
  text-align: left;
}

#Navigation a:hover {
  white-space: nowrap;
  color: #0f7ec0 !important;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  text-decoration: unset;
}

#Navigation .navbar-brand a {
  font-size: 150%;
}

#Navigation .navbar-brand {
  padding-top: 8px;
  padding-left: 25px;
}

#authnav {
  background-color: #142032 !important;
  color: white;
  white-space: nowrap;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  z-index: 100;
  /* height: 80px; */
}

#authnav button {
  background-color: white !important;
  color: black !important;
  border: 1px solid black;
}

.togglenav {
  /* margin: 0;
  position: fixed;
  right: 10px; */
}

#basic-navbar-nav {
}

#authnav a {
  color: white;
  white-space: nowrap;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  text-decoration: unset;
}

#contact strong p {
  margin-top: 20px;
  margin-bottom: 5px;
}
.tab-text {
  margin: auto;
}
#authnav a:hover {
  white-space: nowrap;
  color: #ffb914 !important;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  -webkit-font-smoothing: antialiased;
  text-decoration: unset;
}
.registrationDashBTN h1 {
  text-align: center;
  margin-bottom: 100px;
  margin-left: 30px;
}
#authnav .navbar-brand a {
  font-size: 150%;
}

#authnav .navbar-brand {
  padding-top: 15px;
}

#authnav button {
  background-color: rgb(23, 36, 61);
  border: 1px solid black;
}
#Landing label {
  width: 100%;
}
#registerUser label {
  width: auto !important;
}

#about {
  /* height: 400px; */
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif !important; */
  /* padding: 40px 5%; */
  font-size: 120%;
  line-height: 2;
  /* font-weight: bold; */
  text-align: left;
}
.expanded {
  position: fixed;
  top: 0;
  bottom: 0;
  top: 0;
  bottom: 0;
  left: 0;
  /* right: 70%; */
  /* background-color: #f9f9f9; */
  background: #f9f9f9;
  background-attachment: fixed;
  border-right: 1px solid #dfe3ed;
  z-index: 1000;
  overflow: auto;
  width: 300px;
}
/* .side-nav-open-title h5 {
  float: left;
  margin-left: 30px;
} */
#com-app-icon-group-expanded {
  top: 0;
  bottom: 5%;
}
.tabs {
  position: fixed;
  top: 0;
  height: 50px;
  left: 30%;
  right: 0;
  background-color: #121921;
  border-bottom: 3px solid rgba(0.12, 0.4, 0.29, 0.9);
}
.tabs-inner {
  position: absolute;
  left: 0;
  width: 80%;
  border-right: 3px solid rgba(0.12, 0.4, 0.29, 0.9);
  height: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.tabs-btn {
  position: absolute;
  right: 0;
  width: 20%;
  height: 100%;
  padding-top: 12px;
}

.registrationDashBTN {
  padding-top: 80px;
  padding-bottom: 100px;
  /* background-image: url('../src/pics/agronomy.svg'); */
}
.registerDashLogin {
  margin-top: 50px;
}
.registerDashLogin a {
  color: #0f7ec0 !important;
}
.Logo {
  text-align: right;
  color: #0f7ec0;
  font-weight: 700;
  padding-right: 20px;
  padding-top: 20px;
}
.Logo h1 {
  font-size: 10px;
}

#ac {
  margin-bottom: 40px;
  /* padding-top: 100px;
  height: 500px; */
}
.w-buttons {
  width: 100%;
  margin: auto;
  /* padding: 15px; */
}
.w-buttons button {
  margin: auto;
  margin: 5px;
}
.w-buttons .add-btn-plus {
  float: unset;
}
.expanded .add-case {
  /* background-color: #f9f9f9; */
  /* margin-left: 10px; */
  background: transparent !important;
  background-attachment: fixed;
  border: none;
  box-shadow: none;
}
.add-btn-plus1 {
  float: right !important;
}
.add-btn-plus3 {
  float: left !important;
}
.w-home {
  float: right;
  width: unset;
  margin: unset;
  padding: unset;
}
.logo-home {
  width: 16px !important;
  margin-right: 10px;
  padding-bottom: 3px;
}
/* .w-buttons img {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
} */
.w-buttons:hover {
  cursor: pointer;
}
.action {
  background-color: #0071e3;
  border-color: rgb(180, 172, 172);
  height: 44px;
  color: white;
  border-radius: 10px 10px !important;
  /* box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.2) 0px 5px 10px, rgba(15, 15, 15, 0.4) 0px 15px 40px; */
  border: 1px solid #0071e3;
  transition: 0.5s;
  font-weight: 700 !important;
}
.action:hover {
  background-color: rgba(33, 30, 49, 1) !important;
  border: 1px solid rgba(33, 30, 49, 1) !important;
  /* border: none; */
  /* border: 1px solid black; */
  box-shadow: 1px 2px 6px 0 rgba(15, 15, 15, 0.1);
  height: 44px;
  outline: none !important;
}

.action:focus {
  outline: none !important;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.2) 0px 5px 10px, rgba(15, 15, 15, 0.4) 0px 15px 40px;
  border: 1px solid rgba(33, 30, 49, 1) !important;
}
.action:active {
  outline: none !important;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.2) 0px 5px 10px, rgba(15, 15, 15, 0.4) 0px 15px 40px;
  border: 1px solid rgba(33, 30, 49, 1) !important;
}
#reset-pass input {
  color: black;
}

.action-b {
  margin: 20px;
  z-index: 20;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus,
.btn-dark.btn:active:focus {
  outline: none !important;
  box-shadow: transparent !important;
}

.action-inverse {
  background-color: white;
  border-color: black;
  /* text-shadow: 0 1px 3px black; */
  height: 60px;
  border-radius: 0;
  color: black;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  font-weight: 700;
  font-size: 120%;
}
.action-inverse-b {
  background-color: black;
  border-color: black;
  /* text-shadow: 0 1px 3px black; */
  height: 60px;
  border-radius: 14px;
  color: white;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  font-weight: 700;
  font-size: 120%;
}
.action-inverse-g {
  background-color: #0f7ec0;
  border-color: #0f7ec0;
  /* text-shadow: 0 1px 3px black; */
  height: 60px;
  border-radius: 14px;
  color: white;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  font-weight: 700;
  font-size: 120%;
}

.action-inverse-b:hover,
.action-inverse-b:focus {
  background-color: black;
  border-bottom: 3px solid #0f7ec0;
  border-right: 3px solid #0f7ec0;
  border-top: 0px solid black;
  border-left: 0px solid black;

  /* text-shadow: 0 1px 3px black; */
  /* box-shadow: 0 0.2em 0.2em -0.1em; */
  color: white;
  height: 60px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: white;
  color: #0f7ec0;
}
a {
  color: black;
}
.features-img {
  width: 700px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: -1px 1px 20px -1px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}
.feature-img-con {
  width: 90%;
  margin-left: 100px;
  padding: 10px;
  /* background-color: #0275d8; */
  opacity: 0.86;
  background-position: 100% 100%, 10% 10%;
  background-size: 100px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05…/5d40b01…_dots.svg),
    url(https://uploads-ssl.webflow.com/5bddf05…/5d69377…_Design-squiggle.svg);
}
.tab-m {
  background-color: white;
  /* color: black; */
  /* background-color: #007bff; */
  border-bottom: 1px solid #e7e7e7;
  border-radius: 0;
  text-align: left;
  width: 100%;
  height: 100%;
  margin: 0;
}
.tab-m-l {
  border-bottom: none;
}
.tab-m p {
  font-weight: 100;
}
.tab-m img {
  width: 30px;
}
#UpdateCard label {
  width: 100%;
  font-size: 120%;
  margin-bottom: 50px;
}
#UpdateCard2 label {
  width: 100%;
  font-size: 120%;
  margin-bottom: 50px;
}
.action-inverse-g:hover,
.action-inverse-g:focus {
  background-color: #0f7ec0;
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  /* border-top: 0px solid #f9f9f9;
  border-left: 0px solid #f9f9f9; */

  /* text-shadow: 0 1px 3px black; */
  /* box-shadow: 0 0.2em 0.2em -0.1em; */
  /* color: black; */
  height: 60px;
}
.action-inverse:hover,
.action-inverse:focus {
  background-color: white;
  border-bottom: 2px solid #6c95c3;
  border-right: 2px solid #6c95c3;
  border-top: 0px solid white;
  border-left: 0px solid white;

  /* text-shadow: 0 1px 3px black; */
  /* box-shadow: 0 0.2em 0.2em -0.1em; */
  color: black;
  height: 60px;
}

.mobile-title {
  color: lightgray;
}

.f9 {
  /* background-color: #f9f9f9;
  background-color: #ebecf0; */
}

.f9 .border-bx {
  border: 1px solid #e2e2e2 !important;
  width: 96%;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
}

.Aleri {
  font-weight: 700;
}

.more-info {
  margin-left: 30px;
  color: #6772e5;
  border-color: #ffffff;
  background-color: #ffffff;
  text-shadow: 0 1px 3px #ffffff;
}

#topic-form {
  /* margin-top: 150px; */
}

.more-info:hover,
.more-info:focus {
  margin-left: 30px;
  color: #6772e5;
  border-color: #ffffff;
  background-color: #ffffff;
  text-shadow: 0 1px 3px #ffffff;
  box-shadow: 0 0.2em 0.2em -0.1em;
  -webkit-transform: translateY(-0.1em);
          transform: translateY(-0.1em);
}
.underline p {
  word-break: break-word;
}
.more-info-inverse {
  color: white;
  border-color: #32325d;
  background-color: #32325d;
  text-shadow: 0 1px 3px #32325d;
  height: 45px;
}
.logo-tb {
  width: 13px;
}
.more-info-inverse:hover,
.more-info-inverse:focus {
  color: white;
  background-color: #32325d;
  text-shadow: 0 1px 3px #32325d;
  box-shadow: 0 0.2em 0.2em -0.1em;
  -webkit-transform: translateY(-0.1em);
          transform: translateY(-0.1em);
  height: 45px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 2rem;
}

.pad {
  padding-top: 20px;
  line-height: 3;
}
.pad2 {
  /* margin: 10px; */
  margin-right: 10px;
  /* margin-top: 0 !important; */
  margin-top: 4px !important;
  padding-top: 0 !important;
}

/* #ffb914 */
/* #3ecf8e */
/* #90b0c8 */
.logo {
  width: 26px;
}
.logo-t {
  width: 10px;
}
.tab p {
  margin-bottom: 0;
}
.tab {
  /* width: 300px; */
  /* background-color: red; */
  display: inline-block;
  height: 100%;
  border: 1px solid rgba(0.12, 0.4, 0.29, 0.9);
  /* border: 1px solid white; */
  color: #e7e7e7;
  padding: 15px;
  font-size: 80%;
  width: 200px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  /*  */
}
.tab p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 140px;
  /*  */
}
.logo-tab {
  width: 10px;
  /* background-color: red; */
  float: right;
}
.logo-tab-side {
  width: 7px;
  margin: 0px;
  float: left;
  margin-right: 5px;
  display: none;
  margin-top: 6px;
}
.parent-side-nav-child-doc:hover .logo-tab-side {
  display: inline-block;
  cursor: pointer;
}
#main-dash .parent-side-nav {
  height: 90px;
}

.tab:hover {
  cursor: pointer;
  border: 1px solid #6c95c3;
}
#call-to-action-mvp {
  padding: 15px;
  background: #0f7ec0;
  opacity: 0.66;
  height: 200px;
  color: white;
  -webkit-align-items: center;
          align-items: center;
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
          justify-content: center;
}

#feature-landing {
  position: relative;
  top: 30px;
  background: white;
  height: 500px;
  color: black;
  -webkit-align-items: left;
          align-items: left;
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: left;
          justify-content: left;

  /* margin-bottom: 120px; */
}
#feature-landing .col-md-6:first-child {
  z-index: 9000 !important;
}

.f-grid {
  display: -webkit-flex;
  display: flex;
  padding-bottom: 40px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 100px;
}

.feature-s {
  text-align: center;
  position: relative;
  width: 50%;
  height: 50%;
  padding-right: 60px;
  padding-bottom: 60px;
  padding-left: 60px;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.feature-s img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  width: 100px;
}
.parent-side-nav p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  max-height: 3.6em;
  line-height: 1.4em;
  margin: 0;
}
#wit-dash .parent-side-nav p,
.dragging .parent-side-nav p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  max-height: 3.6em;
  line-height: 1em;
  margin: 0;
}

.untouch p {
  line-height: 1.3em;
}
.w-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 300px; */
  max-height: 3.6em;
  line-height: 1.4em;
}
.parent-side-nav {
  /* overflow-wrap: break-word;
  overflow: auto;
  text-overflow: ellipsis; */
  /* width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid rgba(81, 107, 152, 0.15);
  color: #e7e7e7; */
  /* line-height: 2; */
  /* display: flex; */
  min-width: 0px;
  cursor: pointer;
  /* padding: 5px 13px; */
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(234, 239, 244);
  border-image: none;
  border-image: initial;
  border-radius: 5px;
  transition: background-color 0.12s linear 0s, box-shadow 0.12s linear 0s,
    border-color 0.12s linear 0s;
  margin: 10px;
  height: 75px;
}
.parent-side-nav:hover {
  box-shadow: 1px 2px rgb(234, 239, 244);
}
.parent-p {
  text-align: left;
  width: 100%;
  font-weight: 400;
  color: black;
}
.parent-p:hover {
  text-decoration: none !important;
}

.parent-p-d {
  font-size: 80%;
  text-align: left;
  width: 100%;
  font-weight: lighter;
  color: rgb(148, 157, 176);
}
.parent-p-d:hover {
  text-decoration: none !important;
}
.btn-outline-secondary {
  color: rgb(148, 157, 176) !important;
  border-color: rgb(148, 157, 176) !important;
}
.btn-outline-secondary:hover,
.add-case-btn:hover {
  color: white !important;
  border-color: rgb(148, 157, 176) !important;
  background-color: rgb(148, 157, 176) !important;
}
.btn-outline-secondary:active {
  color: white !important;
  border-color: rgb(148, 157, 176) !important;
  background-color: rgb(148, 157, 176) !important;
}
.btn-outline-secondary:focus {
  color: white !important;
  border-color: rgb(148, 157, 176) !important;
  background-color: rgb(148, 157, 176) !important;
}

.parent-p:hover {
  cursor: pointer;
}
.parent-side-nav-child {
  width: 100%;
  color: #e7e7e7;
  /* line-height: 2; */
  margin-top: 40px;
  margin-left: 10px;
  /* border-left: 1px solid #e7e7e7; */
  height: 26px;
}
.parent-side-nav-child-cases {
  width: 100%;
  color: #e7e7e7;
  /* line-height: 2; */
  margin-top: 10px;
  margin-left: 26px;
  border-left: 1px solid #e7e7e7;
  text-align: left;
}
.docs {
  overflow: auto;
  white-space: nowrap;
}
.quote {
  font-style: italic !important;
  margin: 35px !important;
}
.info_and_tech li {
  margin: 20px 0 !important;
}
.info_and_tech ol {
  margin: 35px !important;
}
.info_and_tech ul {
  margin: 35px !important;
}
.info_and_tech ol ul {
  margin: 10px 55px !important;
}
.info_and_tech h6 {
  font-weight: bolder;
  margin: 20px 0 -20px 0;
}
.info_and_tech h2 {
  font-weight: 700;
  margin-bottom: -20px;
}
.b-log .col-md-6 {
  float: left;
}
.info_and_tech h1 {
  text-align: left !important;
  padding-top: 0;
}
.info_and_tech p {
  margin: 20px 0;
}
.info_and_tech ol p {
  margin: 0;
}
.info_and_tech ol h6 {
  margin: 0;
}
.info_and_tech ol li {
  padding-left: 15px !important;
}
.info_and_tech {
  /* background-color: khaki; */
  /* margin-top: -45px; */
  margin-top: 40px;
  padding-right: 50px;
  padding-left: -30px;
  text-align: justify;
  padding-bottom: 80px;
}
.parent-side-nav-child-doc {
  width: 100%;
  color: #e7e7e7;
  /* line-height: 2; */
  margin-left: 15px;
  /* border-left: 1px solid #e7e7e7; */
  height: 26px;
  margin-bottom: 10px;
}
#feature-landing-texts {
  padding: 50px;
  padding-top: 0;
}
#feature-landing-imgs {
  padding: 20px;
  padding-top: 20px;
}
#feature-landing-imgs-in {
  padding-top: 20px;
  background-color: #142032;
  /* position: absolute; */
  z-index: -1;
  width: 80%;
  margin: auto;
  height: 450px;
  /* opacity: 0.66; */
  background-position: 10% 100%, 100% 0%;
  background-size: 100px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d40b01c8cacf51977da7f5e_dots.svg),
    url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg);
  border-radius: 4px;
}
/* .feature-background1 {
  padding-top: 20px;
  background-color: #0f7ec0;
  z-index: -1;
  width: 80%;
  margin: auto;
  height: 450px;
  background-position: 10% 100%, 100% 0%;
  background-size: 100px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d40b01c8cacf51977da7f5e_dots.svg);
  border-radius: 4px;
}
.feature-background2 {
  padding-top: 20px;
  background-color: #1b71a3;
  z-index: -1;
  width: 80%;
  margin: auto;
  height: 450px;
  background-position: 10% 100%, 100% 0%;
  background-size: 100px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d40b01c8cacf51977da7f5e_dots.svg);
  border-radius: 4px;
}
.feature-background3 {
  padding-top: 20px;
  background-color: #28739e;
  z-index: -1;
  width: 80%;
  margin: auto;
  height: 450px;
  background-position: 10% 100%, 100% 0%;
  background-size: 100px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d40b01c8cacf51977da7f5e_dots.svg);
  border-radius: 4px;
}
.feature-background4 {
  padding-top: 20px;
  background-color: #355b70;
  z-index: -1;
  width: 80%;
  margin: auto;
  height: 450px;
  background-position: 10% 100%, 100% 0%;
  background-size: 100px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d40b01c8cacf51977da7f5e_dots.svg);
  border-radius: 4px;
} */
.step-progress {
  padding-top: 0px;
}
ol.progtrckr li.progtrckr-doing {
  color: white;
}
ol.progtrckr li.progtrckr-todo:before {
  background-color: rgb(47, 52, 55) !important;
}
#feature-landing-imgs img {
  position: absolute;
  top: 50px;
  right: 100px;
  left: 0;
  width: 98%;
  border: 3px solid #f9f9f9;
  border-radius: 8px;
  box-shadow: 3px 6px 10px 6px rgba(81, 107, 152, 0.15);
}
#feature-landing-imgs video {
  position: absolute;
  top: 50px;
  right: 100px;
  left: 0;
  width: 98%;
  border: 3px solid #f9f9f9;
  border-radius: 8px;
  box-shadow: 3px 6px 10px 6px rgba(81, 107, 152, 0.15);
}
#feature-landing-texts h1 {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Muli, sans-serif;
  /* color: #0b1a38; */
  font-size: 64px;
  line-height: 1.15em;
  font-weight: 400;
  text-align: left;
  letter-spacing: -1.3px;
}
.info_and_temp h5 {
  font-weight: 700;
}
#feature-landing-texts p {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Muli, sans-serif;
  color: #5a6269;
  font-size: 24px;
  line-height: 1.15em;
  font-weight: 300;
  text-align: center;
  letter-spacing: -1.3px;
}
.parent-side-nav-child-s {
  width: 100%;
  color: #e7e7e7;
  /* line-height: 2; */
  margin-left: 10px;
  /* border-left: 1px solid #e7e7e7; */
  height: 26px;
  margin-top: 10px;
}
.parent-side-nav-child-cases-s {
  width: 100%;
  color: #e7e7e7;
  /* line-height: 2; */
  margin-left: 10px;
  /* border-left: 1px solid #e7e7e7; */
  height: 26px;
  margin-top: 10px;
}
.parent-side-nav-child-s:hover {
  cursor: pointer;
}
.parent-p-child {
  font-size: 80%;
  float: left;
  margin: 7px;
  margin-left: 12px;
  margin-top: 10px;
  color: #e7e7e7;
}
.parent-p-child:hover {
  font-size: 80%;
  float: left;
  margin: 7px;
  margin-left: 12px;
  margin-top: 10px;
  color: #e7e7e7;
  text-decoration: none;
}
.parent-p-child-cases {
  font-size: 80%;
  text-align: left;
  margin: 7px;
  margin-left: 10px;
  margin-top: 10px;
  color: #e7e7e7;
  text-decoration: none;
  text-align: left;
}
.parent-p-child-cases:hover {
  cursor: pointer;
  color: #e7e7e7;
  text-decoration: none;
  text-align: left;
}
.parent-p-child:hover {
  cursor: pointer;
}
.parent-p img {
  margin-right: 5px;
}
.feature-s h4 {
  font-weight: 300;
  padding: 10px;
  color: rgb(148, 157, 176);
}

.feature-s h5 {
  font-weight: 300;
  padding: 10px;
  line-height: 1.7;
  /* color: rgb(148, 157, 176); */
}
.feature-d {
  font-weight: 300;
  padding: 10px;
  line-height: 1.7;
}
.feature-ds {
  font-weight: 300;
  padding: 10px;
  color: #6c95c3;
}
.box-t img {
  float: left;
  /* margin-right: 10px; */
  vertical-align: middle;
}
.ds1 {
  /* color: #dd1111; */
  color: #142032;
}
.ds2 {
  color: darkorange;
}
.ds3 {
  color: darkgoldenrod;
}
.values-m {
  /* border-bottom: 1px solid black; */
}
#feature-landing-in {
  position: absolute;
  top: 0;
  padding: 5px;
  background: #121921;
  height: 900px;
  color: black;

  margin-bottom: 100px;
  width: 60%;
}

#feature-landing-img {
  position: absolute;
  left: 800px;
  margin-top: 40px;
  background: #142032;
  height: 680px;
  color: black;

  margin-bottom: 10px;
  width: 50%;
  z-index: 10;
}

#feature-landing-text {
  padding-top: 120px;
  padding-left: 200px;
  z-index: 11;
  text-align: left;
  font-weight: 500;
  color: white;
  font-size: 120%;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.top-topics {
  width: 100%;
  margin-bottom: 150px;
}

#feature-landing-text p {
  font-size: 150%;
  font-style: italic;
  color: #6c95c3;
  text-align: left;
}

.adj {
  color: #e2e2e2 !important;
}

#feature-landing-text h1 {
  padding-top: 20px;
  font-size: 400%;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  /* background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 20px solid #6c95c3; */
  /* border-bottom: 30px solid rgba(0, 0, 0, 0.1); */
  /* background-image: linear-gradient(to right, #142032, rgba(0, 0, 0, 0.3));
  border-radius: 3px; */
}
#case-menu {
  margin-left: 60px;
}
#case-menu-expanded {
  margin-left: 300px;
}
.navbar-brand {
  z-index: 10000;
}

.infoPic {
  width: 800px;
  padding: 100px;
  margin-top: 100px;
}

.dpic {
  width: 100px !important;
  height: 100px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 40px !important;
}

#info h2 {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 0.5em;
}

#landents {
  margin-top: 100px !important;
}

#dentist button {
  margin-bottom: 120px;
}

#info ul {
  margin-top: 100px;
}

#info {
  /* height: 1000px; */
  padding: 100px;

  text-align: center;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif !important; */
  font-size: 120%;
}

#info ul {
  list-style: none;
  font-size: 120%;
  font-weight: 500;
}

#how {
  height: 900px;
  background: #142032;
  color: white;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif !important; */
  font-size: 120%;
  padding-top: 100px;
  padding-left: 20px;
}
.about-banner {
  width: 100%;
}
#about-banner {
  color: black;
  padding-top: 120px;
  padding-bottom: 400px;
  padding-left: 20px;
  padding-right: 20px;
}
.mission-outer {
  /* margin: 20px; */
  height: 140px;
  width: 100%;
  margin-top: 100px !important;
}
#about-banner:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.19;
  background-image: url("https://res.cloudinary.com/aleri/image/upload/v1593642443/Aleri/about-banner_aon1pl.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  background-size: cover;
}
#info ul {
  list-style: none;
  font-size: 120%;
  font-weight: 500;
}

.invalid-feedback {
  display: block !important;
  margin-bottom: 1em;
}

.valid-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: green;
}

.bottom-btn {
  margin-bottom: 200px !important;
}

.border-b {
  border-bottom: 1px solid black;
  width: 100%;
}

.switch {
  width: 20px;
}

#authnav a {
  width: 50px !important;
  margin: auto;
}
.icon-img p {
  width: 126px !important;
}
#howText {
  padding: 120px;
  padding-top: 240px;
  text-align: left;
}

#howText button {
  margin-top: 30px !important;
}

#howText ol {
  margin-top: 30px !important;
}

#wh-is-mvp .col-md-6 {
  padding-left: 30px;
}

#w-is-mvp {
  /* background-color: #f9f9f9; */
  height: 800px;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif !important; */
  padding: 100px;
  font-size: 120%;
  text-align: left;
}

#w-is-mvp ol {
  list-style: none;
  counter-reset: item;
}

#w-is-mvp li {
  counter-increment: item;
  margin-bottom: 40px;
  /* padding-left: 2em; */
}

#w-is-mvp li:before {
  margin-right: 10px;
  content: counter(item);
  background: #142032;
  border-radius: 100%;
  color: white;
  /* width: 1.2em; */
  width: 50px;
  height: 50px;
  text-align: center;
  display: inline-block;
  float: left;
  font-size: 28px;
}

#w-is-mvp li span {
  /* WRAP TEXT IN SPAN */
  display: block;
  overflow: hidden;
}

#wit-nav-expanded {
  /* margin-top: 80px !important; */
}
#w-is-mvp h2 {
  margin-bottom: 20px;
}

#wh-is-mvp h2 {
  margin-bottom: 20px;
  margin-top: 67px;
}

#contact p {
  font-size: 120%;
}

.cases-mobile {
  display: flex;
  display: flex;
  display: -webkit-flex;
}

.navbar-nav {
  /* background-color: #ffffff !important; */
}

#authnav .navbar-nav {
  background-color: #142032 !important;
}

#wh-is-mvp {
  height: 600px;
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif !important; */

  /* padding: 0 100px; */
  font-size: 120%;
  line-height: 2;
  background-color: white !important;
  color: black !important;
  text-align: left;
  /* font-weight: bold; */
}

#pricing h1 {
  margin-top: 40px;
}

#pricing h5 {
  margin-top: 30px;
  font-weight: 300;
  font-size: 90%;
}

#w-is-mvp img {
  width: 500px;
}
/* .w-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
  max-height: 3.6em;
} */
.w-name:hover {
  overflow: visible;
  white-space: normal;
  white-space: initial;
  /* width: 300px; */
  max-height: 100%;
  word-wrap: break-word;
  cursor: crosshair;
}
#pricing {
  font-size: 120%;
  background-color: #ffffff;
  width: 100%;
}

#pricing h1 {
  font-weight: 700;
}

#pricing h6 {
  font-weight: lighter;
  color: #6c95c3;
}

/* 991 */

.box {
  border-radius: 6px;
  border-width: 1px;
  border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);

  width: 282px;
  height: 333px;
  padding: 15px 32px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 25px;
  background-color: white;

  background-image: url("https://res.cloudinary.com/aleri/image/upload/v1593642445/Aleri/paper_m0ns88.svg");
  /* background-position: 170px 88px; */
  background-position: 220px 8px;

  background-size: 50px;
  background-repeat: no-repeat;
}
.info-c {
  display: none;
}
.cases-list {
  margin-top: 150px;
}

.cases-items {
  color: #2f3740;
  cursor: -webkit-grab;
  cursor: grab;
  text-decoration: none !important;
}

.cases-items-fact-tags {
  color: #2f3740;
  cursor: pointer;
  text-decoration: none !important;
}

.cases-items:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.cases-items div {
  /* margin-bottom: 10px; */
}
.untouch {
  height: 44px !important;
  margin-bottom: 10px !important;
  padding: 11px 11px !important;
}

.untouch:hover {
  color: black;
}
.untouch p {
  /* width: unset; */
  /* position: relative;
  display: inline; */
  /* float: left; */
  /* padding-left: 15px !important; */
  padding-right: 0 !important;
  padding-top: 0px !important;
  /* width: 135px !important; */
  text-align: left !important;
}
.untouch-information {
  height: 60px !important;
}
.untouch-information img:last-child {
  visibility: hidden !important;
  height: 20px !important;
  position: absolute !important;
  top: 19px !important;
  right: 15px !important;
}
.untouch-information p {
  line-height: 1.3em;
}
.untouch-information:hover img:last-child {
  visibility: visible !important;
}
.untouch-information p {
  /* padding-top: 2px !important; */
  width: 135px !important;
}
.untouch-info p {
  width: 300px !important;
  padding-top: 5px !important;
  padding-left: 15px !important;
  padding-right: 0 !important;
  text-align: left !important;
}
.untouch-info img:last-child {
  visibility: hidden;
  height: 20px;
  position: absolute;
  top: 22px;
  right: 15px;
}
.untouch-info-w p {
  width: 400px !important;
}
.untouch-info:hover img:last-child {
  visibility: visible;
}
.untouch-info {
  height: 100px !important;
}
.untouch-info:hover {
  color: black;
}
.untouch-info p {
  margin-top: 15px !important;

  text-align: left !important;
  width: 100% !important;
  padding-left: 0 !important;
}
.untouch-info img:last-child {
  top: 5px;
  right: 5px;
}

.cases-items .list-group-item:hover {
  /* background-color: rgba(0, 0, 0, 0.4); */
  /* color: black; */
  /* border: 1px solid #b1b1b9; */
  transition: 0.4s;
  box-shadow: 1px 1px 7px rgb(214, 217, 219);
}

.box-b {
  border-radius: 6px;
  border-width: 1px;
  border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);

  width: 282px;
  height: 379px;
  padding: 15px 32px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 25px;
  background-color: white;
  background-image: url("https://res.cloudinary.com/aleri/image/upload/v1593642445/Aleri/pro_wdxdk9.svg");
  background-position: 180% 0;
  background-size: 170px;
  background-repeat: no-repeat;
}

.payment-modal {
  width: 80vw;
  max-width: 80vw;
  z-index: 11000;
}
.box-b-2 {
  height: 441px;
  border-radius: 6px;
  border-width: 1px;
  border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);

  width: 282px;
  padding: 15px 32px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 25px;
  background-color: white;
  background-image: url("https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/buis_fmkkes.svg");
  background-position: 100% 100%;
  background-size: 80px;
  background-repeat: no-repeat;
}
.dash-d-m {
  display: none;
}
.middle-p {
  text-align: center;
  /* margin-top: 40px; */
  font-weight: 100;
  color: white;
  margin: auto;
  margin-top: 40px;
  background: #0275d8;
  width: 282px;
  border-radius: 10px;
  font-size: 17px;
  padding: 5px;
}
.middle-pd {
  display: none;
}
#box-b {
  background-position: 10% 70%, 100% 7%;
  background-size: 100px, 125px;
  background-repeat: no-repeat, no-repeat;
  background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d40b01c8cacf51977da7f5e_dots.svg),
    url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg);
}
#contact p {
  margin: 10px;
  font-weight: 300;
  padding: 10px;
  line-height: 1.7;
}
.card-close {
  z-index: 1000;
  float: right;
  padding: 3px;
  padding-right: 7px;
}
.card-close img {
  width: 14px;
}
.f9 textarea {
  /* padding-top: 30px !important; */
}
#contact h1 {
  margin-top: 20px;
  text-align: center !important;
  width: 100%;
}

#contact h1 strong {
}
.cases-open {
  margin-top: 100px !important;
}
.tab-logo {
  margin: 10px;
}
#contact form {
  width: 80%;
  margin: auto;
}

#contact button {
  width: 42%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.box-bottom {
  border-radius: 6px;
  border-width: 1px;
  border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);
  width: 80%;
  padding: 15px 32px;
  background-color: white;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
}

.box-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.func-settings {
  width: 140px !important;
}
.about-title {
  margin-bottom: 20px;
  font-size: 38px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding: 0 10%;
  margin-top: 100px;
}
.values {
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */
  padding: 100px 20px;
  padding-top: 0;
  text-align: center;
}
#platforms {
  background-color: #e7e7e7;
}
#platforms .values {
  padding: 100px 20px;
  padding-top: 0;
  text-align: center;
  border-top: none;
  border-bottom: none;
}
.dash-d-d {
  text-align: center;
  color: rgb(148, 157, 176);
}
.dash-desc-d-d button {
  margin: auto;
  /* width: 20% !important; */
}
.d {
  width: 140px !important;
}
.dash-desc-d {
  margin: auto;
  margin-top: 200px !important;
}
.dash-desc-d .btn-toolbar {
  display: flex !important;
  display: flex;
  display: -webkit-flex;
}
.admin {
  cursor: pointer !important;
}
.nt-admin {
  cursor: default !important;
}
.trash img {
  height: 30px;
  width: 25px;
  float: right;
  /* margin-left: 20px; */
  margin: auto;

  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0;
}

img:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.trash {
  background-color: transparent !important;
  border-color: transparent !important;
  height: 30px;
  width: 25px;
  padding: inherit;
}
.trash:active {
  outline: 0;
  box-shadow: none !important;
}

.trash.focus,
.trash:focus {
  outline: none;
  box-shadow: none !important;
}
.add-btn {
  margin-top: 42px;
  float: right;
  /* padding-right: 30px; */
  padding-left: 20px;
}
.add-btn-expanded {
  margin-top: 42px;
  float: right;
  padding-left: 20px;
}

#document-tabs {
  background-color: lawngreen;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /* border-bottom: 1px solid black; */
  z-index: -1;
}

.add-btn:first-child {
  /* padding-right: 30px; */
}
.add-btn-expanded:first-child {
  /* padding-right: 30px; */
}
#platforms .ds1 {
  color: #142032;
}
#platforms .values h5 {
  font-weight: 100 !important;
}
#platforms .ds2 {
  color: #142032;
}
.icons-img {
  padding: 10px;
  margin: 20px;
}
.icon-img h4 {
  float: left;
  padding: 0;
  margin: 0;
}
.icon-img img:first-child {
  float: left;
  width: 20px;
  /* height: 20px; */
  margin: 0;
  padding: 0;
  margin-bottom: 0px;
  margin-right: 15px;
  padding-top: 0px;
}
.untouch img:last-child {
  visibility: hidden;
  height: 15px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.untouch:hover img:last-child {
  visibility: visible;
}

#platforms .ds3 {
  color: #142032;
}
#platforms .mission {
  color: #142032;
}
.values h5 {
  font-weight: 300;
}
#about-banner p {
  font-size: 20px;
}
.about-v {
  margin-right: auto;
  margin-bottom: 25px;
  margin-left: auto;
  width: 100%;

  text-align: center;

  margin-top: 30px;
  font-weight: 300;
  /* font-size: 90%; */
  padding: 0 22%;
  line-height: 1.7em;
}
.call-to-action-heading {
  font-size: 120%;
}
.m {
  text-align: center;
  /* transform: scale(1) rotate(0deg) translate(0px, 0px) skew(170deg, 0deg); */
}
.l {
  padding-left: 100px !important;
}
.r {
  padding-right: 100px !important;
}
.parent-side-nav-card {
  height: 95px;
  padding: 0 !important;
  width: 200px;
}
.cases-items-s {
  height: 250px !important;
  width: 100% !important;
  z-index: 2000;
}
.mission {
  margin-bottom: 10px;
  line-height: 45px;
  font-weight: 600;

  width: 100%;
  text-align: center;

  padding: 0 10%;
  margin-top: 100px;
}

.box-title-price {
  /* margin-bottom: 30px; */
  margin-bottom: 10px;
}

.about-l-img {
  width: 100%;
  border-radius: 10px;
}

.box-title-d {
  /* margin-top: -15px; */
  line-height: 20px;
  color: #0b1a38;
  font-size: 20px;
}

.box-title-d sup {
  top: -0.9em;
}

.career-b {
  width: 100%;
  margin: auto;
  padding: 60px 0;
}

.footer-call-t-action h1 {
  margin-bottom: 20px;
}
.values img {
  width: 100px;
  margin: 40px;
}
.saved-input h4 {
  font-size: 92%;
  margin-top: 0px;
  margin-bottom: 0;
}

.footer-call-t-action {
  text-align: center;
  height: 320px;
  background-color: #0b1a38;
  border-radius: 6px;
  border-width: 1px;
  border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);
  background: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d40b01c8cacf51977da7f5e_dots.svg);
  background-repeat: no-repeat;
  padding: 60px 32px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 25px;
  background-color: white;
  z-index: 100;
  /* background-image: url(../src/pics/circ.svg); */

  /* padding: 0; */
  width: 80%;
}
.footer-call-t-action .feature-s h4 {
  color: black !important;
}
.blog:hover {
  text-decoration: none !important;
  color: black;
  box-shadow: 0 1px 3px 1px #b8bbbd;
  transition: 0.3s;
}
.blog:hover p {
  text-decoration: none !important;
}
.f10 a:hover {
  text-decoration: none !important;
}
.blog {
  /* height: 420px; */
  height: 480px;
  text-align: center;
  /* height: 320px; */
  background-color: #0b1a38;
  border-radius: 6px;
  border-width: 1px;
  /* border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144; */
  box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);
  background: url(https://uploads-ssl.webflow.com/5bddf05…/5d40b01…_dots.svg);
  background-repeat: no-repeat;
  /* padding: 60px 32px; */
  margin: auto;
  margin-top: 100px;
  margin-bottom: 25px;
  background-color: white;
  z-index: 100;
  /* background-image: url(../src/pics/circ.svg); */
  /* padding: 0; */
  width: 80%;
}
.blog-right {
  text-align: right;
  color: black;
  font-size: 12px;
  font-weight: 100;
}
.blog-left {
  text-align: left;
  color: #132144;
  font-weight: 700;
}
.blog-desc {
  text-align: left;
  color: black;
  margin-top: 20px;
}
.modal {
  z-index: 10050 !important;
}
.footer-outside {
  /* background-position: 100% 0;
  background-size: cover;

  perspective: 1000px;
  background-repeat: no-repeat; */
  padding: 20px;
}
.footer-outside:before {
  content: " ";
  display: block;
  /* position: absolute; */
  /* left: 0;
  top: 52%; */
  /* position: relative; */
  width: 100%;
  /* height: 100%; */
  z-index: -1;
  /* opacity: 0.7; */
  /* background-image: url(https://uploads-ssl.webflow.com/5bddf05642686caf6d17eb58/5d693779029cb296af614fe2_Design-squiggle.svg); */
  background-repeat: no-repeat;
  /* background-position: 100% 50%; */
  /* -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover; */
  background-size: cover;
}
.btn-b {
  height: 44px !important;
  margin-bottom: 10px;
}
/* .btn-b {
  height: 44px !important;
  margin-bottom: 10px;
} */
.bob-link:hover {
  text-decoration: none;
}
.button-a {
  /* background-color: #0275d8; */
  background-color: #5b6a87;
  /* width: 100%; */
  color: white;
  font-weight: 200;
  height: 40px;
  display: flex;
  display: flex;
  display: -webkit-flex;
  border-radius: 4px;
  padding: 5px;
  /* margin: 0 1%; */
  margin: 0;
  /* box-shadow: #2d3c43 1px 2px; */
  position: absolute;
  bottom: 11px;
  opacity: 0.5;
  display: none;
  left: 11px;
  right: 11px;
}
.cases-items-s:hover .button-a {
  display: flex;
  display: flex;
  display: -webkit-flex;
}
.text-button {
  text-align: center;
  font-weight: 300;
  display: none;
  height: 40px;
}
.button-a:hover .text-button {
  display: block;
}
/* .button-a:hover img {
  display: none;
} */
.button-a img {
  width: 16px;
  height: 16px;
  margin: auto;
}
.parent-side-nav p {
  width: 200px;
}
/* .button-a p {
  z-index: 1010;
  height: 40px;
  width: 50%;
  margin: auto;
  line-height: 22px;
  text-align: center;
} */
.career-b-b {
  margin: auto !important;
  width: 30%;
  height: 50px;
  font-size: 100%;
  display: block;
}
.career-b a:hover {
  text-decoration: none !important;
}

.box-title-m {
  color: #0b1a38;
  font-size: 40px;
  font-weight: 700;
}

.box-title-t {
  margin-top: 15px;
  color: #0b1a38;
  font-size: 16px;
}
textarea.form-control {
  border: 1px solid black;
  resize: none;
}
.video-landing {
  width: 75%;
  margin: auto;
  /* height: 500px; */
}
textarea.form-control:focus {
  background: inherit;
  color: #495057;
  border-color: #b9bdc2;
  outline: 0;
  box-shadow: 2px 3px 3px 0.5rem rgba(191, 194, 197, 0.25);
}
.case-type {
  position: absolute;
  font-size: 9px;
  right: 5px;
  bottom: 1px;
  text-align: center;
  border: 1px solid #0f7ec0;
  color: #0f7ec0;
  border-radius: 20px;
  padding: 1px;
  width: 50px;
  font-weight: 700;
}
.blog-img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.active-blog {
  border-bottom: 1px solid lightgrey;

  margin-bottom: 15px;

  padding-bottom: 15px;
}
.case-type p {
  width: unset !important;
  color: #0f7ec0 !important;
}
.active-blog ul {
  margin-left: 30px;
}
.box-items-t {
  font-size: 14px;
  margin-top: 20px;
}
#contact {
  /* background-color: white; */
}
.box-time {
  margin-top: 5px;
  color: #5b6a87;
  margin-top: 0 !important;
}

.registrationForm {
  width: 100%;
  margin: auto;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-top: 20% !important;
  overflow: auto;
}
.name-space {
  padding-right: 5px;
}

.registrationForm a {
  color: black !important;
  display: inline-block;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
}
.alert a {
  padding: 0px !important;
  width: 65% !important;
  margin: 0 auto !important;
  text-align: center !important;
  font-size: 100% !important;
  color: red !important;
}
.registrationForm p {
  text-align: center;
  font-size: 16px;
  color: orange;
}

.com-app-icon-group {
  top: 24%;
}
.last-icon {
  bottom: 0;
  position: absolute;
}

.second-last-icon {
  bottom: 60px;
  position: absolute;
}
/* .button-a {
  width: 96%;
} */

/* Documentation 
--------------------------------------- */
.hamburger {
  width: auto;
  /* height: 35px; */
  /* position: absolute; */
  /* right: 15px; */
}

.main-search {
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 15px;
  margin-left: 15px;
}
.document-section {
  /* margin-left: 25px; */
  height: 100%;
}
.doc-div {
  /* margin-left: 25px; */
}

.input_doc {
  border-radius: 8px;
  padding: 3px;
  width: 80vw;
  border: 1px solid #67676740;
}

.doc_list_div {
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  padding-top: 10px;
}
.doc_list {
  list-style-type: none;
  margin-left: 5px;
}
.doc_list_div a:hover {
  text-decoration: none;
}
.document-details a:hover {
  text-decoration: none;
}
.sidebar-icon {
  display: flex;
  display: -webkit-flex;
  /* width: 200px; */
  margin-left: 5px;
  display: -webkit-inline-box;
}
.docs {
  padding-bottom: 10px;
}
#reset-pass .alert-success {
  margin-top: 20px !important;
}
.sidebar-icon h6 {
  font-weight: 800;
}
.sidebar-icon h6,
.sidebar-icon p {
  margin-left: 10px;
  margin-bottom: 0;
  padding: 0 !important;
}
.main-search #mySearch {
  margin-left: 10px;
}
.main-search .sidebar-head {
  text-align: center;
  position: relative;
  top: 5px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  float: right;
}

.sidebar {
  display: flex;
  display: flex;
  display: -webkit-flex;
  top: 1em;
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow-y: auto;
  /* height: 90vh; */
  top: 0;
}
details div {
  margin-left: 5px;
}
details div li {
  list-style-type: none;
  cursor: pointer;
  margin: 10px;
  -webkit-user-select: none;
          user-select: none;
  margin-left: 0px;
}
.p-doc {
  font-size: 16px !important;
}
details div li span {
  margin-right: 5px;
}
.document-details {
  text-align: left;
  width: 250px;
}
.document-details summary {
  padding: 10px;
}
summary {
  outline: none;
  border: none;
  -webkit-user-select: none;
          user-select: none;
}

.document-details p {
  text-align: left;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  -webkit-user-select: none;
          user-select: none;
}

.document-details p {
  text-align: left;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  -webkit-user-select: none;
          user-select: none;
}

ul .logo-title {
  margin-right: 10px;
  height: 20px;
  margin-top: 12px;
}
.doc_list .logo-title,
.doc_list_div .logo-title {
  margin-right: 10px;
  height: 20px;
  margin-top: 0px;
}
.sidebar-icon .logo-title {
  height: 20px;
  margin-top: 12px;
}
.sidebar-icon .logo-title {
  margin-top: 0;
}
.document-details .logo-title {
  margin-top: 12px;
}

details[open] summary ~ * {
  -webkit-animation: fade 0.5s ease;
          animation: fade 0.5s ease;
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
    /* margin-left: 10px; */
  }
  100% {
    opacity: 1;
    /* margin-left: 16px; */
  }
}
@keyframes fade {
  0% {
    opacity: 0;
    /* margin-left: 10px; */
  }
  100% {
    opacity: 1;
    /* margin-left: 16px; */
  }
}

/* End - Documentation Section
--------------------------------------- */

/* new documentation style
--------------------------------------- */

.section-doc {
  display: -webkit-flex;

  display: flex;
  display: -webkit-flex;
  border-top: 1px solid rgba(160, 160, 160, 0.596);
}
.doc-head {
  margin: 40px 10px;
}
.sidebar-sticky {
  width: 200px;
}

.section-doc #Navigation {
  width: 290px;
}

.left-div {
  border-right: 1px solid rgba(160, 160, 160, 0.596);
  margin-right: 25px;
}
.right-div {
  display: flex;
  display: -webkit-flex;
  -webkit-flex: 1;
  flex: 1 1;
  padding: 25px 50px 50px 0;
}
.section-doc .navToggle {
  width: 100%;
}
.section-doc p {
  font-size: 18px;
}
.doc-title {
  margin-bottom: 10px;
  margin: 5px;
}
.doc-line {
  padding-top: 25px;
  border-top: 1px solid rgba(178, 179, 180, 0.377);
}
.text-center.doc-line {
  border-right: 1px solid rgba(178, 179, 180, 0.377);
  /* border-bottom: 1px solid #80808040; */
  top: 0;
  padding-top: 0;
}
.sidebar-div #Navigation {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.searchDoc {
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.searchDoc div {
  margin: 0 auto;
  text-align: left;
  background: white;
  color: slategray;
}

/* END new documentation style
--------------------------------------- */
.saved-input h4 {
  display: inline;
  margin-right: 20px;
}
.hoverHelp {
  width: 18px;
  /* position: absolute; */
  /* right: 0; */
  /* top: 22px; */
  /* right: 10px; */
  cursor: pointer;
  visibility: hidden;
  display: inline;
}
.ref:hover .hoverHelp {
  visibility: visible;
  /* float: right; */
}
.prephelp {
  width: 18px;
  visibility: hidden;
}

.checkHelp {
  width: 18px;
  visibility: visible;
  display: inline;
}

.saved-input.underline-t .row {
  max-height: 18px;
}

.saved-input.underline-t .row img {
  margin-left: 20px;
  margin-top: 0px;
  visibility: hidden;
  height: 100%;
}
.saved-input.underline-t .row:hover img {
  visibility: visible;
  cursor: pointer;
}

.titles .row {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}
.titles .row:hover img {
  visibility: visible;
  cursor: pointer;
}
.titles .row h3 {
  margin: 0;
  margin-right: 20px;
  display: inline;
}
.hov-container {
  margin: auto;
  display: inline;
}
.hov-container-focus {
  /* margin: auto; */
  display: inline;
}

.h-facts:hover .perphover,
.h-stat:hover .perphover,
.h-other:hover .perphover,
.h-trail:hover .perphover {
}
@media (max-width: 1600px) {
  .main-head h1 {
    margin-top: 30px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 1920px) {
  /* .video iframe {
    width: 100%;
    height: 600px;
  } */

  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.9% !important;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.88vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 39.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 42.45vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 39.5vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 41.5vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 42.5vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 41.5vw !important;
    overflow: auto;
  }
}
@media (max-width: 1680px) {
  .video iframe {
    width: 90%;
    height: 600px;
  }
  #wit-dash {
    padding: 0 10px;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.5% !important;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }

  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 0;
    bottom: 15%;
    content: "";
    right: 48.5vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }

  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw;
    overflow: auto;
  }

  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.5vw;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw;
    overflow: auto;
  }

  .c3-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 320px;
    width: 40.5vw;
    overflow: auto;
  }

  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 41.18vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 41.18vw;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 40.5vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 41.18vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 40.5vw !important;
    overflow: auto;
  }

  #feature-landing-img {
    position: absolute;
    left: 700px;
    margin-top: 50px;
    background: #142032;
    height: 620px;
    color: black;

    margin-bottom: 10px;
    width: 50%;
    z-index: 10;
  }

  .feature-img {
    width: 750px;
  }
  /* .button-a {
    width: 93.5%;
  } */
}
@media (min-width: 1617px) {
  #feature-landing {
    position: relative;
    top: 30px;
    background: white;
    height: 700px;
    color: black;
    -webkit-align-items: left;
            align-items: left;
    display: flex;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: left;
            justify-content: left;

    /* margin-bottom: 120px; */
  }
  #why-not-aleri {
    top: 1100px !important;
  }
}
@media (max-width: 1616px) {
  .video iframe {
    width: 80%;
    height: 510px;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.1% !important;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.4vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 39.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 40.8vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 39.5vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 39.5vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 40.8vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 39.5vw !important;
    overflow: auto;
  }

  #feature-landing-img {
    position: absolute;
    left: 570px;
    margin-top: 70px;
    background: #142032;
    height: 570px;
    color: black;

    margin-bottom: 10px;
    width: 50%;
    z-index: 10;
  }

  .feature-img {
    width: 700px;
  }
  /* .button-a {
    width: 92%;
  } */
}
.larger-screen {
  display: block;
}
.mobile-screen {
  display: none;
}
@media (max-width: 1500px) {
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.5%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.1vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 39.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 40vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 39.5vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 39vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 40vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 39vw !important;
    overflow: auto;
  }

  #com-app-icon-group-expanded {
    top: 0;
    bottom: 5%;
  }

  #feature-landing-img {
    position: absolute;
    left: 500px;
    margin-top: 120px;
    background: #142032;
    height: 600px;
    color: black;

    margin-bottom: 10px;
    width: 60%;
    z-index: 10;
  }

  .feature-img {
    width: 620px;
  }

  #feature-landing-text {
    padding-top: 25px;
    padding-left: 100px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }

  #feature-landing-img {
    position: absolute;
    left: 400px;
    margin-top: 20px;
    background: #142032;
    height: 600px;
    color: black;
    margin-bottom: 10px;
    width: 60%;
    z-index: 10;
  }

  /* .button-a {
    width: 92.5%;
  } */
}

@media (max-width: 1440px) {
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.1%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48.1vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 38.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 39.6vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 38.5vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 39vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 39.5vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 39vw !important;
    overflow: auto;
  }
}

@media (max-width: 1366px) {
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.9% !important;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.95vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 37.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 39vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 37.5vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 37.5vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 39vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 37.5vw !important;
    overflow: auto;
  }
}

@media (max-width: 1340px) {
  .video iframe {
    width: 100%;
    height: 600px;
  }
  .feature-img-con {
    margin-left: 35px;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 48%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.8vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 38.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 38.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 38.5vw !important;
    overflow: auto;
  }
  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 38.5vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 38.7vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 38.5vw !important;
    overflow: auto;
  }
  /* .dark-mode-section {
    width: 100vw;
    height: 100vh;
  } */
  /* .button-a {
    width: 91%;
  } */
  .w-name:hover {
    width: 260px;
  }

  #feature-landing {
    height: 500px;
  }
  #feature-landing-img {
    position: absolute;
    left: 400px;
    margin-top: 20px;
    background: #142032;
    height: 600px;
    color: black;
    margin-bottom: 10px;
    width: 60%;
    z-index: 10;
  }

  #feature-landing-text {
    padding-left: 100px;
    z-index: 11;
    text-align: center;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }
  #feature-landing-texts h1 {
    font-size: 380%;
    text-align: center;
  }
  .icon-img p {
    width: 126px !important;
  }
  .topic-btn {
    margin-top: 42px !important;
    float: right;
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 1280px) {
  .video iframe {
    width: 100%;
    height: 500px;
  }

  .topic-btn {
    margin-top: 42px !important;
    float: right;
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.7%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.7vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 37vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 37.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 37vw !important;
    overflow: auto;
  }
  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 37vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 38.2vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 37vw !important;
    overflow: auto;
  }
}
@media (max-width: 1240px) {
  .topic-btn {
    margin-top: 42px !important;
    float: right;
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.7%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.7vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 37.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 37.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 37.5vw !important;
    overflow: auto;
  }
  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 37vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 37.3vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 37vw !important;
    overflow: auto;
  }

  .zoom-img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .dark-head {
    font-size: 30px;
  }
  .dark-sub-head {
    font-size: 18px;
  }
  .dark-img {
    width: 590px;
    height: 590px;
  }
  /* .dark-mode-section {
    width: 100vw;
    height: 100vh;
  } */
  .untouch-info-w p {
    text-align: center !important;
  }
  /* .button-a {
    width: 92%;
  } */
  .w-name:hover {
    width: 200px;
  }
  .w-name {
    width: 200px;
  }
  #wh-is-mvp {
    height: 600px;
  }
  #feature-landing-imgs-in {
    height: 360px;
  }
  .tab-m p {
    font-size: 90%;
  }
  .features-img {
    width: 600px;
  }
  #info {
    height: 750px;
  }
  #dash-desc {
    margin-top: 140px;
  }
  /* #feature-landing-img {
    position: absolute;
    left: 500px;
    margin-top: 100px;
    background: #121921;
    height: 500px;
    color: black;

    margin-bottom: 10px;
    width: 50%;
    z-index: 10;
  } */
  #feature-landing-text h1 {
    font-size: 400%;
  }

  #feature-landing-text {
    padding-left: 100px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }

  #feature-landing-img {
    position: absolute;
    left: 400px;
    margin-top: 20px;
    background: #142032;
    height: 600px;
    color: black;
    margin-bottom: 10px;
    width: 60%;
    z-index: 10;
  }

  /* .feature-img {
    width: 550px;
  } */
  .cases-mobile {
    display: flex;
    display: flex;
    display: -webkit-flex;
  }
  .cases-comp {
    display: none;
  }

  .func {
    margin: 20px 3px !important;
    height: 60px !important;
    width: 60px !important;
  }
  .func-settings {
    width: 140px !important;
  }
  .top-g {
    width: 280px;
    padding: 0;
    margin: auto;
  }
  #info {
    padding: 50px;
  }
}

#purpose h2 {
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: 700;
}

#purpose2 h2 {
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: 700;
}

#purpose3 h2 {
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 150px;
}
.larger-screen {
  display: block;
}
.mobile-screen {
  display: none;
}
@media (max-width: 1200px) {
  /* .info-summary,
  .prepChart {
    width: 130px;
    white-space: normal;
  } */
  .video-landing iframe {
    width: 100%;
    height: 500px !important;
  }
  .video iframe {
    width: 100%;
    height: 400px;
  }
  summary:nth-of-type(7) {
    width: 130px;
    white-space: normal;
  }

  .larger-screen {
    display: block;
  }
  .mobile-screen {
    display: none;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.5%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.5vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 36.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 37.3vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 36.5vw !important;
    overflow: auto;
  }
  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 36%;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 37%;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 36%;
    overflow: auto;
  }

  .dark-img {
    width: 570px;
    height: 570px;
  }

  #wh-is-mvp h2 {
    margin-top: 0;
  }
}

@media (max-width: 1114px) {
  .video-landing iframe {
    width: 100%;
    height: 400px !important;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.2vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .topic-btn {
    margin-top: 42px !important;
    float: right;
    padding-right: 30px !important;
    padding-left: 0 !important;
  }

  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 35.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 36.3vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 35.5vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 35.5vw !important;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 36.18vw !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 35.5vw !important;
    overflow: auto;
  }

  .dark-img {
    width: 540px;
    height: 540px;
  }
  .untouch p {
    padding-left: 0px !important;
    width: 140px !important;
  }

  .feature-img {
    width: 590px;
  }
  #feature-landing {
    height: 500px;
  }
  #feature-landing-img {
    position: absolute;
    left: 320px;
    margin-top: 130px;
    background: #142032;
    height: 500px;
    color: black;

    margin-bottom: 10px;
    width: 62%;
    z-index: 10;
  }

  #feature-landing-text {
    padding-top: 54px;
    padding-left: 70px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }

  #feature-landing-text h1 {
    padding-top: 0;
    font-size: 280%;
  }

  #feature-landing-img {
    position: absolute;
    left: 320px;
    margin-top: 20px;
    background: #142032;
    height: 500px;
    color: black;
    margin-bottom: 10px;
    width: 62%;
    z-index: 10;
  }

  #wh-is-mvp {
    /* font-family: Camphor, Open Sans, Segoe UI, sans-serif !important; */
    padding: 0 100px;
    font-size: 120%;
    line-height: 2;
    /* font-weight: bold; */
  }

  #howText {
    padding: 120px 30px;
  }

  #how {
    height: 800px;

    padding-left: 40px;
  }

  #landmvp {
    width: 350px !important;
  }

  #info {
    height: 750px !important;
    padding: 50px;
    padding-top: 0;
  }
  .text-center-adv {
    margin: auto !important;
    text-align: center !important;
  }
  .errd {
    color: #a70f20;
  }
  #w-is-mvp {
    height: 800px;
    padding: 100px 50px;
  }

  #purpose2 h2 {
    font-size: 2.5em;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 100px;
    margin-top: 100px;
  }
  .saved-input h4 {
    font-size: 80%;
  }
  .larger-screen {
    display: block;
  }
  .mobile-screen {
    display: none;
  }
}
@media (max-width: 1098px) {
  .sidebar-sticky {
    width: 100%;
  }
  .info-summary,
  .prepChart {
    width: auto;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.2vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }

  .topic-btn {
    margin-top: 42px !important;
    float: right;
    padding-right: 30px !important;
    padding-left: 0 !important;
  }

  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 35.5vw !important;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 36vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 35.5vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 34.5vw;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 36vw;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 34.5vw;
    overflow: auto;
  }

  .main-image {
    position: relative;
    right: 24%;
    top: -100px;
    width: 100%;
    z-index: 1;
  }
  .dark-img {
    width: 520px;
    height: 520px;
  }

  .titles h3 {
    font-size: 90%;
  }
  .icon-img p {
    width: 126px !important;
  }
  .larger-screen {
    display: block;
  }
  .mobile-screen {
    display: none;
  }
}

@media (max-width: 1080px) {
  #top-nav-expanded,
  .czhaTb-top-expanded {
    /* width: 100vw; */
    /* left: 0; */
    z-index: 10;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 47.1% !important;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .larger-screen {
    display: block;
  }
  .mobile-screen {
    display: none;
  }
}

@media (max-width: 1030px) {
  .larger-screen {
    display: block;
  }
  .mobile-screen {
    display: none;
  }
  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }
  .dark-img {
    width: 510px;
    height: 510px;
  }
  .main-image {
    position: relative;
    right: 20%;
    top: -82px;
    /* width: 116%; */
    z-index: 1;
  }
  .head-left {
    margin-left: 0;
  }
  .head-right {
    margin-right: 0;
  }

  .home-new-line {
    margin: 5px 20px;
    margin-right: 12px;
  }
  .features-img {
    width: 500px;
  }
  .icon-img p {
    width: 126px !important;
  }
}

@media (max-width: 991px) {
  #footer h4 {
    text-align: center;
    font-size: 150%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 0;
  }

  #footer li {
    text-align: center;
    font-size: 100%;
  }
  #Navigation .navbar-brand {
    margin-top: 10px;
  }
  .dropdown-menu {
    text-align: center;
  }
  .navbar-toggler {
    margin-right: 25px;
    margin-top: 10px;
    padding: 0.05rem 0.35rem;
  }
  .navbar-toggler:active,
  .navbar-toggler:focus {
    /* border: 2px solid #121921 !important; */

    outline: #121921 !important;
  }
  .background-blue a {
    color: white !important;
  }

  #Navigation a:hover,
  #Navigation a:active,
  #Navigation a:focus {
    color: #121921 !important;
  }
  .navbar {
    padding: 0 !important;
  }

  .background-blue {
    background: #0f7ec0;
  }
  .mobile-screen {
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
    height: 70px;
    cursor: pointer;
    /* margin-bottom: 20px; */
  }
  .section-doc {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .right-div {
    margin: 0 auto;
    max-width: 800px;
    padding: 10px;
  }
  .section-doc #Navigation {
    width: 100%;
    padding: 0;
  }
  .left-div {
    margin-right: 0;
    border-right: 0;
  }
  .section-list .navbar-toggler,
  .doc-title {
    display: none;
  }
  .doc-line {
    border: none;
  }
  .section-list {
    border: none;
    border-bottom: 1px solid #80808040;
    padding-left: 25px;
  }
  .main-search .sidebar-head {
    text-align: center;
    position: relative;
    /* top: 10px; */
    -webkit-align-self: flex-start;
            align-self: flex-start;
  }
  .main-search {
    display: none;
  }

  .sidebar-icon {
    padding-left: 10px;
    /* flex: 1; */
    margin-left: 0;
  }
  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }

  .dark-img {
    width: 500px;
    height: 500px;
  }

  .f11 h2 {
    text-align: center;
    font-size: 28px;
  }
  .f11 h4 {
    text-align: left;
  }
  .pad2 {
    margin: 0;
  }
  #feature-landing {
    height: 420px;
    margin-bottom: 120px;
  }
  .action-inverse-g {
    margin: 0;
  }

  #feature-landing-imgs-in {
    height: 320px;
  }
  #feature-landing-texts h1 {
    font-size: 250%;
  }
  #feature-landing-texts p {
    font-size: 130%;
    text-align: center;
  }
  #pricing .col-md-4 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .w-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
    max-height: 3.6em;
  }
  .middle-p {
    display: none;
  }
  .middle-pd {
    /* display: block;
    text-align: center;
    margin-top: 40px;
    font-weight: 700;
    color: #0275d8; */
    display: block;
    font-weight: 100;
    color: white;
    margin: auto;
    margin-top: 40px;
    background: #0275d8;
    width: 282px;
    border-radius: 10px;
    font-size: 17px;
    padding: 5px;
  }

  /* .w-buttons {
    width: 50%;
    margin: auto;
    padding: 0px;
  } */
  .w-home {
    width: 10%;
  }
  .underline {
    padding: 35px 0;
  }
  .wit img {
    width: 140px;
    height: 100px;
  }
  .titles h3 {
    font-size: 90%;
  }
  .larger-screen {
    display: block;
  }
  #Landing {
    box-shadow: none;
  }
  .registrationForm {
    /* margin-top: 20px !important; */
  }
}

@media (max-width: 986px) {
  .registrationForm {
    margin-top: 20% !important;
  }

  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }
  .dark-img {
    width: 490px;
    height: 490px;
  }
  .larger-screen {
    display: none;
  }
  .mobile-screen {
    display: block;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
  }
}

@media (max-width: 984px) {
  .doc_list_div {
    border-top: 0;
    padding-top: 0;
  }
  /* .main-image {
    position: relative;
    right: 20%;
    top: -52px;
    /* width: 116%; */
  /* z-index: 1;
  } */
  .main-image {
    position: relative;
    right: 30%;
    top: -52px;
    width: 100%;
    z-index: 1;
  }
  #landing-info-row {
    margin-top: 20px !important;
  }

  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }

  .feature-img-con {
    margin-left: 20px;
    width: 97% !important;
  }
  #info .col-md-9 {
    border-radius: 90px 0 90px 90px;
    height: 400px;
  }
  .features-img {
    width: 500px !important;
  }

  .saved-input textarea {
    font-size: 80%;
  }
  .side-nav-open-title h5 {
    margin-left: 10px;
  }
  #feature-landing-texts {
    padding: 20px;
    padding-top: 80px;
  }
  .numberOfItems {
    margin-right: 10px;
  }
  .about-title {
    padding: 0;
    font-size: 28px;
  }

  .about-v {
    font-size: 90%;
    padding: 0 10%;
  }

  #feature-landing-text h1 {
    padding-top: 0;
    font-size: 300%;
  }

  #feature-landing-text p {
    font-size: 100%;
    text-align: left;
  }

  #feature-landing-img {
    position: absolute;
    left: 280px;
    margin-top: 135px;
    background: #142032;
    height: 460px;
    color: black;
    margin-bottom: 10px;
    width: 60%;
    z-index: 10;
  }

  #feature-landing-text {
    padding-top: 40px;
    padding-left: 40px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }

  #feature-landing-img {
    position: absolute;
    left: 280px;
    margin-top: 20px;
    background: #142032;
    height: 460px;
    color: black;
    margin-bottom: 10px;
    width: 60%;
    z-index: 10;
  }

  .features-img {
    width: 400px;
  }

  .w-home {
    width: unset;
    padding: 0;
  }
  .w-name:hover {
    width: 160px;
  }

  .side-nav-t {
    margin-right: 10px;
  }

  .titles h3 {
    font-size: 90%;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 45.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 46.7%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 45.5vw !important;
    overflow: auto;
  }
  .main-head h1 {
    font-size: 35px;
  }
  .langing-img {
    width: 75%;
  }

  .landing-video {
    width: 75%;
  }
  .main-head p {
    font-size: 20px;
  }
  .dark-img {
    width: 480px;
    height: 480px;
  }
  .untouch p {
    padding-left: 0px !important;
    width: 100% !important;
  }
  .icon-img p {
    width: 126px !important;
  }
  .untouch img:last-child {
    /* top: 5px; */
    right: 5px;
  }
}

@media (max-width: 960px) {
  .video-landing iframe {
    width: 100%;
    height: 360px !important;
  }
  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 47.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 46.7% !important;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }

  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 46.7vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 47.5vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }
}

@media (max-width: 950px) {
  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }
}

@media (max-width: 900px) {
  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }

  .progtrckr li span {
    display: none;
  }
  .fea-image {
    width: 500px;
  }
}
@media (max-width: 810px) {
  #feature-landing {
    margin-bottom: 0px;
  }
  .main-search {
    display: none;
  }

  .document-section h2 {
    text-align: left;
    font-size: 20px;
    margin: 25px;
    margin-top: 15px;
  }
  .main-search {
    margin-bottom: 0 !important;
  }

  .document-section h4 {
    font-size: 18px;
  }
  .sidebar-icon {
    padding-left: 10px;
    /* flex: 1; */
  }

  .navbar-nav summary,
  .document-details p,
  details div li {
    font-size: 18px;
  }
  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 44.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 45.7%;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 44.5vw !important;
    overflow: auto;
  }
  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 45.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 45.8vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 45.5vw !important;
    overflow: auto;
  }
  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 30.5%;
    overflow: auto;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 30.5%;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 30.5%;
    overflow: auto;
  }

  .dark-img {
    width: 460px;
    height: 460px;
  }
  .main-image {
    /* position: relative;
    right: 24%;
    top: -66px;
    width: 100%;
    z-index: 1; */
    position: relative;
    right: 30%;
    top: -52px;
    width: 100%;
    z-index: 1;
  }
  .feature-img-con {
    margin-left: 20px;
    width: 97% !important;
  }
  #info .col-md-9 {
    border-radius: 90px 0 90px 90px;
  }
  .features-img {
    width: 500px;
  }
  /* .button-a {
    width: 86%;
  } */

  .w-home {
    width: 10%;
  }

  /* .side-nav-t {
    margin-right: 3px;
  } */
  .w-home {
    padding: 0;
  }

  .w-name:hover {
    width: 140px;
  }
  #side-nav-open h5 {
    /* color: rgba(0.12, 0.4, 0.29, 0.9); */
    font-size: 120%;
    font-weight: 500;
  }

  #info {
    padding: 30px;
  }
  #w-is-mvp {
    height: 700px;
  }
  .feature-img-con {
    padding-left: 0px;
    width: 80%;
  }

  #feature-landing-in {
    position: absolute;
    top: 0;
    padding: 5px;
    background: #121921;
    height: 600px;
    color: black;
    margin-bottom: 100px;
    width: 60%;
  }
  .info-c {
    padding-top: 30px;
    margin-top: 30px;
  }

  #feature-landing {
    background: white;
    color: black;
    -webkit-align-items: left;
            align-items: left;
    display: flex;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: left;
            justify-content: left;
    /* margin-bottom: 100px; */
  }

  #feature-landing-img {
    position: absolute;
    left: 300px;
    margin-top: 50px;
    background: #142032;
    height: 320px;
    color: black;
    margin-bottom: 10px;
    width: 50%;
    z-index: 10;
  }

  #feature-landing-text {
    padding-top: 45px;
    padding-left: 100px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }

  .feature-img {
    width: 350px;
    margin-top: 20px;
  }

  #feature-landing-text h1 {
    font-size: 230%;
    padding: 0;
    margin-top: 0;
  }

  #landmvp {
    width: 300px !important;
  }

  #wh-is-mvp .col-md-6 {
    padding-left: 0px;
  }
  .icon-img p {
    width: 126px !important;
  }
}
@media (max-width: 768px) {
  .reg-extender {
    padding-bottom: 50px !important;
  }
  .blog {
    height: unset;
    margin-bottom: 23px !important;
  }
  .hamburger {
    right: 0;
  }
  #top-nav-expanded,
  .czhaTb-top-expanded {
    width: 100vw;
    left: 0;
    z-index: 1;
  }
  .c3-u-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    left: 0;
    overflow: auto;
  }
  .c3-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7vw !important;
    border-radius: 9px;
  }
  .c3-u-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c3-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    width: 50vw !important;
    overflow: auto;
    left: 0;
  }
  .c3-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 49.7% !important;
    border-radius: 9px;
  }
  .c3-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 50vw !important;
    overflow: auto;
  }

  .c4-left {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 60px;
    width: 46.5vw !important;
  }
  .c4-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 45.6% !important;
    border-radius: 9px;
  }
  .c4-right {
    position: fixed;
    top: 40px;
    bottom: 0;
    right: 0;
    width: 46.5vw !important;
    overflow: auto;
  }

  .c4-u-left {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 46.5vw !important;
    overflow: auto;
  }
  .c4-u-left::after {
    position: fixed;
    width: 2px;
    background-color: #dfe3ed;
    top: 15%;
    bottom: 15%;
    content: "";
    right: 45.55vw !important;
    border-radius: 9px;
  }
  .c4-u-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 46.5vw !important;
    overflow: auto;
  }
  .expanded {
    box-shadow: 19px 1px 20px 6px #70707094;
  }
  .czhaTb::after {
    background-color: #5b6975;
  }
  .main-feature {
    display: none !important;
  }
  .main-head h1 {
    font-size: 30px;
  }
  .main-head p {
    font-size: 20px;
  }

  .main-image {
    position: relative;
    right: 17%;
    top: -13px;
    width: 100%;
    z-index: 1;
  }

  .dark-head {
    font-size: 25px;
  }
  .dark-sub-head {
    font-size: 16px;
  }
  .dark-img {
    width: 400px;
    height: 400px;
  }
  /* .dark-mode-section {
    width: 100vw;
    height: 100vh;
  } */
  .thanks-msg {
    margin: 20px auto;
    width: 90%;
    font-size: 16px;
  }
  #feature-landing-imgs-in {
    height: 280px;
  }
  .landing-section2 {
    padding: 0 1em;
  }

  #wh-is-mvp h2 {
    margin-top: 0;
  }
  .info-c {
    padding: 0;
    display: block !important;
    text-align: center !important;
  }
  .tab-m {
    padding: 10px;
    margin: auto !important;
    width: 90% !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    text-align: center !important;
  }
  .tab-m {
    background-color: white;
    /* color: black; */
    /* background-color: #007bff; */
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    text-align: left;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  #info {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .registrationForm {
    margin-top: 12% !important;
    width: 80%;
  }
  #Landing button {
    width: 80%;
  }
  .step-progress {
    padding-top: 0px;
  }
  .action-b {
    margin: 20px 0;
  }
  .video-landing iframe {
    width: 100%;
    height: 300px !important;
  }
  #verification .action {
    height: auto;
  }
  #verification .form-control {
    margin-bottom: 20px;
  }
  /* #Landing label {
    width: 100%;
  } */

  /* .dark-mode-section {
    width: 100vw;
    height: 100vh;
  } */

  .dark-img {
    width: 300px;
    height: 380px;
  }
  .dash-desc-d {
    margin-top: 110px !important;
  }
  .dash-d {
    display: none;
  }
  .dash-d-m {
    display: block;
  }
  .info_and_tech {
    padding-top: 30px;
  }
  .icon-img img:first-child {
    /* display: inline; */
  }
  .icon-img p {
    width: 126px !important;
  }
  #Navigation {
    /* margin-top: 10px; */
  }
  /* .registrationForm h4 {
    font-size: 1rem;
  } */
  /* .registrationForm h1 {
    font-size: 1.2rem !important;
  } */
  .single-blog-section h2 {
    text-align: left;
  }
  .single-blog-section h4 {
    text-align: left;
  }
  .f10 {
    padding-bottom: 0;
  }
  .l-simple-text__inner table {
    display: none;
  }
  .pricing-container {
    margin-top: 25px;
  }
  .pricing-container h2 {
    font-size: 26px;
  }
  .annex {
    display: none;
  }
  #user-settings h2 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  /* 
  <<<<<<< head .untouch p {
    padding-left: 0px !important;
    width: 120px !important;
  }
  /* .untouch p {
    margin-top: 15px !important;

    text-align: center !important;
    width: 100% !important;
    padding-left: 0 !important;
  }
  .untouch img:first-child {
    margin: auto;
    float: none;
    display: block;

  }
  .untouch img:last-child {
    top: 5px;
    right: 5px;
  }
  .untouch-info p {
    margin-top: 15px !important;

    text-align: center !important;
    width: 100% !important;
    padding-left: 0 !important;
  }
  .untouch-info img:first-child {
    margin: auto;
    float: none;
  }
  .untouch-info img:last-child {
    top: 5px;
    right: 5px;
  } */
  /* .untouch img:first-child {
    display: block;
    margin-right: 10px;
  } */

  .MyCardElement {
    /* margin: auto; */
    padding-left: 0;
    padding-right: 0;
  }
  #dashboard-bottom-logo {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 80px;
  }
  .case-btn-side {
    margin-right: 10px;
  }
  .dash-d-logo {
    display: none;
  }

  .logo-settings {
    width: 18px !important;
    /* margin-bottom: 4px; */
  }
  .logo-s {
    /* padding: 14px; */
    right: 0;
    width: 14px !important;
  }
  #wit-dash h1 {
    text-align: left;
  }
  .czhaTb {
    /* display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    right: -18px;
    height: 100%;

    z-index: 30;
     */
    top: 0;
    position: fixed;
    /* bottom: 50px; */
    cursor: n-resize;
    left: 0;
    right: 0;
    height: 52px;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #dfe3ed;
    z-index: 1000;
    overflow: auto;
  }

  .czhaTb::after {
    /* position: absolute;
    top: 50%;
    right: 49%;
    height: 4px;
    width: 20px;
    background-color: #8da2b5;
    transform: translateY(-50%);
    /* opacity: 0.5; */

    content: "";
    border-radius: 3px;
    display: none;
  }
  .dash-d-logo {
    text-align: center !important;
    bottom: 10px;
  }
  .dash-d-firm {
    text-align: center;
    width: 100%;
    margin: -2px;
  }
  .czhaTb-notExpanded {
    /* background-color: white;
    border-bottom: 1px solid #dfe3ed;
    position: fixed;
    top: 0;
    bottom: 0;
    cursor: s-resize;
    left: 0px;
    right: 0;
    height: 48px;
    width: 100%;
    background-color: white; */
    /* border-right: 1px solid #dfe3ed; */
    z-index: 1000;
    overflow: auto;
  }
  .czhaTb-notExpanded::after {
    /* position: absolute;
    top: 50%;
    right: 49%;
    height: 4px;
    width: 20px;
    background-color: #8da2b5;
    transform: translateY(-50%);
    /* opacity: 0.5; */
    display: none;
    /* content: ''; */
    /* border-radius: 3px; */
  }

  /* Icon 2 */
  .mobile-upper-nav {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .title-mobile {
    position: absolute;
    top: 0px;
    left: 45px;
    font-size: 1px !important;
    font-weight: 700;
    color: rgb(141, 162, 181);
    z-index: 7000;
  }

  .ham {
    position: absolute;
    right: 40px;
    top: 11px;
    width: 25px !important;
    height: 10px !important;
  }
  .ham button,
  .ham button:active,
  .ham button:focus {
    width: 45px;
    height: 30px;
    background-color: white !important;
    border-color: #dfe3ed !important;
    outline: none !important;
    padding: 0;
  }

  .ham img {
    width: 40px;
    height: 24px;
  }
  .mobile-auth-nav-toggle .land-logo {
    max-width: 200px;
    max-height: 62px;
    margin-right: 42px;
    display: block;
    width: auto;
  }

  #foc {
    padding-right: 0 !important;
    padding-left: 0 !important;

    padding-bottom: 200px !important;
  }
  .add-btn-p2 {
    padding: 0 30px !important;
  }
  .w-buttons {
    margin-left: 10px;
    padding-left: 15%;
    padding-right: 30%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .add-btn-plus2,
  .add-btn-plus1,
  .add-btn-plus3 {
    padding-left: 3.5%;
    padding-right: 5%;
    float: right !important;
  }
  .saved-input h3 {
    text-align: center;
  }
  .underline-t {
    padding: 0 !important;
    margin: 13px;
  }
  .saved-input h3 {
    padding-top: 10px !important;
  }
  .notes {
    border-left: 0px;
  }
  .side-nav-open-title {
    margin-bottom: 70px;
    margin-top: 50px;
  }
  .add-btn-i3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
    float: left;
  }
  .add-btn-i2 {
    padding-left: 15px !important;
    /* padding-right: 32px !important; */
  }
  .add-btn-i1 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .unsaved-input {
    padding-bottom: 0;
  }
  .unsaved-input textarea {
    font-size: 120%;
  }
  .unsaved-input h2 {
    font-size: 90%;
  }
  .unsaved-input h3 {
    font-size: 80%;
  }
  .saved-input textarea {
    font-size: 120%;
  }
  .saved-input h3 {
    font-size: 80%;
  }

  .add-btn-p-top {
    /* padding: 0 40px !important; */
    padding: 4px !important;
    margin: 4px !important;
  }
  .add-btn-p-top:last-child {
    padding-left: 30%;
    padding-right: 0 !important;
    float: left !important;
  }
  .w-name {
    width: 280px;
  }
  .w-name:hover {
    width: 300px;
  }
  .scope {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .tooltip {
    display: none !important;
  }
  .add-btn-p {
    /* padding: 0 40px !important; */
    /* padding-right: 30%; */
    padding-left: 0;
    float: right;
  }
  .add-btn-p:last-child {
    /* padding-left: 30%; */
    padding-right: 0 !important;
    float: left !important;
  }
  /* .button-a {
    width: 96%;
  } */
  .side-nav-t-t:hover {
    margin-bottom: 0;
  }
  /* .com-app-icon-group {
    top: 29%;
  } */
  #load h1 {
    padding-top: 120px;
  }
  .info-c {
    display: block;
  }
  .tab-m {
    padding: 10px;
    margin: auto;
    width: 90%;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }

  #info {
    display: none;
  }
  #info {
    display: none;
  }
  #info h2 {
    text-align: center;
  }
  #info h5 {
    text-align: center;
  }
  #info p {
    text-align: center;
  }
  #feature-landing-texts {
    padding: 20px;
    /* padding-top: 80px; */
  }
  #feature-landing-texts h1 {
    /* padding: 20px; */
    padding-top: 0px;
    text-align: center;
  }
  #feature-landing-texts p {
    text-align: center;
  }
  .l {
    padding: 0 !important;
  }
  .r {
    padding: 0 !important;
  }
  #dash-desc {
    margin-top: 140px;
  }
  .registrationDashBTN h1 {
    text-align: center;
  }
  .com-app-icon {
    margin-left: 38px;
  }
  #com-app-icon-first {
    margin-left: 5px;
  }
  .cases {
    margin-bottom: 100px;
  }

  #wit-dash h1 {
    text-align: left;
    margin-left: 10px;
    margin-top: 50px !important;
    font-size: 140%;
    overflow-wrap: break-word;
  }

  .mobile-title {
    width: 100%;
  }

  #topic-form {
    margin-top: 50px;
  }

  #case-menu .list-group {
    margin-top: 20px;
  }
  #case-menu-expanded .list-group {
    margin-top: 20px;
  }
  .cases-list {
    margin-top: 100px;
  }

  .cat-list {
    margin-top: 0;
  }

  .underline {
    border-radius: 6px;
    border-width: 1px;
    /* border-bottom: unset !important; */
    border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07)
      rgba(19, 33, 68, 0.07);
    /* box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15); */
    /* box-shadow: 0 1px 3px 1px #2f3740; */
    margin-bottom: 20px !important;
    padding: 15px 32px;
  }

  .switch-btn-c {
    width: 180px !important;
    float: right;
    margin-top: 0 !important;
  }

  #contact h1 {
    font-size: 200%;
  }

  .feature-s {
    width: 100%;
  }

  .footer-call-t-action h1 {
    font-size: 140%;
  }

  .registrationForm form {
    width: 100%;
    margin: auto;
    padding-top: 0px;
  }

  .registrationForm form button {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .career-b {
    padding: 40px 0;
  }

  .career-b-b {
    width: 50%;
  }

  .switch-btn {
    width: 100px !important;
    float: right;
    margin-top: 0 !important;
  }

  .user-form {
    margin-top: 100px !important;
    position: relative;
  }

  #user-settings h1 {
    font-size: 180%;
  }

  .top-g {
    width: 280px;
    padding: 0;
    margin: auto;
  }

  .func {
    margin: 20px 3px !important;
    height: 60px;
    width: 60px !important;
  }

  .func-settings {
    width: 140px !important;
  }

  .func-settings img {
    width: 16px !important;
  }

  /* .func:focus {
    background-color: #6c95c3 !important;
  }

  .func:hover {
    background-color: #6c95c3 !important;
  } */

  .cases-mobile {
    display: flex;
    display: flex;
    display: -webkit-flex;
  }

  .cases-comp {
    display: none;
  }

  .top-g-b {
    border-radius: 0 !important;
  }

  #topic-back {
    width: 100%;
  }

  .saved-input textarea {
    resize: none !important;
    border: 1px solid grey;
    overflow: auto !important;
    outline: 1px solid grey;
    box-shadow: none !important;
  }

  .underline-t {
    border-radius: 6px;
    border-width: 1px;
    border-bottom: unset !important;
    border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
    /* box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15); */
    /* box-shadow: 0 1px 3px 1px #2f3740; */
    margin-bottom: 20px !important;
    padding: 5px 5px !important;
  }

  .underline-t h2 {
    margin-bottom: 20px;
  }

  .topic-b {
    margin-left: 0 !important;
  }

  .saved-input {
    /* padding-top: 5px; */
    border-bottom: none;
    /* padding-bottom: 5px; */
  }

  .case-btn-d {
    margin: auto;
    background-color: white;
    border-color: #17253d;
    color: #17253d;
    font-size: 100%;
    position: relative;
  }

  .top-g-f {
    /* border-radius: 2 !important; */
  }

  .case-btn {
    width: 100%;
    margin: unset;
    margin-top: 30px;
  }

  .case-btn:focus {
    background-color: #6c95c3 !important;
  }

  .case-btn:hover {
    background-color: #6c95c3 !important;
  }

  .case-btn-d {
    width: 100%;
    margin: unset;
    margin-top: 20px;
  }

  .app-icon {
    display: block;
  }

  .app-icon-group {
    display: block;
  }

  .comp-auth-nav {
    display: none;
  }
  .comp-auth-nav-toggle {
    display: none;
  }
  .mobile-auth-nav-toggle {
    display: block;
  }
  .mobile-auth-nav {
    /* background-color: #142032; */
    background-color: white;
    border-top: 1px solid #dfe3ed;
    /* background-color: rgba(0, 0, 0, 0.4); */
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1001;
  }
  #side-nav-open {
    display: none;
  }
  #side-nav-open-mobile {
    /* margin-left: 60px; */
    padding-top: 38px;
    /* overflow: auto; */
    padding-bottom: 200px;
    display: block;
  }

  /* Blog Media query */

  .blog {
    width: 100%;
    margin: 0;
  }
  .blog-left > .h5 {
    text-align: center;
  }

  .blog-right > p {
    text-align: center;
  }
  /* End Blog Query */

  .expanded {
    position: fixed;
    top: 0;
    bottom: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #f9f9f9; */
    background: #f9f9f9;
    background-attachment: fixed;
    border-right: 1px solid #dfe3ed;
    z-index: 1000;
    overflow: auto;
  }
  #case-menu {
    top: 0;
    padding-top: 20px;
    margin: 10px;
    /* bottom: 80px; */
  }
  #case-menu-expanded {
    top: 0;
    padding-top: 20px;
    margin: 10px;
    /* bottom: 80px; */
  }

  .app-icon-group {
    margin: auto;
    width: 320px;
  }

  .app-icon {
    /* background: #6c95c3; */
    background: #17253d;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin: 12px;
    float: left;
  }

  .app-icon:hover {
    background: #6c95c3;
  }

  .app-icon:focus {
    background: #6c95c3;
  }

  .action-b-start {
    width: 80%;
    margin: 10px;
  }

  .navbar {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 2px 1rem;
  }

  .navbar-toggler {
    padding: 0.05rem 0.45rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  button.navbar-toggler:focus {
    outline: none !important;
  }

  /* #feature-landing-text .action-inverse {
    margin-top: 40px;
  } */
  /* #Navigation .navbar-brand {
    padding-top: 0;
  } */

  .navbar-brand {
    height: 40px;
  }

  /* .logo {
    width: 28px;
  } */
  /* .navbar-light .navbar-toggler { */
  /* margin: 5px; */
  /* margin-left: 15px;
    margin-right: 15px;
  } */

  #Navigation .navbar-brand a {
    font-size: 100%;
  }

  /* #Navigation .navbar-brand {
    padding: 5px;
    padding-left: 8px;
    padding-top: 10px;
  } */

  #Navigation {
    width: 100%;
    padding: 0;
    z-index: 1000;
    padding-top: 10px;
  }

  .brands {
    color: black;
    font-size: 100%;
  }

  #feature-landing {
    height: auto;
  }

  .brands {
    color: black;
  }

  .feature-img {
    margin-top: 0px;
  }

  #feature-landing-img {
    position: absolute;

    background: #142032;
    height: 400px;
    color: black;
    left: 0;
    margin-bottom: 10px;
    width: 100%;
    z-index: 10;
  }

  #feature-landing-in {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    background: #142032;
    height: 550px;
    color: black;
    width: 100%;
    z-index: 1;
  }

  #feature-landing-text {
    padding-top: 40px;
    padding-left: 20px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
    width: 100%;
  }

  #feature-landing-text h1 {
    margin-top: 280px;
    margin-top: 50px;
    font-size: 180%;
    margin-bottom: 50px;
    text-align: center;
  }

  .feature-img {
    width: 450px;
  }

  #landImg {
    margin-top: 100px;
    width: 80% !important;
  }

  #land {
    height: 900px;
  }

  #w-is-mvp {
    height: 450px;
    padding: 20px 100px;
    text-align: center;
  }

  #w-is-mvp li {
    padding: 0;
    text-align: left;
  }

  #w-is-mvp img {
    display: none;
  }

  #landmvp {
    display: none;
  }

  #wh-is-mvp {
    height: 350px;
    padding: 40px 100px;
    text-align: center;
  }

  #purpose {
    /* padding-top: 100px; */
    padding-bottom: 100px;
  }

  /* #purpose2 {
    padding-top: 100px;
  }
  #purpose3 {
    padding-bottom: 100px;
  } */
  #info {
    padding: 20px 100px;
    height: 1700px !important;
  }

  #how img {
    display: none;
  }

  #how {
    height: 600px;
    padding: 20px 100px;
    /* padding-left: 20px; */
    width: 100%;
  }

  #how li {
    text-align: left;
    margin-left: 80px;
  }

  #howText {
    padding: 50px 0;
    text-align: center;
    width: 100%;
  }

  .steps li {
    margin: 2em 0;
    padding: 30px;
    display: block;
    position: relative;
    counter-increment: inst;
  }

  #feature-landing-text {
    padding-top: 60px;
    padding-left: 20px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }

  .land-logo {
    max-width: 200px;
    max-height: 62px;
    margin-right: 42px;
    display: block;
    width: auto;
  }
  .brands {
    line-height: 1.9;
  }
  .navbar-light .navbar-toggler {
    margin-top: 15px !important;
  }
  #feature-landing-imgs-in {
    height: 400px;
  }
  #tooltip-folder-s {
    display: none;
  }
  .add-btn-p3 {
    padding-left: 30px !important;
  }
  .larger-screen {
    display: none;
  }
  .mobile-screen {
    display: block;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
}

@media (max-width: 761px) {
  .video img {
    width: 100%;
  }
  .video iframe {
    width: 90%;
    height: 410px;
  }
  .doc-head {
    font-size: 26px;
    text-align: left;
  }
  .section-doc p {
    font-size: 16px;
  }
  .larger-screen {
    display: none;
  }
  .mobile-screen {
    display: block;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
  .main-image {
    position: relative;
    right: 9%;
    top: 0px;
    width: 100%;
    z-index: 1;
  }
  .dark-img {
    width: 360px;
    height: 360px;
  }
  /* .button-a {

    width: 95%;
  } */
  #feature-landing-text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .action-b-start {
    width: 100%;
    /* margin-top: 100px;
    margin-bottom: 100px; */
    margin-left: 0;
    margin-right: 0;
  }
  #w-is-mvp {
    height: 520px;
  }
  #feature-landing-img {
    margin-top: 0;
  }

  .feature-img {
    width: 450px;
    margin-left: 150px;
  }

  #feature-landing-text h1 {
    margin-top: 250px;
    margin-right: 120px;
  }

  #landing-header h1 {
    font-size: 400% !important;
  }

  #more-info {
    margin: 20px 0;
    width: 90%;
    background-color: #a70f20;
    color: white;
    border-color: #a70f20;
  }

  .navbar-brand {
    /* height: 120px; */
  }

  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-color: #a70f20;
    background-color: #a70f20;
    padding-top: 30px;
    position: fixed;
    top: 120px;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 30px;
  }

  .registrationForm {
    padding: 30px;
    margin: auto;
  }

  .auth {
    padding-top: 120px;
  }

  #info {
    height: 1600px !important;
    padding: 0;
  }

  #feature-landing-img {
    position: absolute;

    margin-top: 0;
    background: #142032;
    height: 300px;
    color: black;
    left: 0px;
    margin-bottom: 10px;
    width: 100%;
    z-index: 10;
  }

  #feature-landing-in {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    background: #142032;
    height: 400px;
    color: black;
    width: 100%;
    z-index: 1;
  }

  #feature-landing-text h1 {
    margin-top: 0;
    font-size: 280%;
    text-align: right;
    margin-bottom: 50px;
  }

  #feature-landing-text {
    padding-top: 40px;
    padding-left: 20px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
  }

  #feature-landing-text h1 {
    margin-top: 0;
    font-size: 180%;
  }

  #feature-landing-in {
    height: 500px;
  }
}

@media (max-width: 661px) {
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
  .dark-head {
    font-size: 25px;
  }
  .dark-sub-head {
    font-size: 14px;
  }

  .dark-img {
    width: 350px;
    height: 350px;
  }
  #forgotPass-style {
    width: 85% !important;
    display: flex;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: vertical;
            flex-direction: vertical;

    min-height: 200px;
    margin: auto;
    padding: 20px;

    -webkit-justify-content: center;

            justify-content: center;
  }
  #forgotPass-style input {
    color: white !important;
  }
  .info_and_tech {
    margin: 0 auto !important;
    line-height: 1.3;
    padding-right: 0;
  }
  .info_and_tech h2 {
    font-size: 25px;
  }
  .info_and_tech h1 {
    font-size: 19px !important;
  }

  .info_and_tech h6 {
    font-size: 16px !important;
    padding-bottom: 10px;
  }
  .case-btn-side-t {
    margin-left: 15px;
    font-size: 18px;
    /* margin-bottom: 10px; */
  }
  .feature-img {
    width: 400px;
    margin-left: 140px;
  }

  .feature-img {
    width: 380px;
  }

  #feature-landing-text p {
    font-size: 80%;
  }

  #feature-landing-img {
    position: absolute;

    margin-top: 0;
    background: #142032;
    height: 300px;
    color: black;
    left: 0px;
    margin-bottom: 10px;
    width: 100%;
    z-index: 10;
  }

  #feature-landing-in {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    background: #142032;
    height: 450px;
    color: black;
    width: 100%;
    z-index: 1;
  }

  #purpose2 h2 {
    font-size: 2.5em;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 200px;
    margin-top: 200px;
  }

  #how {
    height: 700px;
  }

  #feature-landing-text h1 {
    margin-top: 60px;
    font-size: 180%;
    padding-top: 0;
    margin-top: 0;
    text-align: right;
    margin-right: 60px;
  }

  #landing-info p {
    padding-bottom: 0;
  }
  .mission-outer {
    margin-top: 0;
    margin-bottom: 100px;
  }
  .mission {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 609px) {
  .video-landing iframe {
    width: 100%;
    height: 250px !important;
  }
  /* .dark-mode-section {
    width: 100vw;
    height: 100vh;
  } */
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
  .dark-img {
    width: 330px;
    height: 330px;
  }
  #purpose2 h2 {
    margin-bottom: 100px;
    margin-top: 100px;
  }

  #feature-landing-text h1 {
    margin-top: 40px;
  }

  #w-is-mvp {
    height: 400px;
    padding: 0;
  }

  #wh-is-mvp {
    height: 300px;
    padding: 0;
  }

  #how {
    height: 700px;
    padding: 20px 100px;
  }

  .new {
    width: 46%;
    margin: unset;
    margin-top: 40px;
    margin-left: 10px;
  }

  #call-to-action-mvp {
    height: 400px;
  }

  #feature-landing-text {
    padding-top: 20px;
  }

  .modal-box-a {
    border-radius: 6px;
    border-width: 1px;
    border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
    box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);
    width: 216px;
    height: 308px;
    padding: 15px 32px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 25px;
    background-color: white;
    background-image: url(/static/media/paper.0f3b426d.svg);
    background-position: 170px 88px;
    background-size: 50px;
    background-repeat: no-repeat;
  }

  .modal-box-b {
    border-radius: 6px;
    border-width: 1px;
    border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
    box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);
    width: 216px;
    height: 379px;
    padding: 15px 32px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 25px;
    background-color: white;
    background-image: url(/static/media/pro.631db7eb.svg);
    background-position: 180% 0;
    background-size: 134px;
    background-repeat: no-repeat;
  }
  .modal-box-c {
    height: 441px;
    border-radius: 6px;
    border-width: 1px;
    border-color: rgba(19, 33, 68, 0.07) rgba(19, 33, 68, 0.07) #132144;
    box-shadow: 0 1px 3px 1px rgba(81, 107, 152, 0.15);
    width: 216px;
    padding: 15px 32px;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 25px;
    background-color: white;
    background-image: url(/static/media/buis.8af64eec.svg);
    background-position: 100% 100%;
    background-size: 80px;
    background-repeat: no-repeat;
  }
}

@media (max-width: 530px) {
  .registrationForm {
    padding: 0;
  }
  .video-landing iframe {
    width: 100%;
    height: 200px !important;
  }
  .video iframe {
    width: 100%;
    height: 250px;
  }
  .video-landing {
    width: 90%;
  }
  .doc-head {
    font-size: 20px;
    text-align: left;
  }
  .section-doc h4 {
    font-size: 18px;
  }
  .section-doc p {
    font-size: 16px;
  }
  .hamburger {
    width: auto;
    position: relative;
    right: 0;
  }
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
    margin-bottom: 20px;
  }
  .main-head h1 {
    font-size: 25px;
  }

  .main-head p {
    font-size: 16px;
    width: 100%;
  }
  .dark-img {
    width: 300px;
    height: 300px;
  }
  .w-buttons {
    padding-left: 15%;
    padding-right: 25%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .info_and_tech li {
    margin: 20px 0 !important;
  }
  .info_and_tech ol {
    margin: 10px !important;
  }
  .info_and_tech ul {
    margin: 10px !important;
  }
  .info_and_tech ol ul {
    margin: 10px 20px !important;
  }
  #w-is-mvp {
    padding: 20px;
  }

  #contact button {
    width: 90%;
  }

  #contact p {
    padding: 0;
    margin: 20px;
  }

  #feature-landing-text {
    padding-top: 16px;
    padding-left: 20px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
    width: 100%;
    padding-right: 20px;
  }

  #feature-landing-img {
    position: absolute;

    background: #142032;
    height: 200px;
    color: black;
    left: 0px;
    margin-bottom: 10px;
    width: 100%;
    z-index: 10;
  }

  #feature-landing {
    /* margin-bottom: 100px; */
  }

  #feature-landing-text h1 {
    /* margin-top: 120px; */
    font-size: 180%;
    padding-top: 0;
    margin-top: 0;
    text-align: right;
  }

  .feature-img {
    width: 320px;
    margin-top: 33px;
  }

  #info {
    height: 1600px !important;
    padding: 0;
    text-align: center;
  }

  #wh-is-mvp {
    height: 350px;
  }

  .headerl {
    margin-top: 100px;
  }

  .action-b-start {
    width: 70%;
    /* margin: 60px;
    margin-top: 50px; */
  }

  #w-is-mvp {
    height: 550px;
    margin-top: 0;
  }

  #purpose2 h2 {
    font-size: 2.5em;
    line-height: 1.2;
    font-weight: 700;
    margin-top: 100px;
  }

  #call-to-action-mvp {
    height: 400px;
  }

  #feature-landing-text h1 {
    margin-top: 10px;
    font-size: 180%;
    padding-top: 20px;
    margin-top: 0;
    text-align: right;
    padding-right: 0px;
    margin-right: 10px;
  }
  #feature-landing-imgs-in {
    height: 320px;
  }
}

@media (max-width: 480px) {
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
  .dark-img {
    width: 280px;
    height: 280px;
  }

  /* .button-a {
    width: 94.5%;
  } */

  .feature-s {
    width: 100%;
    padding: 60px 0 0 0;
  }

  .career-b-b {
    width: 100%;
  }

  .feature-img {
    width: 360px;
    margin-top: 90px;
    margin-left: 0;
  }

  #call-to-action-mvp {
    height: 400px;
  }

  #feature-landing-text p {
    font-size: 100%;
    padding-left: 20px;
  }

  #feature-landing-text {
    padding-top: 5px;
    padding-left: 0;
    padding-right: 0;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 120%;
    width: 100%;
  }

  #purpose2 h2 {
    margin-top: 100px;
  }

  #purpose3 h2 {
    margin-bottom: 200px;
  }

  #feature-landing-text h1 {
    margin-top: 78px;
    font-size: 200%;
    margin-bottom: 30px;
    text-align: center;
    background-image: unset;
    text-align: center;
    padding-right: 0;
    /* background-color: rgba(0, 0, 0, 0.3); */
    width: 100%;
  }

  #feature-landing-in {
    height: 450px;
  }

  .action-b-start {
    /* width: 70%;
    margin: 60px; */
  }

  #info {
    height: 1700px !important;
    padding: 0;
    text-align: center;
  }

  #how {
    height: 800px !important;
    padding: 20px;
    text-align: center;
  }

  #landForm {
    margin: 0;
  }

  #landImg {
    margin: 0;
  }

  #land {
    height: 600px;
    text-align: center;
  }

  #w-is-mvp h2,
  #wh-is-mvp h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .registrationForm {
    width: 90%;
    margin: auto;
  }

  #feature-landing {
    height: 500px;
  }

  #w-is-mvp {
    height: 750px;
  }
  .call-to-action-heading {
    font-size: 80%;
  }
  #feature-landing-imgs-in {
    height: 275px;
  }
}

@media (max-width: 414px) {
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
  .dark-img {
    width: 210px;
    height: 210px;
  }
}
@media (max-width: 400px) {
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: -webkit-flex !important;
    display: flex !important;
    width: 100%;
  }
  .dark-img {
    width: 200px;
    height: 200px;
  }
  .czhaTbP:focus {
    background-color: #0f7ec0;
  }
  .w-buttons {
    padding-left: 0;
    padding-right: 20%;

    padding-top: 0;
    padding-bottom: 50px;
  }
  #feature-landing-imgs-in {
    height: 260px;
  }
  #feature-landing-text h1 {
    margin-top: 78px;
    font-size: 200%;
    margin-bottom: 30px;
    text-align: center;
    background-image: unset;
    text-align: center;
    padding-right: 0;
    /* background-color: rgba(0, 0, 0, 0.3); */
    width: 100%;
  }

  #pricing h1 {
    font-size: 30px;
  }

  .Landing {
    padding-top: 100px;
  }

  nav.navbar.navbar-inverse {
    height: 100px !important;
  }

  #icon {
    font-size: 200% !important;
  }

  .navbar-brand {
    height: 80px;
  }

  .about-containers-title {
    font-size: unset;
  }

  #info {
    height: 2100px !important;
    padding: 0;
  }

  .navbar {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 2px 1rem;
  }

  .navbar-toggler {
    padding: 0.05rem 0.45rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  /* #feature-landing-text .action-inverse {
    margin-top: 40px;
  } */
  /* #Navigation .navbar-brand {
    margin-left: 10px;
  } */

  .navbar-brand {
    height: 40px;
  }

  /* .logo {
    width: 28px;
  } */
  .navbar-light .navbar-toggler {
    margin: 5px;
  }

  #Navigation .navbar-brand a {
    font-size: 100%;
  }

  /* #Navigation .navbar-brand {
    margin-left: 10px;
  } */

  #Navigation {
    width: 100%;
    padding: 0;
    z-index: 1000;
  }

  .brands {
    color: black;
    font-size: 100%;
  }
}

@media (max-width: 387px) {
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
  /* .button-a {
    width: 93%;
  } */
  .landing-btn1,
  .landing-btn2 {
    width: 142px !important;
  }

  #about-banner {
    padding-bottom: 320px;
    padding-top: 80px;
  }
  .feature-img {
    width: 320px;
    margin-left: 0;
    margin-top: 85px;
  }

  #feature-landing-text h1 {
    margin-top: 74px;
    font-size: 220%;
    margin-bottom: 50px;
  }

  #feature-landing-text {
    padding-top: 20px;
    z-index: 11;
    text-align: left;
    font-weight: 500;
    color: white;
    font-size: 90%;
  }

  #feature-landing-text p {
    font-size: 100%;
  }

  #info {
    height: 2100px !important;
    padding: 0;
  }

  #w-is-mvp {
    height: 680px;
  }

  #wh-is-mvp {
    height: 300px;
  }

  .action-b-start {
    /* width: 70%;
    margin: 50px; */
  }

  #call-to-action-mvp {
    height: 400px;
  }

  .navbar {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 2px 1rem;
  }

  .navbar-toggler {
    padding: 0.05rem 0.45rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  /* #feature-landing-text .action-inverse {
    margin-top: 40px;
  } */
  #Navigation .navbar-brand {
  }

  .navbar-brand {
    height: 40px;
  }

  /* .logo {
    width: 28px;
  } */
  .navbar-light .navbar-toggler {
    margin: 5px;
    margin-right: 15px;
  }

  #Navigation .navbar-brand a {
    font-size: 100%;
  }

  #Navigation .navbar-brand {
    padding: 5px;
    padding-top: 6px;
  }

  #Navigation {
    width: 100%;
    padding: 0;
    z-index: 1000;
  }

  .brands {
    color: black;
    font-size: 100%;
  }

  #feature-landing {
    height: auto;
  }

  .brands {
    color: black;
  }
}

@media (max-width: 360px) {
  .dash-t {
    font-size: 130% !important;
  }
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: flex !important;
    display: flex;
    display: -webkit-flex;
    width: 100%;
  }
}

@media (max-width: 340px) {
  /* .button-a {
    width: 90%;
  } */
  .larger-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block !important;
    display: -webkit-flex !important;
    display: flex !important;
    width: 100%;
  }
  #Landing button {
    width: auto;
  }
  .pricing-container h2 {
    font-size: 28px;
  }
  #about-banner {
    padding-bottom: 200px;
    padding-top: 0;
  }
  #w-is-mvp {
    height: 680px;
  }

  #feature-landing-in {
    height: 350px;
  }

  #feature-landing {
    height: 700px;
  }
  #wh-is-mvp {
    height: 450px;
  }
  #feature-landing-text {
    padding: 0;
  }
  .headerl {
    margin-top: 0;
  }
  #feature-landing-text h1 {
    margin-top: 65px;
    font-size: 220%;
    margin-bottom: 50px;
  }

  .feature-img {
    width: 300px;
    margin-left: 0;
    margin-top: 60px;
  }

  #info {
    height: 2250px !important;
    padding: 0;
  }

  #how {
    height: 900px !important;
    padding: 20px;
    text-align: center;
  }

  .action-b-start {
    /* margin: 45px;
    width: 70%; */
  }

  #call-to-action-mvp {
    padding: 15px;
    background: #0f7ec0;
    height: 400px;
    color: white;
    -webkit-align-items: center;
            align-items: center;
    display: flex;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media (min-width: 33em) {
  .steps li:before {
    border-radius: 50%;
    font-size: 1.5em;
    height: 1.35em;
    margin-left: 2.5%;
    padding-left: 0;
    padding-top: 0;
    top: -0.15em;
    width: 1.35em;
    z-index: 2;
  }
}

/* terms */
.l-container {
  margin: 0 auto;
  padding-left: calc(1.25 * 1rem);
  padding-right: calc(1.25 * 1rem);
  max-width: 71.875rem;
}
.back-big {
  width: 100% !important;
}

.blog .card-body {
  padding: 0;
  margin: 0;
}
.blog h4 {
  float: centre;
  padding: 15px;
  font-weight: 600;
  text-align: centre;
}
.blog p {
  float: left;
  padding: 15px;
  padding-top: 0;
  padding-bottom: 20px;
  margin-bottom: 50px;
  text-align: left;
}
.b-title {
  padding: 30px;
  text-align: left;
}
.blog:hover {
  color: #0275d8;
}
.article-go {
  width: 22px;
}
.blog:hover .article-go {
  fill: #0275d8;
}
/* Blog styling */
#blog-data > h3,
#blog-data > h2,
#blog-data > h1,
#blog-data > p {
  margin-top: 20px;
  margin-bottom: 10px;
}

.case-drag-single-list {
  /* margin-top: 20px; */
}

/* Verfication Page  --Bhushan*/
.btn-verify {
  margin: 10px !important;
  width: auto !important;
}

.case-drag-single-list h4 {
  border-radius: 3px;
  margin: 8px;
  padding: 10px;
  font-weight: 600;
  background-color: #d9dde2;
  border-bottom: 1px solid #d9dde2;
  border-top: 1px solid #d9dde2;
}

.fact-tags-btn {
  margin-top: 2%;
  margin-left: 0 !important;
}
.fact-tags-btn-2 {
  margin-top: 2%;
}

.timeline-btn {
  margin-top: 2%;
  margin-left: 2% !important;
}

.fact-tags-header {
  margin-top: 2%;
}

.create-tag-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.create-tag-textarea {
  width: 50%;
  margin: auto;
  /* height: 2%; */
  resize: none;
}

.create-new-fact-tag {
  padding-left: 2%;
  padding-right: 2%;
}

@media screen and (max-width: 500px) {
  .dash-d-logo {
    margin: 0;
  }
  .dash-t {
    font-size: 150%;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
    -webkit-user-select: none;
            user-select: none;
    color: rgb(148, 157, 176);
    margin-bottom: 4px;
    text-align: center;
  }
  .dash-t,
  .loggedInUser {
    grid-column: 1;
    grid-row: 1;
  }
  .dash-d {
    padding-top: 10px;
    font-size: 80%;
    font-weight: 600;
  }
  /* .dash-d-logo { */
  /* right: -10px; */
  /* margin: 60px;
    text-align: center !important; */
  /* } */
}

#topic-delete .logo-title {
  /* width: 30px; */
  /* display: flex; */
  height: 13px;
}

#topic-delete {
  background-color: transparent;
  border-color: rgba(180, 172, 172, 0.537);
  height: 35px;
  color: rgb(167, 163, 163);
  border-radius: 10px 3px 10px !important;
  box-shadow: 1.5px 2px 2px;
  border: 1px solid #fff8f800;

  /* display: flex; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;

  color: rgb(141, 162, 181);
  border-radius: 90px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: none;
  border-image: initial;
}

#topic-delete:focus {
  background-color: transparent;
  border-color: rgba(180, 172, 172, 0.537);
  height: 35px;
  color: rgb(167, 163, 163);
  border-radius: 10px 3px 10px !important;
  box-shadow: 1.5px 2px 2px !important;
  border: 1px solid #fff8f800;

  /* display: flex; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;

  color: rgb(141, 162, 181);
  border-radius: 90px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: none;
  border-image: initial;
}
#topic-delete:hover {
  background-color: transparent;
  border-color: rgba(180, 172, 172, 0.537);
  height: 35px;
  color: rgb(167, 163, 163);
  border-radius: 10px 3px 10px !important;
  box-shadow: 1.5px 2px 2px;
  border: 1px solid #fff8f800;

  /* display: flex; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;

  color: rgb(141, 162, 181);
  border-radius: 90px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: none;
  border-image: initial;
}

#topic-delete:active {
  background-color: transparent;
  border-color: rgba(180, 172, 172, 0.537);
  height: 35px;
  color: rgb(167, 163, 163);
  border-radius: 10px 3px 10px !important;
  box-shadow: 1.5px 2px 2px;
  border: 1px solid #fff8f800;

  /* display: flex; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;

  color: rgb(141, 162, 181);
  border-radius: 90px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: none;
  border-image: initial;
}

.search-list-dark {
  border-radius: 5px;
  width: 100%;
  height: 70px;
  color: #6c757c;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  white-space: nowrap;
}

.search-list-dark:hover {
  border-radius: 5px;
  width: 100%;
  height: 70px;
  background: #0275d8;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
}

.search-list-tab-elements:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.search-list-tab-elements {
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  line-height: 2;
}

.search-list-tab {
  width: 100%;
  height: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  padding-left: 10px;
}

.search-input-dark {
  color: white;
  padding: 5px;
  padding-left: 10px;
  border: 1px lightgrey solid;
  background: none;
  width: 100%;
  border-radius: 8px;
}
.search-input-light {
  padding: 5px;
  padding-left: 10px;
  border: 1px lightgrey solid;
  background: none;
  width: 100%;
  border-radius: 8px;
}

.expand-icon:hover {
  cursor: pointer;
}

div[class|="c3-left"],
div[class|="c3-right"],
div[class|="c3-u-left"],
div[class|="c3-u-right"] {
  z-index: -1;
  opacity: 0.09;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.hui {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
div[class|="expanded"] + div[class|=".czhaTb"]::before {
  display: flex;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-self: center;
          align-self: center;
  position: inherit;
  top: 0;
  width: 100vw;
  height: 100vh;
}
div[class|="c3-right"] + div[class|="hui"]::after {
  /* background-color: rgba(255, 255, 255, 0.653) !important; */
  /* color: black !important; */
  content: "Close Sidebar To Continue";
  font-size: 24px;
  bottom: 49%;

  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}
div[class|="c3-u-right"] + div[class|="hui"]::after {
  /* background-color: rgba(255, 255, 255, 0.653) !important; */
  /* color: black !important; */
  content: "Close Sidebar To Continue";
  font-size: 24px;
  bottom: 49%;

  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}
div[class|="hui"] ~ .expanded {
  box-shadow: 19px 1px 20px 6px #70707094;
  z-index: 1000;
}

.divider {
  border-width: 4px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
}
.witness--name {
  color: #0275d8;
}

.s-item.create-topic-btn {
  background-color: #0f7ec0;
  color: #fff;
  margin-bottom: 0.8rem;
  margin-left: 20px;
  margin-right: 0.8rem;
}

.s-item.topic-created-btn {
  background-color: #5fcc42;
  color: #fff;
  box-shadow: none;
  margin-bottom: 0.8rem;
  margin-left: 20px;
  margin-right: 0.8rem;
}

.s-item.create-topic-btn:hover {
  background-color: transparent;
  color: #0f7ec0;
}

.photo_person {
  display: -webkit-flex;
  display: flex;
  margin-left: 5px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.photo_person .witness-img {
  max-width: 400px;
  max-height: 225px;
}

.photo_person .logo-s.highlight-delete {
  margin: 10px;
}

img.trash:hover {
  cursor: pointer;
}

.highlighted--text {
  color: #0f7ec0;
}

p.deactivate-account {
  margin-left: 30px;
  color: rgb(15, 126, 192);
  cursor: pointer;
  width: 145px;
}
p.deactivate-account:hover {
  border-bottom: #0f7ec0 solid 1.5px;
}

.s-item {
  background-color: transparent;
  color: #0f7ec0;
  box-shadow: inset 0 0 0 1px #0f7ec0;
  border: none;
  margin-left: 8px;
}
.s-item:hover {
  background-color: #0f7ec0;
  border: none;
}

/* .s-item-documents {
  font-size: 13px;
} */

.suggestion {
  height: 58px;
  width: 100%;
}
#foc .suggestion {
  height: 45px;
}
.tech-info {
  background-color: transparent;
  color: #0f7ec0;
  box-shadow: inset 0 0 0 1px #0f7ec0;
  border: none;
  margin-left: 8px;
  float: right;
  font-size: 12px;
}
.tech-info:hover {
  background-color: #0f7ec0;
  border: none;
}
.s-items {
  position: absolute;
  right: 10px;
  left: 10px;
  height: 52px;
  bottom: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: -webkit-flex;
  display: flex;
  /* padding-bottom: 10px; */
  white-space: nowrap;
}

.s-items button {
  max-height: 40px;
}
/* .s-items::before {
  width: 2000px;
  height: 20px;
  background-color: red;
  content: 'Read this: ';
  z-index: 90000;
}
.s-items::after {
  width: 20px;
  height: 20px;
  background-color: blue;
  content: 'Read this: ';
} */
.s-items::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
  /* background-color: #f5f5f5; */
}

.s-items::-webkit-scrollbar {
  height: 2px;
  background-color: transparent;
  margin-top: 10px !important;
}

.s-items::-webkit-scrollbar-thumb {
  background-color: #0f7ec0;
}
.expanded::-webkit-scrollbar-thumb {
  background-color: #0f7ec0;
}
#focus-form .form-group {
  margin-bottom: 0 !important;
}
#focus-form textarea {
  padding: 0 5px;
}

#dark {
  background: #2b2b2b;
  /* background-image: url('https://res.cloudinary.com/aleri/image/upload/v1593642443/Aleri/dark-back_tfvcsp.jpg'); */
  color: white !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
}
#dark .parent-side-nav-card {
  box-shadow: none !important;
}
#dark #side-nav-open {
  background-color: #242527;
}
.dark .saved-input textarea {
  /* color: rgba(255, 255, 255, 0.749) !important; */
  font-size: 20px !important;
}
#dark .btn-outline-secondary {
  color: white !important;
  border-color: black !important;
  background-color: #2e3235;
}

.dark-add,
.dark-add:active,
.dark-add:focus,
.dark-add:hover {
  border: 1px solid #191a1b !important;
  background-color: #202224 !important;
}
#dark .disc3 {
  border-right: 1px solid rgb(117, 116, 116);
}
#dark .btn-outline-secondary:hover,
#dark .add-case-btn:hover {
  color: white !important;
  background-color: #212121 !important;
  border-color: black !important;
}

#dark .parent-side-nav p {
  color: white;
}

#dark .comp-auth-nav {
  background-color: #242527;
}

#dark .side-nav-open-title-w h6 {
  color: white;
}

#dark .list-group-item {
  color: whitesmoke !important;
  background-color: #14111129;
  /* box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px; */
}
#dark .modal-content {
  background-color: #14111129;
}
#dark .list-group-item:hover {
  color: whitesmoke !important;
  background-color: #14111129;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}
#dark .user-list .list-group-item {
  background-color: #232527 !important;
}
.dark-modal .StripeElement {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  background-color: white;
  color: white !important;
}
.dark-modal .action {
  background-color: #0f7ec0;
  color: white;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b;
}
#dark .d {
  border-color: #191a1b !important;
  background-color: #242527;
}
#dark .d:hover {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b !important;
  background-color: #242527 !important;
}
#dark .d:active {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b !important;
  background-color: #242527 !important;
}
#dark .d:focus {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
  border-color: #191a1b !important;
  background-color: #242527 !important;
}
.dark-modal .box-title-m,
.dark-modal .box-title-d,
.dark-modal .box-title-t {
  color: #0f7ec0;
}
.dark-modal .StripeElement p,
.dark-modal .StripeElement span,
.dark-modal .StripeElement input {
  color: white !important;
}
.dark-modal .ElementsApp,
.ElementsApp .InputElement {
  color: white !important;
}
.dark-modal .box {
  background-color: #191a1b !important;
}
.dark-modal .box-b {
  background-color: #191a1b !important;
}
.dark-modal .box-b-2 {
  background-color: #191a1b !important;
}
.dark-modal .box-bottom {
  background-color: #191a1b !important;
}
.dark-modal.box-title-price .box-title-d {
  color: #0275d8 !important;
}
#dark div.inside-card {
  color: #212121;
  background-color: #242527 !important;
  background-image: none;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}
#dark .inside-card:hover {
  color: #212121;
  background-color: #242527 !important;
  background-image: none;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;

  outline: none !important;
}

#dark .modal-content .btn-primary {
  background-color: #212121;
  border: none;
}

#dark .expanded {
  background-color: #232527;
}

#dark .form-control {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.541);
  /* border: #8da2b57d 1px solid; */
}

#dark .action {
  background-color: #232527;
  border: 1px solid rgb(46, 45, 45);
  color: aliceblue;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}
#dark .action:active {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px, #0f7ec0 2px 3px 3px 0px !important;
}
#dark .action:focus {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 30px 2px,
    rgb(16, 19, 23) 4px 6px 6px 0px !important;
}
#dark .parent-side-nav {
  border-color: #191a1b;
}
#dark .action:hover {
  background-color: #232527;
  border: 1px solid rgb(48, 47, 47);

  color: aliceblue;
}

#dark .phone {
  color: white;
}

#dark .underline-settings {
  background-color: #202224 !important;
}

#dark .form-control:focus {
  background-color: transparent;
  color: whitesmoke;
  border-bottom: 1px solid rgb(163, 155, 155);
}

#dark #user-settings textarea.form-control {
  border: 1px solid rgb(163, 155, 155) !important;
}
#dark .expanded {
  border-right: 1px solid #191a1b;
}

#dark #comp-auth-nav-expanded {
  border-right: 1px solid #191a1b;
}

#dark .com-app-icon-proof {
  border-bottom: solid 1px #191a1b;
}

#dark textarea {
  background: transparent;
  color: rgba(255, 255, 255, 0.749);
}
#dark textarea:focus {
  background: transparent;
  color: #f9f9f9;
}

#dark .saved-input .disc {
  border-left: 1px solid rgba(255, 255, 255, 0.281);
}

#dark .notes {
  border-left: 1px solid rgba(255, 255, 255, 0.281);
}
#dark .unsaved-input .disc {
  /* border-right: 1px solid rgb(117, 116, 116); */
}
#dark .unsaved-input .disc2 {
  border-right: 1px solid rgba(255, 255, 255, 0.281);
}

#dark .unsaved-input .ref {
  border-right: 1px solid rgb(117, 116, 116);
}

#dark .saved-input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.281);
  /* border-top: 1px solid rgba(255, 255, 255, 0.281); */
}

#dark .comp-auth-nav {
  background: linear-gradient(to bottom, #2b2b2b 0, #242527 100%);
}
#dark #top-func {
  background: #242527;
  background-color: #242527;
}
#dark #top-second,
#dark #top-first {
  background: #242527 !important;
}
#dark #top-second div,
#dark #top-first div {
  /* background: #242527; */

  border-right: 1px solid #191a1b;
}
#dark #top-first .active-top-card {
  box-shadow: inset 0px 5px 0px 0px #0f7ec0 !important;
  z-index: 10000;
}

#dark #top-nav-expanded {
  border-bottom: 1px solid #191a1b;
  background-color: #242527;
}
#dark #top-second {
  border-left: 1px solid #191a1b;
  border-right: 1px solid #191a1b;
}
#dark #top-nav {
  border-bottom: 1px solid #191a1b;
}
#dark #top-second .top-card {
  background-color: #0f7ec0 !important;
}
#dark .top-card {
  border-right: 1px solid #dfe3ed;
}

#dark .case-drag-single-list {
  background-color: #242527 !important;
}
.dark-mode {
  background-color: #424242;
  color: white;
}
.dark-modal .modal-content {
  background-color: #242527;
  color: white;
}
.dark-modal .form-control {
  color: white;
}
.dark-modal button span {
  color: white;
}
.dark-modal .modal-header {
  border-bottom: 1px solid #191a1b;
}
.dark-modal .modal-footer {
  border-top: 1px solid#191a1b;
}

#dark-tooltip-folder-s {
  background-color: #202224;
}
#dark-tooltip-folder-s > .tooltip-inner {
  background: transparent;
  color: rgb(148, 157, 176);
  background-color: #202224;
  border: none;
}

#dark-tooltip-folder {
  background-color: #202224;
  border: 1px solid #191a1b;
}

#dark-tooltip-folder > .tooltip-inner {
  background: transparent;
  color: rgb(148, 157, 176);
  background-color: #202224;
  border: none;
}

#dark-tooltip-add {
  opacity: 1;
  background-color: #202224;
  color: rgb(148, 157, 176);
  padding: 3px;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid #191a1b;
  font-size: 90%;

  font-weight: 300;
}
#dark-tooltip-add > .tooltip-inner {
  background: transparent;
  color: rgb(148, 157, 176);
  background-color: #202224;
  border: none;
}
#dark.btn-toolbar {
  display: block;
}

#tooltip-add > .tooltip-arrow {
  display: none;
  color: #202224;
}
#dark .arrow::before {
  display: none;
}
#dark [data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  margin-top: 20px;
  z-index: 100000;
}

#dark [data-tooltip]:hover:before {
  opacity: 1;
  background-color: #202224;
  color: rgb(148, 157, 176);
  padding: 10px;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid #191a1b;
  font-size: 90%;
  font-weight: 300;
  z-index: 100000;
}
#dark [data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
  margin-top: 20px;
  z-index: 100000;
}

#dark .case-drag-single-list h4 {
  background-color: #191a1b !important;
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
}
#dark .parent-side-nav {
  background-color: #14111129 !important;
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
}
#dark .parent-side-nav:hover {
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px;
}

#dark .parent-side-nav:active {
  background-color: #424242 !important;
  border-bottom: 1px solid #191a1b !important;
  border-top: 1px solid #191a1b !important;
}
#dark .expanded .add-case-nav {
  border: 1px solid #191a1b;
}
#dark .add-case {
  box-shadow: none;
  background: transparent;
}
#dark #dash-add .add-case {
  border: 1px solid #191a1b;
  background-color: #202224;
}
#dark #dash-add .add-case,
#dark #wit-dash .add-case {
  border: 1px solid #191a1b;
  background-color: #202224;
}

#dark button.add-case.plus-icon,
#dark button.add-case.plus-icon {
  border: none !important;
  background-color: #0f7ec0 !important;
}
#dark #dash-add .add-case:active,
#dark #dash-add .add-case:focus,
#dark #wit-dash .add-case:focus,
#dark #wit-dash .add-case:active,
#dark #dash-add .add-case:hover,
#dark #wit-dash .add-case:hover {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px !important;
}

#dark #topic-delete {
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px !important;
}

#dark.inside-card:active,
#dark.inside-card:focus,
#dark.parent-side-nav-card:active,
#dark.parent-side-nav-card:focus,
.dark-parent-side-nav-card,
.inside-card-dark {
  background-color: #424242 !important;
  border: 1px solid #191a1b !important;
  /* cursor: move !important; fallback if grab cursor is unsupported */
  /* cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important; */
  background-image: none !important;
  color: #f9f9f9 !important;
  box-shadow: rgba(38, 41, 46, 0.72) 0px 0px 20px 1px,
    rgb(16, 19, 23) 2px 3px 3px 0px !important;
}
.dragging {
  cursor: grabbing !important;
  cursor: move !important; /* fallback if grab cursor is unsupported */
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.f9 .dark-border-bx {
  background-color: #424242 !important;
  border: 1px solid #191a1b !important;
}
#dark .border-bx {
  background-color: #202224 !important;
  border: 1px solid #191a1b !important;
}
#dark #top-nav {
  /* width: 70%; */
  height: 42px;
  background: #202224 !important;

  position: fixed;
  z-index: 1000;
  left: 60px;
  right: 0;
  overflow: hidden;
  white-space: nowrap;
}
#dark #top-nav-expanded {
  height: 42px;
  background: #202224 !important;

  position: fixed;
  z-index: 1000;
  /* left: 300px; */
  right: 0;
  overflow: hidden;
  white-space: nowrap;
  top: 0;
}

#dark div[class|="c3-right"] + div[class|="hui"]::after {
  /* background-color: #202224 !important; */
  /* color: white !important; */
  content: "Close Sidebar To Continue";
  font-size: 24px;
  bottom: 49%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}
#dark div[class|="c3-u-right"] + div[class|="hui"]::after {
  /* background-color: #202224 !important; */
  /* color: white !important; */
  content: "Click Here To Close Sidebar";
  font-size: 24px;
  bottom: 49%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 50%;
  z-index: 10;
  position: fixed;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 8px;
  padding: 10px;
}

.czhaTb::before + div[class|="c3-u-right"] {
  width: 100vw;
  height: 100vh;
  content: "";
  position: inherit;
  top: 0;
}
#dark #top-nav-expanded,
#dark .czhaTb-top-expanded {
  /* width: 100vw; */
  /* left: 0; */
  z-index: 10;
}
.dark-modal .sidebar {
  overflow: auto;
  color: white;
  background: #242527;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25%;
}

#dark .sidebar {
  overflow: auto;
  color: white;
  background: #242527;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25%;
}
#dark .Tip__card {
  padding: 20px;
  background: #242527;
  background-clip: padding-box;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(37 40 43 / 20%);
}

#module-search {
  max-width: 720px !important;
}

.s-item-documents {
  background-color: #0f7ec0;
  border: none;
  margin-left: 8px;
}

.s-item-documents:hover,
.s-item-documents:active,
.s-item-documents:focus {
  background-color: transparent !important;
  color: #0f7ec0 !important;
  box-shadow: inset 0 0 0 1px #0f7ec0 !important;
}

.document-text {
  cursor: default;
}

#focus-document-list {
  /* width: 500px; */
  /* margin: auto; */
}

.card-type {
  float: left;
  padding: 5px;
  padding-bottom: 0;
  color: #949db0 !important;
}
.card-type span {
  font-size: 15px !important;
  color: #949db0 !important;
}
.card-placement {
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
  text-align: right;
  /*  */
  /* position: absolute;
  right: 10px;
  left: 10px;
  height: 52px;
  bottom: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 10px;
  white-space: nowrap; */
}
.card-placement a {
  color: #0f7ec0;
}
.document-box .form-group {
  margin: 0;
}
.document-box textarea {
  border: none !important;
  background-color: transparent !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
}

.document-box textarea::-webkit-scrollbar {
  height: 2px;
  background-color: transparent !important;
  margin-top: 10px !important;
}

.document-box textarea::-webkit-scrollbar-thumb {
  background-color: #0f7ec0 !important;
}
.link_doc,
.link_doc:hover,
.link_doc:active,
.link_doc:focus {
  cursor: pointer;
  color: #0f7ec0 !important;
  text-decoration: none !important;
}
.document-x {
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 5px;
  width: 15px !important;
}
.document-x:hover {
  opacity: 70%;
}
.document-u {
  margin-left: 10px;
  margin-top: 2px;
  margin-right: 5px;
  width: 18px !important;
}
.document-u:hover {
  opacity: 70%;
}
#focus-form .card-close {
  padding-bottom: 0;
}
#focus-document-list textarea {
  height: 30px;
}

.important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 7px;
  cursor: pointer;
  z-index: 999 !important;
  display: block;
}
/* .card_important_comment {
  display: block;
} */
.comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 7px;
  cursor: pointer;
  z-index: 999 !important;
  display: none;
}
.not_important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 7px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: block;
}
.not_comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 8px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: none;
}

/*  */
/*  */
/*  */

#focus-form .col-md-12 {
  padding: 0;
}

#focus-form .saved-input {
  padding: 3px;
}

#foc .to-center .not_important,
#foc .to-center .important {
  width: 18px;
  position: absolute;
  left: -25px;
  top: 25px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: block;
}

#foc .to-center .not_comment,
#foc .to-center .comment {
  width: 18px;
  position: absolute;
  right: -25px;
  top: 25px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: none;
}
.underline-t .important,
.underline-t .not_important {
}

.underline-t {
  position: relative;
  width: 100%;
}
#focus-form .col-md-12 {
  padding: 0 !important;
}
#foc textarea {
  padding: 0;
}
#foc .saved-input {
  padding: 0;
}
#foc .important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 8px;
  cursor: pointer;
  z-index: 999 !important;
  display: block;
}
#foc .comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 8px;
  cursor: pointer;
  z-index: 999 !important;
  display: none;
}
#foc .not_important {
  width: 12px;
  position: absolute;
  left: -23px;
  top: 8px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: block;
}
#foc .not_comment {
  width: 12px;
  position: absolute;
  right: -23px;
  top: 8px;
  cursor: pointer;
  opacity: 0.8;
  z-index: 999 !important;
  display: none;
}

/* split */
.c4 #wit-dash,
.c4-u #wit-dash {
  padding: 10px 20px;
}
/* .c4-left #wit-dash,
.c4-left-u #wit-dash {
  padding: 10px;
} */

.c4-left #wit-dash .not_important,
.c4-left-u #wit-dash .not_important,
.c4-right #wit-dash .not_important,
.c4-right-u #wit-dash .not_important,
.c4-left #wit-dash .important,
.c4-left-u #wit-dash .important,
.c4-right #wit-dash .important,
.c4-right-u #wit-dash .important {
  display: inline-block;
  position: relative;
  left: unset;
  top: 0;
  padding: 0;
  margin-right: 7px;
}
.c4-left #wit-dash .not_comment,
.c4-left-u #wit-dash .not_comment,
.c4-right #wit-dash .not_comment,
.c4-right-u #wit-dash .not_comment,
.c4-left #wit-dash .comment,
.c4-left-u #wit-dash .comment,
.c4-right #wit-dash .comment,
.c4-right-u #wit-dash .comment {
  display: none;
  position: relative;
  right: unset;
  top: 1px;
  padding: 0;
}
.c4-left #wit-dash .saved-input .ref,
.c4-left-u #wit-dash .saved-input .ref,
.c4-right #wit-dash .saved-input .ref,
.c4-right-u #wit-dash .saved-input .ref {
  padding: 0;
}
.not_important:hover,
.not_comment:hover {
  opacity: 1 !important;
}

.c4-left #wit-dash .not_important,
.c4-right #wit-dash .not_important,
.c4-left #wit-dash .not_comment,
.c4-right #wit-dash .not_comment {
  /* display: none; */
  opacity: 0.8;
}
.c4-left #wit-dash .focus .ref:hover .not_important,
.c4-right #wit-dash .focus .ref:hover .not_important,
.c4-left #wit-dash .focus .ref:hover .not_comment,
.c4-right #wit-dash .focus .ref:hover .not_comment {
  /* display: inline-block !important; */
}

/* check */
.c4-left #foc .to-center .not_important,
.c4-left-u #foc .to-center .not_important,
.c4-right #foc .to-center .not_important,
.c4-right-u #foc .to-center .not_important,
.c4-left #foc .to-center .important,
.c4-left-u #foc .to-center .important,
.c4-right #foc .to-center .important,
.c4-right-u #foc .to-center .important {
  display: inline-block;
  position: absolute;
  left: unset;
  top: 0;
  padding: 0;
  margin-right: 7px;
  left: 0 !important;
}
.c4-left #foc .to-center .not_comment,
.c4-left-u #foc .to-center .not_comment,
.c4-right #foc .to-center .not_comment,
.c4-right-u #foc .to-center .not_comment,
.c4-left #foc .to-center .comment,
.c4-left-u #foc .to-center .comment,
.c4-right #foc .to-center .comment,
.c4-right-u #foc .to-center .comment {
  display: none;
  position: absolute;
  right: unset;
  top: 1px;
  padding: 0;
  left: 30px !important;
}

.progress {
  background-color: rgba(148, 157, 176, 1);
  height: 0.5rem !important;
}

.progress-bar {
  background-color: #0f7ec0;
}
#dark .progress {
  background-color: rgba(255, 255, 255, 0.749);
}
.document-x {
  width: 12px !important;
  float: right !important;
  margin-right: 0px;
  margin-left: 5px;
}

.document-u {
  width: 14px !important;
  float: left !important;
}
.card-type {
  float: left;
  padding: 0 5px;
  font-size: 15px !important;
  padding-bottom: 0;
  color: #949db0 !important;
}
.card-type span {
  font-size: 15px !important;
  color: #949db0 !important;
}
.document-u {
  margin-left: 5px;
}
.temp {
  line-height: 0.5;
  padding: 0px !important;
  margin: 5px !important;
  margin-left: 0 !important;
  font-size: 26px;
}
#Navigation .navbar-brand {
  padding-top: 4px;
}
.underline-t .s {
  height: 18px;
}
.side-nav-t {
  margin-right: 5px;
  margin-left: 0;
}
@media (max-width: 991px) {
  .temp {
    display: none;
  }
}

.dropdown-toggle::after {
  margin-left: 0.355em;
}
.badged {
  width: 300px;
}
.badged-1 {
  width: 150px;
  margin-left: 20px;
}
#badges {
  background-color: #e1e1e1;
  height: 500px;
  padding-top: 60px;
}
.view-all {
  float: left;
}
.dropdown-menu {
  width: 300px;
  z-index: 119999 !important;
  background-color: white !important;
  padding: 20px 10px !important;
  overflow: visible !important;
}
.feature-img-con-m img {
  width: 100% !important;
}
.dropdown-menu .navbar-nav {
  height: 30px;
  background-color: white !important;
  z-index: 119999 !important;
  line-height: 0.9 !important;
  margin: 0 !important;
  padding: 10px 10px !important;
}
.dropdown-menu a {
  background-color: white !important;
  z-index: 119999 !important;
  font-size: 14px !important;
}
.land-logo {
  cursor: pointer;
}
.header .header-nav ul.dropdown-menu,
.header .header-nav ul.dropdown-menu {
  position: relative !important;
  z-index: 9999;
}
#Navigation {
  z-index: 9999;
}
#basic-navbar-nav {
  z-index: 9999;
}
.navbar-expand-lg .navbar-nav {
  z-index: 9999;
}
#basic-navbar-nav {
  z-index: 9999;
}
.dropdown-item {
  padding: 0;
}
.sub-nav {
  font-weight: normal;
}
.head-nav img {
  float: right;
  height: 14px;
  width: 14px;
  position: absolute;
  opacity: 0;
  right: 20px;
  padding: 0;
}
.head-nav:hover img {
  opacity: 100%;
}
.box-t h4 {
  display: none;
}

.Login-IMG {
  background-color: rgb(247, 247, 248) !important;
  background: url("https://res.cloudinary.com/aleri/image/upload/v1618581480/Aleri/landing/Copy_of_Copy_of_Untitled_v7stdx.jpg")
    right center / 90% no-repeat rgb(247, 247, 248);
}
.Login-Container {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  position: relative;
  bottom: 0;
  overflow: auto;
  -webkit-flex: none;
          flex: none;
  scroll-behavior: smooth;

  overflow-x: hidden;
}
.Login-Container .row {
  height: 100%;
}
.signup-signin {
  color: #0f7ec0;
}
.progtrckr {
  display: none !important;
}
.registrationForm h1 {
  font-weight: 900;
}
@media (max-width: 991px) {
  #feature-landing {
    height: 480px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin: auto !important;
    margin-bottom: 20px !important;
  }
  .dropdown-menu a {
    margin-bottom: 12px;
    text-align: center;
  }
  .dropdown-menu a:last-child {
    margin-bottom: 0;
  }
  .head-nav:hover img {
    opacity: 0;
  }
  .box-t h4 {
    padding-left: 43px;
    display: block;
  }
  #footer li {
    text-align: center;
  }
  #Navigation {
    margin: 0;
  }

  #badges {
    padding-top: 20px;
  }
  #why-not-aleri {
    top: 800px;
  }
  .badged {
    width: 300px;
  }
  .badged-1 {
    width: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .brands {
    line-height: 1.5 !important;
  }
  #Navigation {
    margin: 0;
  }
}
@media (max-width: 500px) {
  #Navigation .navbar-brand {
    padding-left: 15px;
  }
  .navbar-light .navbar-toggler {
    margin-right: 15px;
    margin-top: 20px !important;
  }
  #Navigation {
    margin: 0;
  }
}
@media (max-width: 400px) {
  .under-head ul li {
    font-size: 16px !important;
  }
}

.social {
  margin: 0 5px;
  width: 20px;
}
.social-a {
  font-size: unset !important;
  cursor: pointer;
}

#form-intro h5 {
  text-align: left;
}
#form-intro h3 {
  text-align: center;
  margin-bottom: 20px;
}
#form-intro h1 {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
#form-intro {
  background-color: #142032;
  color: white;
  padding: 30px;
  height: 800px;
}
#form-intro iframe {
  width: 85%;
  height: 750px;
  margin-top: 100px;
  box-shadow: 10px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}
#form-summary {
  background-color: white;
  color: black;
  padding: 30px;
  height: 800px;
}
#form-summary p {
  font-size: 23px;
  font-weight: 500;
  margin-top: 500px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
#form-one {
  background-color: #142032;
  color: white;
  padding: 30px;
  height: 800px;
  position: relative;
  overflow: hidden;
}
#form-one img {
  position: absolute;

  right: -300px;
  top: 100px;
  /* bottom: 5%; */
  box-shadow: 10px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  height: 600px;
}
#form-one p {
  position: relative;
  text-align: left;
  font-size: 20px;
  margin-left: 120px;
  margin-right: 50px;
  margin-top: 80px;
  display: block;
  line-height: 1.8;
}
#form-one p:first-of-type {
  margin-top: 140px;
}
#form-one p img {
  position: relative;

  right: unset;
  top: unset;
  /* bottom: 5%; */
  box-shadow: unset;
  border-radius: unset;
  height: 70px;
  width: 70px;
  float: left;
  margin-right: 10px;
  margin-bottom: 40px;
}
#form-one p span {
  font-weight: bolder;
  font-size: 23px;
}
#form-two {
  background-color: white;
  color: black;
  padding: 30px;
  height: 800px;
  position: relative;
  overflow: hidden;
}
#form-two img {
  position: absolute;

  left: -300px;
  top: 100px;
  /* bottom: 5%; */
  box-shadow: 10px 20px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  height: 600px;
}
#form-two div {
  position: absolute;
  left: 50%;
}
#form-two p {
  position: relative;
  text-align: left;
  font-size: 20px;
  margin-right: 120px;
  margin-left: 50px;
  margin-top: 80px;

  line-height: 1.8;
}
#form-two p span {
  font-weight: bolder;
  font-size: 23px;
  text-align: left;
}
#form-two p:first-of-type {
  margin-top: 140px;
}
#form-two p img {
  position: relative;

  right: unset;
  top: unset;
  left: unset;
  /* bottom: 5%; */
  box-shadow: unset;
  border-radius: unset;
  height: 70px;
  width: 70px;
  float: left;
  margin-right: 10px;
  margin-bottom: 40px;
}

#form-faq {
  background-color: #142032;
  color: white;
  padding: 60px;
  height: 800px;
}
#form-faq .row {
  margin: 50px;
  margin-top: 100px;
}

#form-faq .nav-tabs .nav-link {
  font-size: 18px;
  background-color: white;
  border-radius: unset;
  border: 1px solid #e2e2e2;
  color: darkgrey;
}

#form-faq .nav-tabs .nav-link:hover {
  color: #142032;
}

#form-faq .tab-content > .active {
  text-align: left;
  margin-left: 50px;
}
#form-faq .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #0f7ec0 !important;
  border: #0f7ec0 !important;
  color: #142032 !important;
}
#form-faq .tab-content > .active {
  font-size: 18px;
}
#form-faq .tab-content > .active span {
  font-size: 26px;
  font-weight: bolder;
}
.colon {
  color: #0f7ec0;
}

@media (min-width: 1700px) {
  #form-intro iframe {
    height: 850px;
  }
  #form-summary {
    padding: 40px;
  }
}
@media (max-width: 1500px) {
  #form-intro iframe {
    height: 680px;
  }
  #form-summary p {
    margin-top: 400px;
  }
}

@media (max-width: 1450px) {
  #form-intro iframe {
    height: 600px;
  }
  #form-summary p {
    margin-top: 350px;
  }
  #form-summary {
    height: 700px;
  }
  #form-two p {
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-right: 30px;
    margin-left: 100px;
    margin-top: 80px;
    line-height: 1.8;
  }
  #form-one p {
    position: relative;
    text-align: left;
    font-size: 20px;
    margin-left: 30px;
    margin-right: 100px;
    margin-top: 80px;
    line-height: 1.8;
  }
  #form-one img {
    right: -400px;
  }
  #form-two img {
    left: -400px;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 70px;
  }
}
@media (max-width: 1150px) {
  #form-intro iframe {
    height: 540px;
  }
  #form-intro {
    height: 600px;
  }
  #form-summary {
    height: 600px;
  }
  #form-one img {
    right: -460px;
  }
  #form-two img {
    left: -460px;
  }
  #form-one p img,
  #form-two p img {
    width: 50px;
  }
  #form-one p,
  #form-two p {
    font-size: 16px;
  }
  #form-one p span,
  #form-two p span {
    font-size: 20px;
  }
  #form-faq .tab-content > .active {
    font-size: 16px;
  }
}
@media (max-width: 1100px) {
  #form-intro iframe {
    height: 500px;
  }
  #form-one img {
    right: -500px;
  }
  #form-two img {
    left: -500px;
  }
  #form-one p {
    margin-left: 10px;
  }
  #form-two p {
    margin-right: 10px;
  }
}
@media (max-width: 991px) {
  #form-intro iframe {
    height: 420px;
  }
  #form-summary p {
    margin-top: 260px;
  }
  #form-summary p {
    font-size: 18px;
  }
  #form-summary {
    height: 500px;
  }
  #form-faq {
    padding: 20px;
  }
  #form-faq .row {
    margin: 10px;
    margin-top: 30px;
  }
  #form-faq .tab-content > .active {
    margin-top: 20px;
    margin-left: 0;
  }
  #form-intro {
    margin-top: 25px;
  }
  #form-one img {
    position: relative;
    left: 0;
    right: 0;
    margin: 0;
    height: unset;
    width: 100%;
    border-radius: 15px;
    top: unset;
  }
  #form-one,
  #form-two {
    height: 900px;
  }
  #form-one p:first-of-type {
    margin-top: 0;
    width: 100%;
  }
  #form-one p {
    line-height: 3;
    margin: 10px;
    float: left;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 20px;
  }

  #form-two img {
    left: -460px;
  }
}
@media (max-width: 867px) {
  #form-intro iframe {
    height: 360px;
    width: 100%;
  }
}
@media (max-width: 667px) {
  #form-intro iframe {
    height: 320px;
  }
  #form-summary p {
    margin-top: 170px;
    margin-right: 0;
    margin-left: 0;
  }
  #form-summary {
    padding: 0;
    height: 200px;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 40px;
  }
  #form-one,
  #form-two {
    padding: 20px 10px;
  }
}
@media (max-width: 567px) {
  #form-intro iframe {
    height: 280px;
    width: 100%;
  }

  @media (max-width: 500px) {
    #form-intro iframe {
      height: 260px;
      width: 100%;
      border-radius: 10px;
    }
    #form-intro {
      padding: 15px;
    }
    #form-faq {
      height: 900px;
    }
    #form-intro h1 {
      margin-top: 45px;
    }
    #form-summary p {
      margin-top: 150px;
      margin-right: 0;
      margin-left: 0;
      line-height: 2;
    }
    #form-summary {
      padding: 0;
      height: 300px;
    }
    #form-one p img,
    #form-two p img {
      margin-bottom: 90px;
    }
  }
  @media (max-width: 420px) {
    #form-one p img,
    #form-two p img {
      margin-bottom: 75px;
    }
    #form-one p img,
    #form-two p img {
      width: 30px;
    }
    #form-one p {
      font-size: 14px;
      line-height: 1.8;
    }
    #form-one span,
    #form-two span {
      font-size: 18px;
    }
    #form-one,
    #form-two {
      height: 700px;
    }
  }
}
@media (max-width: 400px) {
  #form-intro iframe {
    height: 200px;
    width: 100%;
    border-radius: 10px;
  }
  #form-one p img,
  #form-two p img {
    margin-bottom: 55px;
  }
}

.cases-d {
  padding-top: 30px;
}
.notes .form-group,
.ref .form-group {
  margin: 0 !important;
}
.underline-t .saved-input {
  margin: 0;
  padding: 0;
}
.underline-t .logo-title {
  position: absolute;
  right: 1.5px;
  top: 4px;
  width: 13px;
}

.disc .form-group {
  margin: 0 !important;
}
.card-close .logo-title {
  position: relative;
  /* right: 10px;
  top: 15px;
  width: 13px; */
  float: right;
}
#focus-form .col-md-12 {
  padding: 10px;
}
#focus-form .drag {
  position: absolute;
  right: 25px;
  top: 2px;
  width: 1.5em;
}
#focus-form .underline-t .logo-title {
  top: 5.4px;
}

.inside-card .progress {
  width: 50px;
  float: left;
  margin-top: 7px;
}
.notes-n {
  height: 34px !important;
}
@media (max-width: 946px) {
  .notes-n {
    height: 64px !important;
  }
}

.landing-logo {
  width: 220px;
  margin-bottom: 50px;
}

@media (max-width: 75em) {
  .landing-logo {
    width: 150px;
    margin-bottom: 50px;
  }
  .dash-t {
    font-size: 1.5rem;
  }
  .dash-foot {
    font-size: 0.8rem;
  }
}

#quate {
  font-size: 80% !important;
  float: right !important;
}
#dash-d-main .season {
  font-size: 22px;
  font-weight: 300;
  color: rgb(148, 157, 176);
}
#dash-d-main .landing-doc-link {
  font-size: 16px;
  font-weight: 500;
  color: rgb(148, 157, 176);
}
#dash-d-main .season:hover,
#dash-d-main .landing-doc-link:hover {
  color: #0275d8;
  text-decoration: none;
}

.card_important_comment {
  opacity: 0.8;
}
.card_important_comment:hover {
  opacity: 1;
}

.case-btn-side-w {
  z-index: 1000;
}

.logo-title-dash {
  width: 18px !important;
  float: right;
  margin-left: 15px;
  cursor: pointer;
}

.case-dash-container {
  position: absolute;
  top: 43px;
  right: 0%;
  width: 75px;
}
#wit-dash h1 {
  margin-right: 75px;
}

#feature-landing-red {
  background-color: white;
  position: absolute;
  top: 50px;
  height: 700px;
  width: 100%;
  text-align: left;
}
#why-not-aleri {
  background-color: #f7f7f7;
  position: absolute;
  top: 700px;
  height: 523px;
  width: 100%;
  text-align: left;
}

#why-not-aleri .under-head {
  margin: 90px;
  margin-left: 0;
  margin-top: 100px;
  text-align: left;
  font-weight: lighter;
  line-height: 1.5;
  color: #142032;
  font-size: 33px;
}
#why-not-aleri-2 .under-head {
  margin-top: 130px;
}
#why-not-aleri-2 {
  background-color: #f7f7f7;
  height: 400px;
  margin-top: 100px;
  /* padding-top: 25px; */
  /* position: absolute;
  top: 700px;
  
  width: 100%;
  text-align: left; */
}

#what-clients-say {
  background-color: white;
  position: absolute;
  top: 1400px;
  height: 700px;
  width: 100%;
  text-align: center;
}
#feature-landing-red h1 {
  color: black;
  /* text-shadow: 8px 4px black; */
  top: 1000px;
  font-size: 750%;
  text-align: left;
}
#feature-landing-red-texts {
  padding: 60px 40px;
}
/* #feature-landing {
  top: 1000px;
} */
/* .dark-mode-section {
  margin-top: 10000px;
} */

.lead-top {
  font-size: 100% !important;
  letter-spacing: 1px !important;
  font-weight: 500 !important;
}

.lead-red {
  /* font-weight: lighter; */
  color: black;
  font-size: 150%;
  /* background-color: white;
  border-radius: 5px 30px 0px 0px;
  padding: 3px 10px; */
  /* box-shadow: 2px 2px black; */
  line-height: 2;
}
.lead-red-2 {
  font-weight: lighter;
  color: black;
  font-size: 230%;
}
.lead-red-2-outer {
  margin: 80px 20px;
  width: 80%;
  height: 400px;
  background: white;
  box-shadow: 2px 2px white;
  padding: 40px;
  border-radius: 5px;
}
.red-blood {
  color: #cc0000;
}
.blue-blood {
  color: #0275d8 !important;
}
#why-not-aleri h1 {
  text-align: center;
  color: black;
}
.landing-sec-2 {
  margin-top: 350px;
}
.navbar-brand {
  margin-right: 5px;
}
#featured {
  height: 400px;
  margin-bottom: 80px;
  background-color: #142032;
}
.landing-demo {
  width: 40% !important;
  height: 50px;
  border-radius: 7px;
  margin: unset;
  padding: unset;
  font-size: unset;
  box-shadow: unset;
  background-color: #0275d8;
  color: white;
}
.pod-btn {
  width: 100%;
  margin-left: 30px !important;
  padding-left: 0 !important;
  text-align: left !important;
}
#featured .under-head {
  color: white;
  margin: 34px;
  padding: 0;
  margin-top: 50px;
  padding-left: 70px !important;
}
.under-head ul li {
  text-align: left;
  margin-left: 0;
  font-size: 70%;
  margin-top: 30px;
}
.blog-length {
  text-align: left !important;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-top: 10px;
}
.blog-length-2 {
  text-align: right !important;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 10px;
}

.blog-length-up {
  /* border-bottom: 1px solid #142032;
  border-top: 1px solid #142032;
  background-color: #f2f2f2; */
  padding-top: 5px;
  position: absolute;
  bottom: 0;
  right: 50%;
  left: 0;
  /* width: 100%; */
  width: 50%;
  padding-top: 10px;
}
.blog-length-up-2 {
  /* border-bottom: 1px solid #142032;
  border-top: 1px solid #142032;
  background-color: #f2f2f2; */
  padding-top: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 50%;
  /* width: 100%; */
  width: 50%;
  padding-top: 10px;
}
#featured h3 {
  margin-top: 0px !important;
  font-size: 22px !important;
  text-align: left;
  margin-bottom: 10px !important;
}
#featured h4 {
  text-align: left;
  margin-top: 0px !important;
  font-size: 18px !important;
}
#featured a {
  float: left;
  margin-top: 0 !important;
  font-size: 20px;
}
#featured img {
  margin-top: 25px;
  width: 570px;
  box-shadow: -1px 1px 20px -1px rgba(0, 0, 0, 0.25);
}
/* test */
.drift-widget-greeting-content {
  line-height: 1.3;
}
.drift-widget-greeting .drift-widget-greeting-content {
  font-size: 13px !important;
}
.drift-widget-chat-bottom {
  top: 90px;
}
.img-ad {
  width: 400px;
}
#landing-info-row {
  margin-bottom: 50px;
}
.landing-list h3 {
  margin-bottom: 30px;
  margin-right: 30px;
  font-weight: lighter;
}

.landing-list ul {
  margin-left: 20px;
  font-size: 150%;
  line-height: 2;
  margin-right: 30px;
}
.landing-list ul li {
  margin-bottom: 20px;
}
.feature-img-side {
  box-shadow: -1px 1px 20px -1px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  position: absolute;
  left: -220px;
  width: 840px;
}

.dark-head {
  font-size: 50px;
}
.helper div {
  font-size: 60%;
  margin: 10px;
  line-height: 4;
  display: inline-block;
}
.helper div span {
  margin-top: 50px;
}
.helper div img {
  width: 16px;
  margin-right: 5px;
}
.email-form {
  width: 80%;
  border: 2px solid #0275d8;
  border-radius: 10px;
}
.email-form .form-group {
  width: 70%;
}
.email-form a {
  width: 30%;
}

.email-form button {
  width: 100% !important;
  height: 50px;
  border-radius: 0 7px 7px 0;

  margin: unset;
  padding: unset;

  font-size: unset;
  box-shadow: unset;
  background-color: #0275d8;
  color: white;
}
.email-form .form-control,
.email-form .form-control:active,
.email-form .form-control:hover {
  width: 100%;
  border: none;
}
.jen {
  text-align: left !important;
  font-size: 380% !important;
}
.dark-mode-section .email-form {
  margin: auto;
  margin-top: 20px;
  width: 30%;
}
.dark-mode-section .helper div span {
  font-size: 15px;
}
#landing-info-row {
  /* margin-top: 40px; */
  margin-top: 0;
}
#feature-landing-texts h1 {
  text-align: left !important;
  margin-bottom: 7px;
}
#feature-landing-texts p {
  text-align: left;
  font-size: 33px;
}
#feature-landing-texts {
  padding-top: 0;
  padding-left: 80px;
}

.under-head {
  margin: 70px;
  text-align: center;
  font-weight: lighter;
  line-height: 1.5;
  color: #142032;
  font-size: 33px;
}

.cap-img {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 400px;
  left: 100px;
}
.cap-img-1 {
  position: absolute;
  bottom: 0;
  top: -10px;
  width: 400px;
  left: 40%;
}
#main-land .container-fluid {
  padding: 50px;
}
.bg-1,
.bg-2,
.bg-3 {
  height: 600px;
}
#main-land .row {
  margin-bottom: 300px;
}
.fea-image {
  width: 100%;
}
#main-land p {
  font-size: 30px;
}

#main-land h3 {
  font-size: 50px;
}
@media (min-width: 995px) {
  #why-not-aleri {
    top: 900px;
  }

  #feature-landing-texts p {
    font-size: 28px;
  }
}
@media (min-width: 1295px) {
  #why-not-aleri {
    top: 900px;
  }
}
@media (max-width: 1678px) {
  .under-head {
    /* margin-right: 20px;
    margin-left: 20px; */
  }
  #why-not-aleri-2 .under-head {
    margin: 30px;
    margin-top: 130px;
  }
}
@media (max-width: 1400px) {
  .jen {
    font-size: 360% !important;
  }
}

@media (max-width: 1360px) {
  .under-head {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 165%;
  }
  .cap-img {
    left: 0px;
  }
  #why-not-aleri #landing-info-row {
    margin-top: 0 !important;
  }
}
@media (max-width: 1300px) {
  #featured .under-head {
    margin-left: 10px;
    float: left;
    padding-left: 40px !important;
  }
  #featured img {
    width: 440px;
    top: 30px;
  }
  .cap-img-1 {
    /* position: absolute;
    bottom: 0;
    top: -10px;
    width: 400px; */
    left: 20%;
  }
  .jen {
    font-size: 300% !important;
  }
  .feature-img-side {
    left: -320px;
    width: 840px;
  }
  #feature-landing-texts p {
    font-size: 26px;
  }
  #main-land .container-fluid {
    padding: 30px;
  }
  .bg-1,
  .bg-2,
  .bg-3 {
    height: 500px;
  }
  .fea-image {
    width: 700px;
  }
  #main-land .row {
    margin-bottom: 200px;
  }
  #main-land p {
    font-size: 20px;
  }

  #main-land h3 {
    font-size: 30px;
  }
}
.modal-front {
  z-index: 10000;
}
@media (max-width: 1165px) {
  .cap-img-1 {
    left: 10%;
  }
  .container-fluid {
    padding: 0;
  }
  #why-not-aleri {
    top: 880px;
  }
  .landing-sec-2 {
    margin-top: 350px;
  }
  #feature-landing-texts {
    padding-bottom: 0;
    padding-left: 30px;
  }
  .feat {
    font-size: 22px !important;
  }
  #info {
    margin-top: 40px;
  }
  .dark-mode-section .email-form {
    width: 52%;
  }

  #main-land .container-fluid {
    padding: 0;
  }
  .bg-1,
  .bg-2,
  .bg-3 {
    height: 420px;
  }
  .fea-image {
    width: 600px;
  }
  #main-land .row {
    margin-bottom: 10px;
  }
  .under-head {
    margin: 50px;
  }
  .lead-top {
    font-size: 75% !important;
  }
}
@media (max-width: 991px) {
  .landing-sec-2 {
    margin-top: 350px;
  }
  #why-not-aleri .under-head {
    margin-right: 10px;
  }
  .cap-img-1 {
    left: 0;
  }
  .landing-demo {
    width: 70% !important;
  }
  #featured {
    height: 500px;
    margin-bottom: 80px;
    background-color: #142032;
  }
}
/*  */
@media (max-width: 900px) {
  .jen {
    font-size: 260% !important;
  }
  #feature-landing-texts p {
    font-size: 22px;
  }
  .helper div {
    margin: 5px;
  }
  .email-form button {
    font-size: 14px;
  }
  .feat {
    font-size: 20px !important;
  }
  #main-land p {
    font-size: 16px !important;
  }
  .feature-img-con {
    margin-left: 0;
  }

  .bg-1,
  .bg-2,
  .bg-3 {
    height: 380px;
  }
  .fea-image {
    width: 500px;
  }
  #main-land .row {
    margin-bottom: 10px;
  }

  /* .under-head {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 165%;
  } */
  /* 
  #why-not-aleri #landing-info-row {
    margin-top: 0 !important;
  } */
  .cap-img {
    left: -20px;
  }
  #featured .under-head {
    margin-left: 0px;
    float: left;
    padding-left: 20px !important;
  }
  #featured img {
    width: 420px !important;
    top: 30px;
  }
  .landing-sec-2 {
    margin-top: 350px;
  }
}
@media (max-width: 850px) {
  .landing-sec-2 {
    margin-top: 350px;
  }
}
@media (max-width: 810px) {
  .landing-sec-2 {
    margin-top: 350px;
  }
  #badges {
    padding-top: 80px;
  }
  #why-not-aleri {
    top: 800px;
  }
  .badged {
    width: 200px;
  }
  .badged-1 {
    width: 100px;
    margin-left: 20px;
  }
}

@media (max-width: 800px) {
  .jen {
    font-size: 220% !important;
  }
  #feature-landing-texts p {
    font-size: 20px;
  }
  #featured img {
    width: 400px !important;
    top: 30px;
  }
  .landing-sec-2 {
    margin-top: 350px;
  }
}
@media (max-width: 767px) {
  #why-not-aleri .under-head {
    text-align: center;
    margin-right: 0;
    margin-top: 0;
  }
  #why-not-aleri li {
    text-align: center;
  }
  #why-not-aleri {
    height: 690px !important;
  }
  #why-not-aleri-2 .under-head {
    margin-top: 30px;
  }

  #why-not-aleri-2 {
    height: 500px !important;
  }
  .cap-img-1 {
    width: 270px;
    margin-top: 300px;
    left: 35%;
  }

  .dark-mode-section .email-form {
    margin: auto;
    margin-top: 20px;
    width: 86%;
  }
  .dark-mode-section .helper div span {
    font-size: 11px;
    margin: 2px;
  }

  .under-head {
    font-size: 23px;
    margin-top: 5px;
  }
  #why-not-aleri {
    top: 1320px;
    height: 486px;
    padding-top: 40px;
  }

  #why-not-aleri-2 {
    height: 600px;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  #feature-landing .email-form {
    width: 100% !important;
  }
  #feature-landing .form-group {
    margin: 0 !important;
  }
  .jen {
    font-size: 280% !important;
  }
  #feature-landing-texts p {
    font-size: 20px;
  }
  #feature-landing-texts {
    padding-bottom: 0;
  }
  .main-image {
    top: -40px;
  }
  .landing-sec-2 {
    margin-top: 350px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #landing-info-row {
    padding: 0;
  }
  .dark-mode-section .form-group {
    margin-top: 0;
  }
  .dark-head {
    font-size: 30px;
  }
  #why-not-aleri-2 {
    padding-top: 10px;
  }
  #landing-info-row {
    margin-top: 13px !important;
  }
  .dark-mode-section .form-group {
    margin-bottom: 0;
  }
  #why-not-aleri #landing-info-row {
    position: relative;
  }
  .cap-img {
    position: relative;
    margin: auto !important;
    width: 250px;
    display: block;
    left: 0;
  }
  #featured .under-head {
    padding: 10px !important;
    float: unset;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .cap-img {
    margin-top: 48px !important;
  }
  .under-head {
    text-align: center !important;
  }
  .dark-mode-section {
    margin-top: 80px;
  }
  #featured {
    padding-top: 30px;
    height: 800px;
  }
  #featured h3 {
    font-size: 30px !important;
  }
  .pod-btn {
    margin-left: 0 !important;
  }
  .landing-demo {
    width: 100%;
  }
  .landing-sec-2 {
    padding-top: 100px !important;
  }

  #badges {
    padding-top: 30px;
    margin-top: 600px;
  }

  .badged {
    width: 200px;
  }
  .badged-1 {
    width: 100px;
  }
}
@media (max-width: 762px) {
  /* #why-not-aleri {
    top: 1120px;
    height: 486px;
    padding-top: 40px;
  } */
  #why-not-aleri {
    top: 1020px;
    height: 486px;
    padding-top: 40px;
  }
  #badges {
    padding-top: 30px;
    margin-top: 300px;
  }
  .main-image {
    width: 70%;
  }
  .landing-sec-2 {
    padding-top: 300px !important;
    margin-top: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 614px) {
  .dark-mode-section .email-form {
    width: 92%;
  }
  .cap-img-1 {
    width: 270px;
    margin-top: 300px;
    left: 25%;
  }
}
@media (max-width: 585px) {
  .under-head {
    margin-bottom: 26px;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  .under-head ul li {
    margin-top: 10px;
    padding: 5px;
  }
}

@media (max-width: 545px) {
  .under-head ul li {
    margin-top: 13px;
  }
  .cap-img {
    width: 252px;
  }
}
@media (max-width: 514px) {
  .under-head ul li {
    margin-top: 5px;
  }
}
@media (max-width: 514px) {
  .under-head ul li {
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .badged {
    width: 200px;
  }
  .badged-1 {
    width: 100px;
    margin-left: 20px;
  }
  #why-not-aleri {
    top: 1010px;
  }
  .under-head {
    font-size: 20px;
    margin-top: 10px;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  #why-not-aleri {
    height: 690px !important;
  }
}
@media (max-width: 500px) {
  .landing-sec-2 {
    margin-top: 100px;
  }
}
@media (max-width: 488px) {
  #why-not-aleri {
    /* height: 418px !important; */
  }
  .landing-sec-2 {
    /* margin-top: 750px !important; */
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
}
@media (max-width: 483px) {
  /* .landing-sec-2 {
    margin-top: 420px;
  } */
  .jen {
    font-size: 220% !important;
  }
  /* .landing-sec-2 {
    margin-top: 550px;
  } */
  /* #why-not-aleri {
    height: 448px !important;
  }
  .landing-sec-2 {
    margin-top: 750px !important;
  } */
  #badges {
    margin-top: 200px;
  }
  #why-not-aleri {
    top: 930px;
  }
}
@media (max-width: 480px) {
  #why-not-aleri {
    height: 690px !important;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  .under-head {
    margin-top: 15px !important;
  }
  .under-head ul li {
    font-size: 60%;
    padding: 5px;
  }
  #feature-landing {
    margin-bottom: 200px;
  }
}
@media (max-width: 460px) {
  /* .landing-sec-2 {
    margin-top: 420px;
  } */
  .jen {
    font-size: 200% !important;
  }
  /* .landing-sec-2 {
    margin-top: 550px;
  } */
  /* #why-not-aleri {
    height: 468px !important;
  }
  .landing-sec-2 {
    margin-top: 750px !important;
  } */
  #why-not-aleri {
    padding-top: 0;

    height: 650px !important;
  }
}
@media (max-width: 431px) {
  /* #why-not-aleri {
    height: 490px !important;
  }
  .landing-sec-2 {
    margin-top: 750px !important;
  } */

  .cap-img-1 {
    left: 15%;
  }
  #featured img {
    width: 350px !important;
  }

  #feature-landing {
    height: 400px;
  }
  .landing-sec-2 {
    margin-top: 100px;
  }
  #why-not-aleri {
    top: 840px;
  }
}
@media (max-width: 423px) {
  #why-not-aleri {
    padding-top: 0;
  }
  .cap-img {
    margin-top: 20px !important;
  }
}

@media (max-width: 380px) {
  .jen {
    font-size: 150% !important;
  }
  /* .landing-sec-2 {
    margin-top: 550px !important;
  }
  #why-not-aleri {
    height: 490px !important;
  } */
}

@media (max-width: 374px) {
  #featured img {
    width: 300px !important;
  }
  .sales {
    padding-top: 10px;
    font-size: 80%;
  }
  /* .under-head {
    margin-top: 0px;
  } */

  .badged {
    width: 150px;
  }
  .badged-1 {
    width: 75px;
    margin-left: 20px;
  }
}

.ref .logo-title {
  position: absolute;
  right: 4px !important;
  top: 4px;
  width: 15px !important;
}
.ref .form-group {
  margin-bottom: 0;
}
.underline-t .logo-title {
  width: 12px;
}
.ref-logo-title {
  position: absolute;
  right: 5px !important;
  top: 4px;
  width: 12px;
  cursor: pointer;
}
.saved-input a,
.saved-input a:active,
.saved-input a:hover,
.saved-input a:focus,
.saved-input p,
.saved-input p:active,
.saved-input p:hover,
.saved-input p:focus {
  font-size: 20px !important;
  color: #0f7ec0;
  cursor: pointer;
  padding: 2px 2px 2px 5px;
  overflow: auto !important;
}

.ref-end {
  /* border-right: 1px solid #2f3740; */
}
.s .drag {
  position: absolute !important;
  right: 15px !important;
  top: 1px !important;
  width: 1.5em !important;
}

.disc3 {
  border-right: 1px solid lightgrey;
}
#dash-d-main .season {
  cursor: pointer;
}
.focus_doc {
  height: 50px;
}
.focus_topic,
.focus_topic:active,
.focus_topic:hover,
.focus_topic:focus {
  margin-bottom: 0;
  color: #8a0468 !important;
}
.focus_summary,
.focus_summary:active,
.focus_summary:hover,
.focus_summary:focus {
  margin-bottom: 0;
  color: #c43838 !important;
}

.focus_fact,
.focus_fact:active,
.focus_fact:hover,
.focus_fact:focus {
  margin-bottom: 0;
  color: #dd482a !important;
}
.saved-input a,
.saved-input a:active,
.saved-input a:hover,
.saved-input a:focus {
  padding: 0 !important;
}
.centre_link {
  line-height: 2;
  margin-left: 4px;
}
.documentList {
  width: 100%;
  border: 1px solid rgb(163, 155, 155);
  padding: 10px;
  background-color: rgb(163, 155, 155);
  border-radius: 5px;
  max-height: 230px;
  overflow-y: scroll;
}
.listItem {
  width: 100%;
  background: white;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  color: black;
}
.dark-modal .listItem {
  border: 1px solid black;
  background: #242527;
  color: #e2e2e2;
}
.listItem:hover,
.listItem:active,
.listItem:focus {
  border: 1px solid #0f7ec0;
  color: #0f7ec0;
}
.listItem p {
  margin: 0 !important;
}
.no_ref {
  text-align: center;
  margin-bottom: 0 !important;
}

.Highlight {
  position: absolute;
}

.Highlight__emoji {
  position: absolute;
  color: black;
  opacity: 1;
  font-size: 28px;
}

.Highlight__parts {
  opacity: 1;
}

.Highlight__part {
  cursor: pointer;
  position: absolute;
  background: rgba(255, 226, 143, 1);
  transition: background 0.3s;
}

.Highlight__popup {
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  max-width: 300px;
  max-height: 100px;
  overflow-y: scroll;
}

.Highlight--scrolledTo .Highlight__part {
  background: #ff4141;
}

.Tip__compact {
  cursor: pointer;
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.Tip__card {
  padding: 20px;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
}

.Tip__card textarea {
  font-size: 16px;
  width: 200px;
  height: 70px;
}

.Tip__card input[type="submit"] {
  margin-top: 5px;
  font-size: large;
}

.Tip__mark-important {
  margin: 0 5px;
}

.Tip__darkMode_mark-import {
  margin: 0 5px;
  color: black;
}

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.sidebar {
  overflow: auto;
  color: rgb(119, 119, 119);
  background: linear-gradient(
    rgb(249, 248, 247),
    rgb(251, 250, 248) 46px,
    rgb(251, 251, 249) 120px,
    rgb(248, 247, 245) 35%,
    rgb(249, 248, 246)
  );
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25%;
}

.description {
  padding: 1rem 1rem;
}

.showInfo {
  cursor: pointer;
  color: #d35400;
}

.sidebar__highlights {
  list-style: "none";
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.highlight-delete:hover {
  cursor: pointer;
}

.sidebar__highlight {
  padding: 1rem;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar_highlight-text {
  cursor: pointer;
  transition: background 140ms ease-in;
}

.sidebar_highlight-text:hover {
  background: rgba(58, 56, 52, 0.08);
}

.sidebar_icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-height: 18px;
}

.sidebar_icons .logo-title-dash.highlight-delete {
  height: 18px;
}

.logo-s .add-btn {
  margin: none !important;
  z-index: 10;
}

.logo-title-dash.highlight-delete,
.logo-title-dash.highlight-add {
  margin-left: 0;
}

.buttons {
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.sidebar_icons div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.buttons .btn-reset,
.buttons .btn-summarize {
  margin: 0.5rem;
}

a {
  color: #d35400;
}

blockquote {
  padding: 0;
  margin: 0;
  quotes: "\201c""\201d";
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}

.c4-right .description h3,
.c4-left.description h3 {
  font-size: 20px;
}

.c4-right .sidebar,
.c4-left .sidebar {
  font-size: 13px;
}

.c4-right .highlight__image,
.c4-left .highlight__image {
  overflow: auto;
  max-width: 150px;
  max-height: 100px;
  border: 1px dashed;
}

.c4-right .btn-reset,
.c4-left .btn-reset,
.c4-right .btn-summarize,
.c4-left .btn-summarize {
  font-size: 12px;
}

.errorScreen {
  height: 100%;
}

.errorMessage {
  position: absolute;
  top: 100px;
  text-align: center;
  width: 100%;
}

.add-fact.select,
.add-fact.create {
  color: #0f7ec0;
}

.add-fact:hover {
  cursor: pointer;
  color: #0f7ec0;
}

/* CSS For:
  - Tag.js
  - SummaryHighlightModal.js
  - Summary.js
*/

.custom-class {
  background-color: rgb(253, 253, 62);
  color: black !important;
  font-size: 20px;
}

.notes span {
  font-size: 20px;
  color: black;
}

.dark .notes span {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.749);
}

.highlighted-text {
  font-size: 20px;
}
.highlight {
  height: auto !important;
  word-break: break-word;
}
.highlight-switch {
  margin-top: 3%;
  /* margin: auto; */
}

.highlight-class {
  width: 100%;
  padding: 2px 2px 2px 5px;
  height: auto !important;
  word-break: break-word;
}

#current-witness {
  background-color: #97caef;
  color: black;
}

.factItem {
  width: 100%;
  background: white;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  color: black;
}

.dark-modal .factItem {
  border: 1px solid black;
  background: #242527;
  color: #e2e2e2;
}

.factItem:hover,
.factItem:target,
.factItem :checked,
.factItem :focus,
.--selected {
  border: 1px solid #0f7ec0;
  color: #0f7ec0;
}
.factItem p {
  margin: 0 !important;
}

.factItem-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* justify-content: center; */
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  /* margin: auto; */
  /* align-items: flex-end; */
  padding-top: 4%;
}

.factItem-witness {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* justify-content: center; */
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  /* align-items: ; */
  /* align-items: flex-end; */
}

.selected-fact {
  border: 2px solid #0f7ec0;
  color: #0f7ec0;
}

.fact-witness-name {
  color: #0f7ebf !important;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start; */
  /* padding-right: 100%; */
  /* width: 100%; */
  line-height: 1.2 !important;
  width: 70%;
}

.fact-card-name {
  line-height: 1.2 !important;
}

/* #fact-witness-name {
  width: 50%;
  /* margin-right: 20%;
} */

/* .buckets {
  /* display: flex;
  flex-direction: row; */
/* width: 100%;
  height: 50px;
  margin: 50px;
  padding: 50px; 
} */

/* .bucket-labels {
  display: flex;
  flex-direction: column;

}

.witness-fact {
  display: flex;
  flex-direction: row;
} */

.bucket-labels {
  margin-right: 50%;
  margin-bottom: 20px;
}

.bucket-labels-btn {
  /* width: 100%; */
  margin-left: 0 !important;
}

.witness-fact-bucket-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  gap: 10px;
  margin: auto;
  /* gap: initial;
  row-gap: 10px; */
}

.fact-card {
  margin: auto;
}
.view-all-btn-1 {
  margin-bottom: 10px;
  margin-left: 0 !important;
}
.view-all-btn {
  margin-bottom: 10px;
}
.select-tag {
  margin-left: 0;
}

.all-tag textarea {
  cursor: default !important;
}

#wit-dash {
  height: 100%;
}

#wit-dash .container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.widgets {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 1rem;
}

.widget-item,
.widget-item-dark {
  height: 180px;
  width: 180px;
  margin: 0.8rem 1rem;
  border-radius: 6px;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.widget-item {
  border-color: rgba(246, 249, 255, 0.07) rgba(19, 33, 68, 0.07) #132144;
  box-shadow: 0 1px 3px 1px #b0b5bb;
  border-width: 1px;
}

.widget-item-dark {
  background-color: #242527;
  color: #f9f9f9;
  box-shadow: rgb(38 41 46 / 72%) 0px 0px 20px 1px,
    rgb(16 19 23) 2px 3px 3px 0px;
  border: 1px solid #191a1b;
}

.widget-item-dark:hover,
.widget-item:hover {
  cursor: pointer;
}

.widget-item:hover {
  background-color: #f9f9f9;
}

.attach-link {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.8rem 0;
  max-height: 60px;
}

.document-type {
  margin-left: 1em;
  margin-right: 1em;
}

.link.document-type {
  color: #0f7ec0;
}
.file.document-type {
  color: #0f7ec0;
}

.document-type:hover {
  cursor: pointer;
  color: #0f7ec0;
}

.choose-file {
  margin: 1em 1em 0.3em 1em;
}

#file-modal.file-preview {
  height: 100vh;
  width: 90vw;
  max-width: none !important;
  padding: 0 !important;
}

#file-modal.file-preview .modal-body {
  padding: 0 !important;
}

/* #file-modal.file-preview div button {
  margin: 0 5px;
} */

.file-modal_header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.buttons .btn.btn-secondary {
  margin-left: 10px;
}

.file-modal_header a {
  position: absolute;
  right: 70px;
}

.pdf-container {
  height: 100vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: relative;
}

.file-container {
  position: absolute;
  top: 0;
  width: 75%;
  background-color: #e2e2e2;
  bottom: 0;
  right: 0;
}

.file_link {
  color: #0f7ec0;
}

.file_link:hover {
  cursor: pointer;
}

.webviewer {
  height: 100%;
  width: 100%;
}

.pdf-container-full {
  /* height: 100vh; */
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.btn.upload-document {
  margin-bottom: 0.5rem;
  background-color: #0f7ec0;
  border-color: #0f7ec0;
}

.c4-left .btn.upload-document,
.c4-right .btn.upload-document {
  font-size: 15px;
}

.img-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.img-container:hover {
  opacity: 0.8;
}

.img-container button.facttag-btn.btn.btn-secondary {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.dropbox {
  width: 140px;
  padding-left: 15px;
}

.dropbox:hover {
  cursor: pointer;
  color: rgb(141, 162, 181);
}

.dropbox-icon {
  color: #0f7ec0;
  /* margin-left: 15px; */
}

/* .dropbox-icon:hover {
  color: rgb(141, 162, 181);
  cursor: pointer;
} */

/* .img-container img:hover {
  opacity: 0.7;
} */

@media screen and (max-width: 600px) {
  .pdf-container {
    height: 100vh;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
  }

  .file-container {
    position: absolute;
    width: 100%;
  }
}

.css-1rklsty-TimelineItemContentWrapper {
  min-height: 0px !important;
}

.css-16u30nb-TimelineControlContainer {
  margin-top: 15px;
}

.date-picker-dark {
  width: 110%;
  background-color: transparent;
  color: white;
  border-right: none;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid white;
}

.date-picker-white {
  width: 110%;
  background-color: transparent;
  color: #212529;
  border-right: none;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid #212529;
}

