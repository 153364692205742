.sidebar {
  overflow: auto;
  color: rgb(119, 119, 119);
  background: linear-gradient(
    rgb(249, 248, 247),
    rgb(251, 250, 248) 46px,
    rgb(251, 251, 249) 120px,
    rgb(248, 247, 245) 35%,
    rgb(249, 248, 246)
  );
  display: flex;
  flex-direction: column;
  width: 25%;
}

.description {
  padding: 1rem 1rem;
}

.showInfo {
  cursor: pointer;
  color: #d35400;
}

.sidebar__highlights {
  list-style: "none";
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.highlight-delete:hover {
  cursor: pointer;
}

.sidebar__highlight {
  padding: 1rem;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar_highlight-text {
  cursor: pointer;
  transition: background 140ms ease-in;
}

.sidebar_highlight-text:hover {
  background: rgba(58, 56, 52, 0.08);
}

.sidebar_icons {
  display: flex;
  justify-content: space-between;
  max-height: 18px;
}

.sidebar_icons .logo-title-dash.highlight-delete {
  height: 18px;
}

.logo-s .add-btn {
  margin: none !important;
  z-index: 10;
}

.logo-title-dash.highlight-delete,
.logo-title-dash.highlight-add {
  margin-left: 0;
}

.buttons {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.sidebar_icons div {
  display: flex;
  flex-direction: row;
}

.buttons .btn-reset,
.buttons .btn-summarize {
  margin: 0.5rem;
}

a {
  color: #d35400;
}

blockquote {
  padding: 0;
  margin: 0;
  quotes: "\201c""\201d";
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}

.c4-right .description h3,
.c4-left.description h3 {
  font-size: 20px;
}

.c4-right .sidebar,
.c4-left .sidebar {
  font-size: 13px;
}

.c4-right .highlight__image,
.c4-left .highlight__image {
  overflow: auto;
  max-width: 150px;
  max-height: 100px;
  border: 1px dashed;
}

.c4-right .btn-reset,
.c4-left .btn-reset,
.c4-right .btn-summarize,
.c4-left .btn-summarize {
  font-size: 12px;
}
