.attach-link {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0;
  max-height: 60px;
}

.document-type {
  margin-left: 1em;
  margin-right: 1em;
}

.link.document-type {
  color: #0f7ec0;
}
.file.document-type {
  color: #0f7ec0;
}

.document-type:hover {
  cursor: pointer;
  color: #0f7ec0;
}

.choose-file {
  margin: 1em 1em 0.3em 1em;
}

#file-modal.file-preview {
  height: 100vh;
  width: 90vw;
  max-width: none !important;
  padding: 0 !important;
}

#file-modal.file-preview .modal-body {
  padding: 0 !important;
}

/* #file-modal.file-preview div button {
  margin: 0 5px;
} */

.file-modal_header {
  display: flex;
  align-items: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.buttons .btn.btn-secondary {
  margin-left: 10px;
}

.file-modal_header a {
  position: absolute;
  right: 70px;
}

.pdf-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.file-container {
  position: absolute;
  top: 0;
  width: 75%;
  background-color: #e2e2e2;
  bottom: 0;
  right: 0;
}

.file_link {
  color: #0f7ec0;
}

.file_link:hover {
  cursor: pointer;
}

.webviewer {
  height: 100%;
  width: 100%;
}

.pdf-container-full {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.btn.upload-document {
  margin-bottom: 0.5rem;
  background-color: #0f7ec0;
  border-color: #0f7ec0;
}

.c4-left .btn.upload-document,
.c4-right .btn.upload-document {
  font-size: 15px;
}

.img-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.img-container:hover {
  opacity: 0.8;
}

.img-container button.facttag-btn.btn.btn-secondary {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.dropbox {
  width: 140px;
  padding-left: 15px;
}

.dropbox:hover {
  cursor: pointer;
  color: rgb(141, 162, 181);
}

.dropbox-icon {
  color: #0f7ec0;
  /* margin-left: 15px; */
}

/* .dropbox-icon:hover {
  color: rgb(141, 162, 181);
  cursor: pointer;
} */

/* .img-container img:hover {
  opacity: 0.7;
} */

@media screen and (max-width: 600px) {
  .pdf-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .file-container {
    position: absolute;
    width: 100%;
  }
}
