.errorScreen {
  height: 100%;
}

.errorMessage {
  position: absolute;
  top: 100px;
  text-align: center;
  width: 100%;
}
